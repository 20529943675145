function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var schemes = {};
schemes.ahom = {
  "vowels": {
    "अ": "𑜒",
    "आ": "𑜒𑜡",
    "इ": "𑜒𑜢",
    "ई": "𑜒𑜣",
    "उ": "𑜒𑜤",
    "ऊ": "𑜒𑜥",
    "ऋ": "𑜍𑜤",
    "ॠ": "𑜍𑜥",
    "ऌ": "𑜎𑜤",
    "ॡ": "𑜎𑜥",
    "ऎ": "𑜒𑜦",
    "ए": "𑜒𑜦",
    "ऐ": "𑜒𑜩",
    "ऒ": "𑜒𑜨",
    "ओ": "𑜒𑜨",
    "औ": "𑜒𑜧"
  },
  "vowel_marks": {
    "ा": "𑜡",
    "ि": "𑜢",
    "ी": "𑜣",
    "ु": "𑜤",
    "ू": "𑜥",
    "ृ": "𑜞𑜤",
    "ॄ": "𑜞𑜥",
    "ॢ": "𑜝𑜤",
    "ॣ": "𑜝𑜥",
    "ॆ": "𑜦",
    "े": "𑜦",
    "ै": "𑜩",
    "ॊ": "𑜨",
    "ो": "𑜨",
    "ौ": "𑜧"
  },
  "yogavaahas": {
    "ं": "𑜪",
    "ः": "𑜑𑜫",
    "ँ": "𑜪"
  },
  "virama": {
    "्": "𑜫"
  },
  "consonants": {
    "क": "𑜀",
    "ख": "𑜁",
    "ग": "𑜕",
    "घ": "𑜗",
    "ङ": "𑜂",
    "च": "𑜋",
    "छ": "𑜋",
    "ज": "𑜊",
    "झ": "𑜙",
    "ञ": "𑜐",
    "ट": "𑜄",
    "ठ": "𑜌",
    "ड": "𑜓",
    "ढ": "𑜔",
    "ण": "𑜃",
    "त": "𑜄",
    "थ": "𑜌",
    "द": "𑜓",
    "ध": "𑜔",
    "न": "𑜃",
    "प": "𑜆",
    "फ": "𑜇",
    "ब": "𑜈",
    "भ": "𑜘",
    "म": "𑜉",
    "य": "𑜊",
    "र": "𑜍",
    "ल": "𑜎",
    "व": "𑜈",
    "श": "𑜏",
    "ष": "𑜏",
    "स": "𑜏",
    "ह": "𑜑",
    "ळ": "𑜎",
    "क्ष": "𑜀𑜫𑜏",
    "ज्ञ": "𑜊𑜫𑜐"
  },
  "symbols": {
    "०": "𑜰",
    "१": "𑜱",
    "२": "𑜲",
    "३": "𑜳",
    "४": "𑜴",
    "५": "𑜵",
    "६": "𑜶",
    "७": "𑜷",
    "८": "𑜸",
    "९": "𑜹",
    "ॐ": "𑜒𑜪𑜨",
    "ऽ": "'",
    "।": "𑜼",
    "॥": "𑜽"
  },
  "zwj": {
    "‍": "‍"
  },
  "skip": {
    "undefined": ""
  },
  "accents": {
    "॑": "॑",
    "॒": "॒"
  },
  "candra": {
    "ॅ": "𑜦"
  },
  "extra_consonants": {
    "क़": "𑜀",
    "ख़": "𑜁",
    "ग़": "𑜕",
    "ज़": "𑜊",
    "ड़": "𑜓",
    "ढ़": "𑜔",
    "फ़": "𑜇",
    "य़": "𑜊",
    "ऱ": "𑜍"
  }
};
schemes.assamese = {
  "vowels": {
    "अ": "অ",
    "आ": "আ",
    "इ": "ই",
    "ई": "ঈ",
    "उ": "উ",
    "ऊ": "ঊ",
    "ऋ": "ঋ",
    "ॠ": "ৠ",
    "ऌ": "ঌ",
    "ॡ": "ৡ",
    "ऎ": "ऎ",
    "ए": "এ",
    "ऐ": "ঐ",
    "ऒ": "ऒ",
    "ओ": "ও",
    "औ": "ঔ"
  },
  "vowel_marks": {
    "ा": "া",
    "ि": "ি",
    "ी": "ী",
    "ु": "ু",
    "ू": "ূ",
    "ृ": "ৃ",
    "ॄ": "ৄ",
    "ॢ": "ৢ",
    "ॣ": "ৣ",
    "ॆ": "ॆ",
    "े": "ে",
    "ै": "ৈ",
    "ॊ": "ॊ",
    "ो": "ো",
    "ौ": "ৌ"
  },
  "yogavaahas": {
    "ं": "ং",
    "ः": "ঃ",
    "ँ": "ঁ"
  },
  "virama": {
    "्": "্"
  },
  "consonants": {
    "क": "ক",
    "ख": "খ",
    "ग": "গ",
    "घ": "ঘ",
    "ङ": "ঙ",
    "च": "চ",
    "छ": "ছ",
    "ज": "জ",
    "झ": "ঝ",
    "ञ": "ঞ",
    "ट": "ট",
    "ठ": "ঠ",
    "ड": "ড",
    "ढ": "ঢ",
    "ण": "ণ",
    "त": "ত",
    "थ": "থ",
    "द": "দ",
    "ध": "ধ",
    "न": "ন",
    "प": "প",
    "फ": "ফ",
    "ब": "ব",
    "भ": "ভ",
    "म": "ম",
    "य": "য",
    "र": "ৰ",
    "ल": "ল",
    "व": "ৱ",
    "श": "শ",
    "ष": "ষ",
    "स": "স",
    "ह": "হ",
    "ळ": "ল়",
    "क्ष": "ক্ষ",
    "ज्ञ": "জ্ঞ"
  },
  "symbols": {
    "०": "০",
    "१": "১",
    "२": "২",
    "३": "৩",
    "४": "৪",
    "५": "৫",
    "६": "৬",
    "७": "৭",
    "८": "৮",
    "९": "৯",
    "ॐ": "ওঁ",
    "ऽ": "ঽ",
    "।": "।",
    "॥": "॥"
  },
  "zwj": {
    "‍": "‍"
  },
  "skip": {
    "undefined": ""
  },
  "accents": {
    "॑": "॑",
    "॒": "॒"
  },
  "candra": {
    "ॅ": "ে"
  },
  "extra_consonants": {
    "क़": "ক়",
    "ख़": "খ়",
    "ग़": "গ়",
    "ज़": "জ়",
    "ड़": "ড়",
    "ढ़": "ঢ়",
    "फ़": "ফ়",
    "य़": "য়",
    "ऱ": "ৰ়",
    "ऴ": "ষ়"
  }
};
schemes.balinese = {
  "vowels": {
    "अ": "ᬅ",
    "आ": "ᬆ",
    "इ": "ᬇ",
    "ई": "ᬈ",
    "उ": "ᬉ",
    "ऊ": "ᬊ",
    "ऋ": "ᬋ",
    "ॠ": "ᬌ",
    "ऌ": "ᬍ",
    "ॡ": "ᬎ",
    "ऎ": "ᬏ",
    "ए": "ᬏ",
    "ऐ": "ᬐ",
    "ऒ": "ᬑ",
    "ओ": "ᬑ",
    "औ": "ᬒ"
  },
  "vowel_marks": {
    "ा": "ᬵ",
    "ि": "ᬶ",
    "ी": "ᬷ",
    "ु": "ᬸ",
    "ू": "ᬹ",
    "ृ": "ᬺ",
    "ॄ": "ᬻ",
    "ॢ": "ᬼ",
    "ॣ": "ᬽ",
    "ॆ": "ᬾ",
    "े": "ᬾ",
    "ै": "ᬿ",
    "ॊ": "ᭀ",
    "ो": "ᭀ",
    "ौ": "ᭁ"
  },
  "yogavaahas": {
    "ं": "ᬂ",
    "ः": "ᬄ",
    "ँ": "ᬁ"
  },
  "virama": {
    "्": "᭄"
  },
  "consonants": {
    "क": "ᬓ",
    "ख": "ᬔ",
    "ग": "ᬕ",
    "घ": "ᬖ",
    "ङ": "ᬗ",
    "च": "ᬘ",
    "छ": "ᬙ",
    "ज": "ᬚ",
    "झ": "ᬛ",
    "ञ": "ᬜ",
    "ट": "ᬝ",
    "ठ": "ᬞ",
    "ड": "ᬟ",
    "ढ": "ᬠ",
    "ण": "ᬡ",
    "त": "ᬢ",
    "थ": "ᬣ",
    "द": "ᬤ",
    "ध": "ᬥ",
    "न": "ᬦ",
    "प": "ᬧ",
    "फ": "ᬨ",
    "ब": "ᬩ",
    "भ": "ᬪ",
    "म": "ᬫ",
    "य": "ᬬ",
    "र": "ᬭ",
    "ल": "ᬮ",
    "व": "ᬯ",
    "श": "ᬰ",
    "ष": "ᬱ",
    "स": "ᬲ",
    "ह": "ᬳ",
    "ळ": "ᬮ᬴",
    "क्ष": "ᬓ᭄ᬱ",
    "ज्ञ": "ᬚ᭄ᬜ"
  },
  "symbols": {
    "०": "᭐",
    "१": "᭑",
    "२": "᭒",
    "३": "᭓",
    "४": "᭔",
    "५": "᭕",
    "६": "᭖",
    "७": "᭗",
    "८": "᭘",
    "९": "᭙",
    "ॐ": "ᬑᬁ",
    "ऽ": "'",
    "।": "᭞",
    "॥": "᭟"
  },
  "zwj": {
    "‍": "‍"
  },
  "skip": {
    "undefined": ""
  },
  "accents": {
    "॑": "॑",
    "॒": "॒"
  },
  "candra": {
    "ॅ": "ᬾ"
  },
  "extra_consonants": {
    "क़": "ᬓ᬴",
    "ख़": "ᬔ᬴",
    "ग़": "ᬕ᬴",
    "ज़": "ᬚ᬴",
    "ड़": "ᬟ᬴",
    "ढ़": "ᬠ᬴",
    "फ़": "ᬧ᬴",
    "य़": "ᬬ᬴",
    "ऱ": "ᬭ᬴"
  }
};
schemes.bengali = {
  "vowels": {
    "अ": "অ",
    "आ": "আ",
    "इ": "ই",
    "ई": "ঈ",
    "उ": "উ",
    "ऊ": "ঊ",
    "ऋ": "ঋ",
    "ॠ": "ৠ",
    "ऌ": "ঌ",
    "ॡ": "ৡ",
    "ऎ": "ऎ",
    "ए": "এ",
    "ऐ": "ঐ",
    "ऒ": "ऒ",
    "ओ": "ও",
    "औ": "ঔ"
  },
  "vowel_marks": {
    "ा": "া",
    "ि": "ি",
    "ी": "ী",
    "ु": "ু",
    "ू": "ূ",
    "ृ": "ৃ",
    "ॄ": "ৄ",
    "ॢ": "ৢ",
    "ॣ": "ৣ",
    "ॆ": "ॆ",
    "े": "ে",
    "ै": "ৈ",
    "ॊ": "ॊ",
    "ो": "ো",
    "ौ": "ৌ"
  },
  "yogavaahas": {
    "ं": "ং",
    "ः": "ঃ",
    "ँ": "ঁ"
  },
  "virama": {
    "्": "্"
  },
  "consonants": {
    "क": "ক",
    "ख": "খ",
    "ग": "গ",
    "घ": "ঘ",
    "ङ": "ঙ",
    "च": "চ",
    "छ": "ছ",
    "ज": "জ",
    "झ": "ঝ",
    "ञ": "ঞ",
    "ट": "ট",
    "ठ": "ঠ",
    "ड": "ড",
    "ढ": "ঢ",
    "ण": "ণ",
    "त": "ত",
    "थ": "থ",
    "द": "দ",
    "ध": "ধ",
    "न": "ন",
    "प": "প",
    "फ": "ফ",
    "ब": "ব",
    "भ": "ভ",
    "म": "ম",
    "य": "য",
    "र": "র",
    "ल": "ল",
    "व": "ব",
    "श": "শ",
    "ष": "ষ",
    "स": "স",
    "ह": "হ",
    "ळ": "ল়",
    "क्ष": "ক্ষ",
    "ज्ञ": "জ্ঞ"
  },
  "symbols": {
    "०": "০",
    "१": "১",
    "२": "২",
    "३": "৩",
    "४": "৪",
    "५": "৫",
    "६": "৬",
    "७": "৭",
    "८": "৮",
    "९": "৯",
    "ॐ": "ওঁ",
    "ऽ": "ঽ",
    "।": "।",
    "॥": "॥"
  },
  "zwj": {
    "‍": ""
  },
  "skip": {
    "undefined": ""
  },
  "accents": {
    "॑": "॑",
    "॒": "॒"
  },
  "candra": {
    "ॅ": "ে"
  },
  "extra_consonants": {
    "क़": "ক়",
    "ख़": "খ়",
    "ग़": "গ়",
    "ज़": "জ়",
    "ड़": "ড়",
    "ढ़": "ঢ়",
    "फ़": "ফ়",
    "य़": "য়",
    "ऱ": "র়",
    "ऴ": "ষ়"
  }
};
schemes.bhaiksuki = {
  "vowels": {
    "अ": "𑰀",
    "आ": "𑰁",
    "इ": "𑰂",
    "ई": "𑰃",
    "उ": "𑰄",
    "ऊ": "𑰅",
    "ऋ": "𑰆",
    "ॠ": "𑰇",
    "ऌ": "𑰈",
    "ॡ": "𑰈",
    "ऎ": "𑰊",
    "ए": "𑰊",
    "ऐ": "𑰋",
    "ऒ": "𑰌",
    "ओ": "𑰌",
    "औ": "𑰍"
  },
  "vowel_marks": {
    "ा": "𑰯",
    "ि": "𑰰",
    "ी": "𑰱",
    "ु": "𑰲",
    "ू": "𑰳",
    "ृ": "𑰴",
    "ॄ": "𑰵",
    "ॢ": "𑰶",
    "ॣ": "𑰶",
    "ॆ": "𑰸",
    "े": "𑰸",
    "ै": "𑰹",
    "ॊ": "𑰺",
    "ो": "𑰺",
    "ौ": "𑰻"
  },
  "yogavaahas": {
    "ं": "𑰽",
    "ः": "𑰾",
    "ँ": "𑰼"
  },
  "virama": {
    "्": "𑰿"
  },
  "consonants": {
    "क": "𑰎",
    "ख": "𑰏",
    "ग": "𑰐",
    "घ": "𑰑",
    "ङ": "𑰒",
    "च": "𑰓",
    "छ": "𑰔",
    "ज": "𑰕",
    "झ": "𑰖",
    "ञ": "𑰗",
    "ट": "𑰘",
    "ठ": "𑰙",
    "ड": "𑰚",
    "ढ": "𑰛",
    "ण": "𑰜",
    "त": "𑰝",
    "थ": "𑰞",
    "द": "𑰟",
    "ध": "𑰠",
    "न": "𑰡",
    "प": "𑰢",
    "फ": "𑰣",
    "ब": "𑰤",
    "भ": "𑰥",
    "म": "𑰦",
    "य": "𑰧",
    "र": "𑰨",
    "ल": "𑰩",
    "व": "𑰪",
    "श": "𑰫",
    "ष": "𑰬",
    "स": "𑰭",
    "ह": "𑰮",
    "ळ": "𑰩",
    "क्ष": "𑰎𑰿𑰬",
    "ज्ञ": "𑰕𑰿𑰗"
  },
  "symbols": {
    "०": "𑱐",
    "१": "𑱑",
    "२": "𑱒",
    "३": "𑱓",
    "४": "𑱔",
    "५": "𑱕",
    "६": "𑱖",
    "७": "𑱗",
    "८": "𑱘",
    "९": "𑱙",
    "ॐ": "𑰌𑰼",
    "ऽ": "𑱀",
    "।": "𑱁",
    "॥": "𑱂"
  },
  "zwj": {
    "‍": "‍"
  },
  "skip": {
    "undefined": ""
  },
  "accents": {
    "॑": "॑",
    "॒": "॒"
  },
  "candra": {
    "ॅ": "𑰸"
  },
  "extra_consonants": {
    "क़": "𑰎",
    "ख़": "𑰏",
    "ग़": "𑰐",
    "ज़": "𑰕",
    "ड़": "𑰚",
    "ढ़": "𑰛",
    "फ़": "𑰣",
    "य़": "𑰧",
    "ऱ": "𑰨"
  }
};
schemes.brahmi = {
  "vowels": {
    "अ": "𑀅",
    "आ": "𑀆",
    "इ": "𑀇",
    "ई": "𑀈",
    "उ": "𑀉",
    "ऊ": "𑀊",
    "ऋ": "𑀋",
    "ॠ": "𑀌",
    "ऌ": "𑀍",
    "ॡ": "𑀎",
    "ऎ": "𑀏𑁆",
    "ए": "𑀏",
    "ऐ": "𑀐",
    "ऒ": "𑀑𑁆",
    "ओ": "𑀑",
    "औ": "𑀒"
  },
  "vowel_marks": {
    "ा": "𑀸",
    "ि": "𑀺",
    "ी": "𑀻",
    "ु": "𑀼",
    "ू": "𑀽",
    "ृ": "𑀾",
    "ॄ": "𑀿",
    "ॢ": "𑁀",
    "ॣ": "𑁁",
    "ॆ": "𑁂",
    "े": "𑁂",
    "ै": "𑁃",
    "ॊ": "𑁄",
    "ो": "𑁄",
    "ौ": "𑁅"
  },
  "yogavaahas": {
    "ं": "𑀁",
    "ः": "𑀂",
    "ँ": "𑀀"
  },
  "virama": {
    "्": "𑁆"
  },
  "consonants": {
    "क": "𑀓",
    "ख": "𑀔",
    "ग": "𑀕",
    "घ": "𑀖",
    "ङ": "𑀗",
    "च": "𑀘",
    "छ": "𑀙",
    "ज": "𑀚",
    "झ": "𑀛",
    "ञ": "𑀜",
    "ट": "𑀝",
    "ठ": "𑀞",
    "ड": "𑀟",
    "ढ": "𑀠",
    "ण": "𑀡",
    "त": "𑀢",
    "थ": "𑀣",
    "द": "𑀤",
    "ध": "𑀥",
    "न": "𑀦",
    "प": "𑀧",
    "फ": "𑀨",
    "ब": "𑀩",
    "भ": "𑀪",
    "म": "𑀫",
    "य": "𑀬",
    "र": "𑀭",
    "ल": "𑀮",
    "व": "𑀯",
    "श": "𑀰",
    "ष": "𑀱",
    "स": "𑀲",
    "ह": "𑀳",
    "ळ": "𑀴",
    "क्ष": "𑀓𑁆𑀱",
    "ज्ञ": "𑀚𑁆𑀜"
  },
  "symbols": {
    "०": "𑁦",
    "१": "𑁧",
    "२": "𑁨",
    "३": "𑁩",
    "४": "𑁪",
    "५": "𑁫",
    "६": "𑁬",
    "७": "𑁭",
    "८": "𑁮",
    "९": "𑁯",
    "ॐ": "𑀑𑀁",
    "ऽ": "",
    "।": "𑁇",
    "॥": "𑁈"
  },
  "zwj": {
    "‍": "‍"
  },
  "skip": {
    "undefined": ""
  },
  "accents": {
    "॑": "॑",
    "॒": "॒"
  },
  "candra": {
    "ॅ": "𑁂"
  },
  "extra_consonants": {
    "क़": "𑀓",
    "ख़": "𑀔",
    "ग़": "𑀕",
    "ज़": "𑀚",
    "ड़": "𑀟",
    "ढ़": "𑀠",
    "फ़": "𑀨",
    "य़": "𑀬",
    "ऱ": "𑀭"
  }
};
schemes.brahmi_tamil = {
  "vowels": {
    "अ": "𑀅",
    "आ": "𑀆",
    "इ": "𑀇",
    "ई": "𑀈",
    "उ": "𑀉",
    "ऊ": "𑀊",
    "ऋ": "𑀭𑀼",
    "ॠ": "𑀭𑀽",
    "ऌ": "𑀮𑀼",
    "ॡ": "𑀮𑀽",
    "ऎ": "𑀏𑁆",
    "ए": "𑀏",
    "ऐ": "𑀐",
    "ऒ": "𑀑𑁆",
    "ओ": "𑀑",
    "औ": "𑀒"
  },
  "vowel_marks": {
    "ा": "𑀸",
    "ि": "𑀺",
    "ी": "𑀻",
    "ु": "𑀼",
    "ू": "𑀽",
    "ृ": "𑁆𑀭𑀼",
    "ॄ": "𑁆𑀭𑀽",
    "ॢ": "𑁆𑀮𑀼",
    "ॣ": "𑁆𑀮𑀽",
    "ॆ": "𑁂𑁆",
    "े": "𑁂",
    "ै": "𑁃",
    "ॊ": "𑁄𑁆",
    "ो": "𑁄",
    "ौ": "𑁅"
  },
  "yogavaahas": {
    "ं": "𑀫𑁆",
    "ः": "𑀂",
    "ँ": "𑀫𑁆"
  },
  "virama": {
    "्": "𑁆"
  },
  "consonants": {
    "क": "𑀓",
    "ख": "𑀓",
    "ग": "𑀓",
    "घ": "𑀓",
    "ङ": "𑀗",
    "च": "𑀘",
    "छ": "𑀘",
    "ज": "𑀚",
    "झ": "𑀚",
    "ञ": "𑀜",
    "ट": "𑀝",
    "ठ": "𑀝",
    "ड": "𑀝",
    "ढ": "𑀝",
    "ण": "𑀡",
    "त": "𑀢",
    "थ": "𑀢",
    "द": "𑀢",
    "ध": "𑀥",
    "न": "𑀦",
    "प": "𑀧",
    "फ": "𑀧",
    "ब": "𑀧",
    "भ": "𑀧",
    "म": "𑀫",
    "य": "𑀬",
    "र": "𑀭",
    "ल": "𑀮",
    "व": "𑀯",
    "श": "𑀰",
    "ष": "𑀱",
    "स": "𑀲",
    "ह": "𑀳",
    "ळ": "𑀴",
    "क्ष": "𑀓𑁆𑀱",
    "ज्ञ": "𑀚𑁆𑀜"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "𑀑𑀫𑁆",
    "ऽ": "'",
    "।": ".",
    "॥": ".."
  },
  "candra": {
    "ॅ": "𑁂𑁆"
  },
  "extra_consonants": {
    "क़": "𑀓",
    "ख़": "𑀓",
    "ग़": "𑀓",
    "ज़": "𑀚",
    "ड़": "𑀝",
    "ढ़": "𑀝",
    "फ़": "𑀧",
    "य़": "𑀬",
    "ऱ": "𑀶"
  }
};
schemes.burmese = {
  "vowels": {
    "अ": "အ",
    "आ": "အာ",
    "इ": "ဣ",
    "ई": "ဤ",
    "उ": "ဥ",
    "ऊ": "ဦ",
    "ऋ": "ၒ",
    "ॠ": "ၓ",
    "ऌ": "ၔ",
    "ॡ": "ၕ",
    "ऎ": "ဧ",
    "ए": "ဧ",
    "ऐ": "အဲ",
    "ऒ": "ဩ",
    "ओ": "ဩ",
    "औ": "ဪ"
  },
  "vowel_marks": {
    "ा": "ာ",
    "ि": "ိ",
    "ी": "ီ",
    "ु": "ု",
    "ू": "ူ",
    "ृ": "ၖ",
    "ॄ": "ၗ",
    "ॢ": "ၘ",
    "ॣ": "ၙ",
    "ॆ": "ေ",
    "े": "ေ",
    "ै": "ဲ",
    "ॊ": "ော",
    "ो": "ော",
    "ौ": "ော်"
  },
  "yogavaahas": {
    "ं": "ံ",
    "ः": "း",
    "ँ": "ံ"
  },
  "virama": {
    "्": "်"
  },
  "consonants": {
    "क": "က",
    "ख": "ခ",
    "ग": "ဂ",
    "घ": "ဃ",
    "ङ": "င",
    "च": "စ",
    "छ": "ဆ",
    "ज": "ဇ",
    "झ": "ဈ",
    "ञ": "ဉ",
    "ट": "ဋ",
    "ठ": "ဌ",
    "ड": "ဍ",
    "ढ": "ဎ",
    "ण": "ဏ",
    "त": "တ",
    "थ": "ထ",
    "द": "ဒ",
    "ध": "ဓ",
    "न": "န",
    "प": "ပ",
    "फ": "ဖ",
    "ब": "ဗ",
    "भ": "ဘ",
    "म": "မ",
    "य": "ယ",
    "र": "ရ",
    "ल": "လ",
    "व": "ဝ",
    "श": "ၐ",
    "ष": "ၑ",
    "स": "သ",
    "ह": "ဟ",
    "ळ": "ဠ",
    "क्ष": "က္ၑ",
    "ज्ञ": "ဇ္ဉ"
  },
  "symbols": {
    "०": "၀",
    "१": "၁",
    "२": "၂",
    "३": "၃",
    "४": "၄",
    "५": "၅",
    "६": "၆",
    "७": "၇",
    "८": "၈",
    "९": "၉",
    "ॐ": "ဩံ",
    "ऽ": "'",
    "।": "၊",
    "॥": "။"
  },
  "zwj": {
    "‍": "‍"
  },
  "skip": {
    "undefined": ""
  },
  "accents": {
    "॑": "॑",
    "॒": "॒"
  },
  "candra": {
    "ॅ": "ေ"
  },
  "extra_consonants": {
    "क़": "က",
    "ख़": "ခ",
    "ग़": "ဂ",
    "ज़": "ဇ",
    "ड़": "ဍ",
    "ढ़": "ဎ",
    "फ़": "ဖ",
    "य़": "ယ",
    "ऱ": "ရ"
  }
};
schemes.cham = {
  "vowels": {
    "अ": "ꨀ",
    "आ": "ꨀꨩ",
    "इ": "ꨁ",
    "ई": "ꨁꨩ",
    "उ": "ꨂ",
    "ऊ": "ꨂꨩ",
    "ऋ": "ꨣꨮ",
    "ॠ": "ꨣꨮꨩ",
    "ऌ": "ꨤꨮ",
    "ॡ": "ꨤꨮꨩ",
    "ऎ": "ꨃ",
    "ए": "ꨃ",
    "ऐ": "ꨄ",
    "ऒ": "ꨅ",
    "ओ": "ꨅ",
    "औ": "ꨀꨯꨱ"
  },
  "vowel_marks": {
    "ा": "ꨩ",
    "ि": "ꨪ",
    "ी": "ꨫ",
    "ु": "ꨭ",
    "ू": "ꨭꨩ",
    "ृ": "ꨴꨮ",
    "ॄ": "ꨴꨮꨩ",
    "ॢ": "ꨵꨮ",
    "ॣ": "ꨵꨮꨩ",
    "ॆ": "ꨯꨮ",
    "े": "ꨯꨮ",
    "ै": "ꨰ",
    "ॊ": "ꨮ",
    "ो": "ꨯ",
    "ौ": "ꨯꨱ"
  },
  "yogavaahas": {
    "ं": "ꩌ",
    "ः": "ꩍ",
    "ँ": "ꩃ"
  },
  "virama": {
    "्": "ʾ"
  },
  "consonants": {
    "क": "ꨆ",
    "ख": "ꨇ",
    "ग": "ꨈ",
    "घ": "ꨉ",
    "ङ": "ꨋ",
    "च": "ꨌ",
    "छ": "ꨍ",
    "ज": "ꨎ",
    "झ": "ꨏ",
    "ञ": "ꨑ",
    "ट": "ꨓ",
    "ठ": "ꨔ",
    "ड": "ꨕ",
    "ढ": "ꨖ",
    "ण": "ꨘ",
    "त": "ꨓ",
    "थ": "ꨔ",
    "द": "ꨕ",
    "ध": "ꨖ",
    "न": "ꨘ",
    "प": "ꨚ",
    "फ": "ꨜ",
    "ब": "ꨝ",
    "भ": "ꨞ",
    "म": "ꨠ",
    "य": "ꨢ",
    "र": "ꨣ",
    "ल": "ꨤ",
    "व": "ꨥ",
    "श": "ꨦ",
    "ष": "ꨦ",
    "स": "ꨧ",
    "ह": "ꨨ",
    "ळ": "ꨤ",
    "क्ष": "ꩀꨦ",
    "ज्ञ": "ꩄꨑ"
  },
  "symbols": {
    "०": "꩐",
    "१": "꩑",
    "२": "꩒",
    "३": "꩓",
    "४": "꩔",
    "५": "꩕",
    "६": "꩖",
    "७": "꩗",
    "८": "꩘",
    "९": "꩙",
    "ॐ": "ꨅꩌ",
    "ऽ": "'",
    "।": "꩝",
    "॥": "꩞"
  },
  "zwj": {
    "‍": "‍"
  },
  "skip": {
    "undefined": ""
  },
  "accents": {
    "॑": "॑",
    "॒": "॒"
  },
  "candra": {
    "ॅ": "ꨯꨮ"
  },
  "extra_consonants": {
    "क़": "ꨆ",
    "ख़": "ꨇ",
    "ग़": "ꨈ",
    "ज़": "ꨎ",
    "ड़": "ꨕ",
    "ढ़": "ꨖ",
    "फ़": "ꨜ",
    "य़": "ꨥ",
    "ऱ": "ꨣ"
  }
};
schemes.devanagari = {
  "vowels": {
    "अ": "अ",
    "आ": "आ",
    "इ": "इ",
    "ई": "ई",
    "उ": "उ",
    "ऊ": "ऊ",
    "ऋ": "ऋ",
    "ॠ": "ॠ",
    "ऌ": "ऌ",
    "ॡ": "ॡ",
    "ऎ": "ऎ",
    "ए": "ए",
    "ऐ": "ऐ",
    "ऒ": "ऒ",
    "ओ": "ओ",
    "औ": "औ",
    "ऍ": "ऍ",
    "ऑ": "ऑ"
  },
  "vowel_marks": {
    "ा": "ा",
    "ि": "ि",
    "ी": "ी",
    "ु": "ु",
    "ू": "ू",
    "ृ": "ृ",
    "ॄ": "ॄ",
    "ॢ": "ॢ",
    "ॣ": "ॣ",
    "ॆ": "ॆ",
    "े": "े",
    "ै": "ै",
    "ॊ": "ॊ",
    "ो": "ो",
    "ौ": "ौ",
    "ॅ": "ॅ",
    "ॉ": "ॉ"
  },
  "yogavaahas": {
    "ं": "ं",
    "ः": "ः",
    "ँ": "ँ",
    "ᳵ": "ᳵ",
    "ᳶ": "ᳶ",
    "ꣳ": "ꣳ"
  },
  "virama": {
    "्": "्"
  },
  "consonants": {
    "क": "क",
    "ख": "ख",
    "ग": "ग",
    "घ": "घ",
    "ङ": "ङ",
    "च": "च",
    "छ": "छ",
    "ज": "ज",
    "झ": "झ",
    "ञ": "ञ",
    "ट": "ट",
    "ठ": "ठ",
    "ड": "ड",
    "ढ": "ढ",
    "ण": "ण",
    "त": "त",
    "थ": "थ",
    "द": "द",
    "ध": "ध",
    "न": "न",
    "प": "प",
    "फ": "फ",
    "ब": "ब",
    "भ": "भ",
    "म": "म",
    "य": "य",
    "र": "र",
    "ल": "ल",
    "व": "व",
    "श": "श",
    "ष": "ष",
    "स": "स",
    "ह": "ह",
    "ळ": "ळ",
    "क्ष": "क्ष",
    "ज्ञ": "ज्ञ"
  },
  "symbols": {
    "०": "०",
    "१": "१",
    "२": "२",
    "३": "३",
    "४": "४",
    "५": "५",
    "६": "६",
    "७": "७",
    "८": "८",
    "९": "९",
    "ॐ": "ॐ",
    "ऽ": "ऽ",
    "।": "।",
    "॥": "॥"
  },
  "zwj": {
    "‍": "‍"
  },
  "zwnj": {
    "‌": "‌"
  },
  "skip": {
    "undefined": ""
  },
  "accents": {
    "॑": "॑",
    "॒": "॒",
    "᳡": "᳡",
    "꣡": "꣡",
    "꣢": "꣢",
    "꣣": "꣣",
    "꣤": "꣤",
    "꣥": "꣥",
    "꣦": "꣦",
    "꣧": "꣧",
    "꣨": "꣨",
    "꣩": "꣩",
    "꣪": "꣪",
    "꣫": "꣫",
    "꣬": "꣬",
    "꣭": "꣭",
    "꣮": "꣮",
    "꣯": "꣯",
    "꣰": "꣰",
    "꣱": "꣱"
  },
  "candra": {
    "ॅ": "ॅ"
  },
  "extra_consonants": {
    "क़": "क़",
    "ख़": "ख़",
    "ग़": "ग़",
    "ज़": "ज़",
    "ड़": "ड़",
    "ढ़": "ढ़",
    "फ़": "फ़",
    "य़": "य़",
    "ऱ": "ऱ",
    "ऴ": "ऴ",
    "ऩ": "ऩ"
  },
  "alternates": {
    "क़": ["क़"],
    "ख़": ["ख़"],
    "फ़": ["फ़"],
    "ज़": ["ज़"],
    "ऩ": ["ऩ"],
    "ड़": ["ड़"],
    "ग़": ["ग़"],
    "ढ़": ["ढ़"],
    "य़": ["य़"],
    "ऱ": ["ऱ"],
    "ऴ": ["ऴ"]
  }
};
schemes.dogra = {
  "vowels": {
    "अ": "𑠀",
    "आ": "𑠁",
    "इ": "𑠂",
    "ई": "𑠃",
    "उ": "𑠄",
    "ऊ": "𑠅",
    "ऋ": "𑠤𑠭",
    "ॠ": "𑠤𑠮",
    "ऌ": "𑠥𑠭",
    "ॡ": "𑠥𑠮",
    "ऎ": "𑠆",
    "ए": "𑠆",
    "ऐ": "𑠇",
    "ऒ": "𑠈",
    "ओ": "𑠈",
    "औ": "𑠉"
  },
  "vowel_marks": {
    "ा": "𑠬",
    "ि": "𑠭",
    "ी": "𑠮",
    "ु": "𑠯",
    "ू": "𑠰",
    "ृ": "𑠱",
    "ॄ": "𑠲",
    "ॢ": "𑠹𑠥𑠭",
    "ॣ": "𑠹𑠥𑠮",
    "ॆ": "𑠳",
    "े": "𑠳",
    "ै": "𑠴",
    "ॊ": "𑠵",
    "ो": "𑠵",
    "ौ": "𑠶"
  },
  "yogavaahas": {
    "ं": "𑠷",
    "ः": "𑠸",
    "ँ": "𑠷"
  },
  "virama": {
    "्": "𑠹"
  },
  "consonants": {
    "क": "𑠊",
    "ख": "𑠋",
    "ग": "𑠌",
    "घ": "𑠍",
    "ङ": "𑠎",
    "च": "𑠏",
    "छ": "𑠐",
    "ज": "𑠑",
    "झ": "𑠒",
    "ञ": "𑠓",
    "ट": "𑠔",
    "ठ": "𑠕",
    "ड": "𑠖",
    "ढ": "𑠗",
    "ण": "𑠘",
    "त": "𑠙",
    "थ": "𑠚",
    "द": "𑠛",
    "ध": "𑠜",
    "न": "𑠝",
    "प": "𑠞",
    "फ": "𑠟",
    "ब": "𑠠",
    "भ": "𑠡",
    "म": "𑠢",
    "य": "𑠣",
    "र": "𑠤",
    "ल": "𑠥",
    "व": "𑠦",
    "श": "𑠧",
    "ष": "𑠨",
    "स": "𑠩",
    "ह": "𑠪",
    "ळ": "𑠥𑠺",
    "क्ष": "𑠊𑠹𑠨",
    "ज्ञ": "𑠑𑠹𑠓"
  },
  "symbols": {
    "०": "०",
    "१": "१",
    "२": "२",
    "३": "३",
    "४": "४",
    "५": "५",
    "६": "६",
    "७": "७",
    "८": "८",
    "९": "९",
    "ॐ": "𑠈𑠷",
    "ऽ": "ऽ",
    "।": "।",
    "॥": "॥"
  },
  "zwj": {
    "‍": "‍"
  },
  "skip": {
    "undefined": ""
  },
  "accents": {
    "॑": "॑",
    "॒": "॒"
  },
  "candra": {
    "ॅ": "𑠳"
  },
  "extra_consonants": {
    "क़": "𑠊𑠺",
    "ख़": "𑠋𑠺",
    "ग़": "𑠌𑠺",
    "ज़": "𑠑𑠺",
    "ड़": "𑠫",
    "ढ़": "𑠗𑠺",
    "फ़": "𑠟𑠺",
    "य़": "𑠣𑠺",
    "ऱ": "𑠤𑠺"
  }
};
schemes.gondi_gunjala = {
  "vowels": {
    "अ": "𑵠",
    "आ": "𑵡",
    "इ": "𑵢",
    "ई": "𑵣",
    "उ": "𑵤",
    "ऊ": "𑵥",
    "ऋ": "𑶈𑶍",
    "ॠ": "𑶈𑶎",
    "ऌ": "𑵵𑶍",
    "ॡ": "𑵵𑶎",
    "ऎ": "𑵧",
    "ए": "𑵧",
    "ऐ": "𑵨",
    "ऒ": "𑵪",
    "ओ": "𑵪",
    "औ": "𑵫"
  },
  "vowel_marks": {
    "ा": "𑶊",
    "ि": "𑶋",
    "ी": "𑶌",
    "ु": "𑶍",
    "ू": "𑶎",
    "ृ": "𑶗𑶈𑶍",
    "ॄ": "𑶗𑶈𑶎",
    "ॢ": "𑶗𑵵𑶍",
    "ॣ": "𑶗𑵵𑶎",
    "ॆ": "𑶐",
    "े": "𑶐",
    "ै": "𑶑",
    "ॊ": "𑶓",
    "ो": "𑶓",
    "ौ": "𑶔"
  },
  "yogavaahas": {
    "ं": "𑶕",
    "ः": "𑶖",
    "ँ": "𑶕"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "𑵱",
    "ख": "𑵲",
    "ग": "𑵶",
    "घ": "𑵷",
    "ङ": "𑶄",
    "च": "𑵻",
    "छ": "𑵼",
    "ज": "𑶀",
    "झ": "𑶁",
    "ञ": "𑵺",
    "ट": "𑵽",
    "ठ": "𑵾",
    "ड": "𑶂",
    "ढ": "𑶃",
    "ण": "𑵺",
    "त": "𑵳",
    "थ": "𑵴",
    "द": "𑵸",
    "ध": "𑵹",
    "न": "𑵺",
    "प": "𑶅",
    "फ": "𑶆",
    "ब": "𑵮",
    "भ": "𑵯",
    "म": "𑵰",
    "य": "𑵬",
    "र": "𑶈",
    "ल": "𑵵",
    "व": "𑵭",
    "श": "𑶉",
    "ष": "𑶉",
    "स": "𑶉",
    "ह": "𑶇",
    "ळ": "𑵿",
    "क्ष": "𑵱𑶗𑶉",
    "ज्ञ": "𑶀𑶗𑵺"
  },
  "symbols": {
    "०": "𑶠",
    "१": "𑶡",
    "२": "𑶢",
    "३": "𑶣",
    "४": "𑶤",
    "५": "𑶥",
    "६": "𑶦",
    "७": "𑶧",
    "८": "𑶨",
    "९": "𑶩",
    "ॐ": "𑶘",
    "ऽ": "",
    "।": ".",
    "॥": ".."
  },
  "zwj": {
    "‍": "‍"
  },
  "skip": {
    "undefined": ""
  },
  "accents": {
    "॑": "॑",
    "॒": "॒"
  },
  "candra": {
    "ॅ": "𑶐"
  },
  "extra_consonants": {
    "क़": "𑵱",
    "ख़": "𑵲",
    "ग़": "𑵶",
    "ज़": "𑶀",
    "ड़": "𑶂",
    "ढ़": "𑶃",
    "फ़": "𑶆",
    "य़": "𑵬",
    "ऱ": "𑶈"
  }
};
schemes.gondi_masaram = {
  "vowels": {
    "अ": "𑴀",
    "आ": "𑴁",
    "इ": "𑴂",
    "ई": "𑴃",
    "उ": "𑴄",
    "ऊ": "𑴅",
    "ऋ": "𑴦𑴶",
    "ॠ": "𑴦𑴵",
    "ऌ": "𑴧𑴴",
    "ॡ": "𑴧𑴵",
    "ऎ": "𑴆",
    "ए": "𑴆",
    "ऐ": "𑴈",
    "ऒ": "𑴉",
    "ओ": "𑴉",
    "औ": "𑴋"
  },
  "vowel_marks": {
    "ा": "𑴱",
    "ि": "𑴲",
    "ी": "𑴳",
    "ु": "𑴴",
    "ू": "𑴵",
    "ृ": "𑴶",
    "ॄ": "𑵅𑴦𑴵",
    "ॢ": "𑵅𑴧𑴴",
    "ॣ": "𑵅𑴧𑴵",
    "ॆ": "𑴺",
    "े": "𑴺",
    "ै": "𑴼",
    "ॊ": "𑴽",
    "ो": "𑴽",
    "ौ": "𑴿"
  },
  "yogavaahas": {
    "ं": "𑵀",
    "ः": "𑵁",
    "ँ": "𑵀"
  },
  "virama": {
    "्": "𑵄"
  },
  "consonants": {
    "क": "𑴌",
    "ख": "𑴍",
    "ग": "𑴎",
    "घ": "𑴏",
    "ङ": "𑴐",
    "च": "𑴑",
    "छ": "𑴒",
    "ज": "𑴓",
    "झ": "𑴔",
    "ञ": "𑴕",
    "ट": "𑴖",
    "ठ": "𑴗",
    "ड": "𑴘",
    "ढ": "𑴙",
    "ण": "𑴚",
    "त": "𑴛",
    "थ": "𑴜",
    "द": "𑴝",
    "ध": "𑴞",
    "न": "𑴟",
    "प": "𑴠",
    "फ": "𑴡",
    "ब": "𑴢",
    "भ": "𑴣",
    "म": "𑴤",
    "य": "𑴥",
    "र": "𑴦",
    "ल": "𑴧",
    "व": "𑴨",
    "श": "𑴩",
    "ष": "𑴪",
    "स": "𑴫",
    "ह": "𑴬",
    "ळ": "𑴭",
    "क्ष": "𑴮",
    "ज्ञ": "𑴯"
  },
  "symbols": {
    "०": "𑵐",
    "१": "𑵑",
    "२": "𑵒",
    "३": "𑵓",
    "४": "𑵔",
    "५": "𑵕",
    "६": "𑵖",
    "७": "𑵗",
    "८": "𑵘",
    "९": "𑵙",
    "ॐ": "𑴉𑵀",
    "ऽ": "",
    "।": "।",
    "॥": "॥"
  },
  "zwj": {
    "‍": "‍"
  },
  "skip": {
    "undefined": ""
  },
  "accents": {
    "॑": "॑",
    "॒": "॒"
  },
  "candra": {
    "ॅ": "𑴺"
  },
  "extra_consonants": {
    "क़": "𑴌𑵂",
    "ख़": "𑴍𑵂",
    "ग़": "𑴎𑵂",
    "ज़": "𑴓𑵂",
    "ड़": "𑴘𑵂",
    "ढ़": "𑴙𑵂",
    "फ़": "𑴡𑵂",
    "य़": "𑴥𑵂",
    "ऱ": "𑴦𑵂"
  }
};
schemes.grantha = {
  "vowels": {
    "अ": "𑌅",
    "आ": "𑌆",
    "इ": "𑌇",
    "ई": "𑌈",
    "उ": "𑌉",
    "ऊ": "𑌊",
    "ऋ": "𑌋",
    "ॠ": "𑍠",
    "ऌ": "𑌌",
    "ॡ": "𑍡",
    "ऎ": "𑌏𑌀",
    "ए": "𑌏",
    "ऐ": "𑌐",
    "ऒ": "𑌓𑌀",
    "ओ": "𑌓",
    "औ": "𑌔"
  },
  "vowel_marks": {
    "ा": "𑌾",
    "ि": "𑌿",
    "ी": "𑍀",
    "ु": "𑍁",
    "ू": "𑍂",
    "ृ": "𑍃",
    "ॄ": "𑍄",
    "ॢ": "𑍢",
    "ॣ": "𑍣",
    "ॆ": "𑍇𑌀",
    "े": "𑍇",
    "ै": "𑍈",
    "ॊ": "𑍋𑌀",
    "ो": "𑍋",
    "ौ": "𑍗"
  },
  "yogavaahas": {
    "ं": "𑌂",
    "ः": "𑌃",
    "ँ": "𑌁"
  },
  "virama": {
    "्": "𑍍"
  },
  "consonants": {
    "क": "𑌕",
    "ख": "𑌖",
    "ग": "𑌗",
    "घ": "𑌘",
    "ङ": "𑌙",
    "च": "𑌚",
    "छ": "𑌛",
    "ज": "𑌜",
    "झ": "𑌝",
    "ञ": "𑌞",
    "ट": "𑌟",
    "ठ": "𑌠",
    "ड": "𑌡",
    "ढ": "𑌢",
    "ण": "𑌣",
    "त": "𑌤",
    "थ": "𑌥",
    "द": "𑌦",
    "ध": "𑌧",
    "न": "𑌨",
    "प": "𑌪",
    "फ": "𑌫",
    "ब": "𑌬",
    "भ": "𑌭",
    "म": "𑌮",
    "य": "𑌯",
    "र": "𑌰",
    "ल": "𑌲",
    "व": "𑌵",
    "श": "𑌶",
    "ष": "𑌷",
    "स": "𑌸",
    "ह": "𑌹",
    "ळ": "𑌳",
    "क्ष": "𑌕𑍍𑌷",
    "ज्ञ": "𑌜𑍍𑌞"
  },
  "symbols": {
    "०": "௦",
    "१": "௧",
    "२": "௨",
    "३": "௩",
    "४": "௪",
    "५": "௫",
    "६": "௬",
    "७": "௭",
    "८": "௮",
    "९": "௯",
    "ॐ": "𑍐",
    "ऽ": "𑌽",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "𑍇"
  },
  "extra_consonants": {
    "क़": "𑌕𑌼",
    "ख़": "𑌖𑌼",
    "ग़": "𑌗𑌼",
    "ज़": "𑌜𑌼",
    "ड़": "𑌡𑌼",
    "ढ़": "𑌢𑌼",
    "फ़": "𑌫𑌼",
    "य़": "𑌯𑌼",
    "ऱ": "𑌰𑌼"
  }
};
schemes.grantha_pandya = {
  "vowels": {
    "अ": "അ",
    "आ": "ആ",
    "इ": "ഇ",
    "ई": "ഈ",
    "उ": "ഉ",
    "ऊ": "ഊ",
    "ऋ": "ഋ",
    "ॠ": "ൠ",
    "ऌ": "ഌ",
    "ॡ": "ൡ",
    "ऎ": "ഏ",
    "ए": "ഏ",
    "ऐ": "ഐ",
    "ऒ": "ഓ",
    "ओ": "ഓ",
    "औ": "ഔ"
  },
  "vowel_marks": {
    "ा": "ാ",
    "ि": "ി",
    "ी": "ീ",
    "ु": "ു",
    "ू": "ൂ",
    "ृ": "ൃ",
    "ॄ": "ൄ",
    "ॢ": "ൢ",
    "ॣ": "ൣ",
    "ॆ": "േ",
    "े": "േ",
    "ै": "ൈ",
    "ॊ": "ോ",
    "ो": "ോ",
    "ौ": "ൗ"
  },
  "yogavaahas": {
    "ं": "ം",
    "ः": "ഃ",
    "ँ": "ം"
  },
  "virama": {
    "्": "്"
  },
  "consonants": {
    "क": "ക",
    "ख": "ഖ",
    "ग": "ഗ",
    "घ": "ഘ",
    "ङ": "ങ",
    "च": "ച",
    "छ": "ഛ",
    "ज": "ജ",
    "झ": "ഝ",
    "ञ": "ഞ",
    "ट": "ട",
    "ठ": "ഠ",
    "ड": "ഡ",
    "ढ": "ഢ",
    "ण": "ണ",
    "त": "ത",
    "थ": "ഥ",
    "द": "ദ",
    "ध": "ധ",
    "न": "ന",
    "प": "പ",
    "फ": "ഫ",
    "ब": "ബ",
    "भ": "ഭ",
    "म": "മ",
    "य": "യ",
    "र": "ര",
    "ल": "ല",
    "व": "വ",
    "श": "ശ",
    "ष": "ഷ",
    "स": "സ",
    "ह": "ഹ",
    "ळ": "ള",
    "क्ष": "ക്ഷ",
    "ज्ञ": "ജ്ഞ"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "ഓം",
    "ऽ": "ഽ",
    "।": "।",
    "॥": "॥"
  },
  "zwj": {
    "‍": "‍"
  },
  "skip": {
    "undefined": ""
  },
  "accents": {
    "॑": "॑",
    "॒": "॒"
  },
  "candra": {
    "ॅ": "േ"
  },
  "extra_consonants": {
    "क़": "ക",
    "ख़": "ഖ",
    "ग़": "ഗ",
    "ज़": "ജ",
    "ड़": "ഡ",
    "ढ़": "ഢ",
    "फ़": "ഫ",
    "य़": "യ",
    "ऱ": "ര"
  }
};
schemes.gujarati = {
  "vowels": {
    "अ": "અ",
    "आ": "આ",
    "इ": "ઇ",
    "ई": "ઈ",
    "उ": "ઉ",
    "ऊ": "ઊ",
    "ऋ": "ઋ",
    "ॠ": "ૠ",
    "ऌ": "ઌ",
    "ॡ": "ૡ",
    "ऎ": "ऎ",
    "ए": "એ",
    "ऐ": "ઐ",
    "ऒ": "ऒ",
    "ओ": "ઓ",
    "औ": "ઔ"
  },
  "vowel_marks": {
    "ा": "ા",
    "ि": "િ",
    "ी": "ી",
    "ु": "ુ",
    "ू": "ૂ",
    "ृ": "ૃ",
    "ॄ": "ૄ",
    "ॢ": "ૢ",
    "ॣ": "ૣ",
    "ॆ": "ॆ",
    "े": "ે",
    "ै": "ૈ",
    "ॊ": "ॊ",
    "ो": "ો",
    "ौ": "ૌ"
  },
  "yogavaahas": {
    "ं": "ં",
    "ः": "ઃ",
    "ँ": "ઁ"
  },
  "virama": {
    "्": "્"
  },
  "consonants": {
    "क": "ક",
    "ख": "ખ",
    "ग": "ગ",
    "घ": "ઘ",
    "ङ": "ઙ",
    "च": "ચ",
    "छ": "છ",
    "ज": "જ",
    "झ": "ઝ",
    "ञ": "ઞ",
    "ट": "ટ",
    "ठ": "ઠ",
    "ड": "ડ",
    "ढ": "ઢ",
    "ण": "ણ",
    "त": "ત",
    "थ": "થ",
    "द": "દ",
    "ध": "ધ",
    "न": "ન",
    "प": "પ",
    "फ": "ફ",
    "ब": "બ",
    "भ": "ભ",
    "म": "મ",
    "य": "ય",
    "र": "ર",
    "ल": "લ",
    "व": "વ",
    "श": "શ",
    "ष": "ષ",
    "स": "સ",
    "ह": "હ",
    "ळ": "ળ",
    "क्ष": "ક્ષ",
    "ज्ञ": "જ્ઞ"
  },
  "symbols": {
    "०": "૦",
    "१": "૧",
    "२": "૨",
    "३": "૩",
    "४": "૪",
    "५": "૫",
    "६": "૬",
    "७": "૭",
    "८": "૮",
    "९": "૯",
    "ॐ": "ૐ",
    "ऽ": "ઽ",
    "।": "।",
    "॥": "॥"
  },
  "zwj": {
    "‍": ""
  },
  "skip": {
    "undefined": ""
  },
  "accents": {
    "॑": "॑",
    "॒": "॒"
  },
  "candra": {
    "ॅ": "ૅ"
  },
  "extra_consonants": {
    "क़": "ક઼",
    "ख़": "ખ઼",
    "ग़": "ગ઼",
    "ज़": "જ઼",
    "ड़": "ડ઼",
    "ढ़": "ઢ઼",
    "फ़": "ફ઼",
    "य़": "ય઼",
    "ऱ": "ર઼",
    "ऴ": "ળ઼"
  }
};
schemes.gurmukhi = {
  "vowels": {
    "अ": "ਅ",
    "आ": "ਆ",
    "इ": "ਇ",
    "ई": "ਈ",
    "उ": "ਉ",
    "ऊ": "ਊ",
    "ऋ": "",
    "ॠ": "",
    "ऌ": "",
    "ॡ": "",
    "ऎ": "",
    "ए": "ਏ",
    "ऐ": "ਐ",
    "ऒ": "",
    "ओ": "ਓ",
    "औ": "ਔ"
  },
  "vowel_marks": {
    "ा": "ਾ",
    "ि": "ਿ",
    "ी": "ੀ",
    "ु": "ੁ",
    "ू": "ੂ",
    "ृ": "",
    "ॄ": "",
    "ॢ": "",
    "ॣ": "",
    "ॆ": "",
    "े": "ੇ",
    "ै": "ੈ",
    "ॊ": "",
    "ो": "ੋ",
    "ौ": "ੌ"
  },
  "yogavaahas": {
    "ं": "ਂ",
    "ः": "ਃ",
    "ँ": "ਁ"
  },
  "virama": {
    "्": "੍"
  },
  "consonants": {
    "क": "ਕ",
    "ख": "ਖ",
    "ग": "ਗ",
    "घ": "ਘ",
    "ङ": "ਙ",
    "च": "ਚ",
    "छ": "ਛ",
    "ज": "ਜ",
    "झ": "ਝ",
    "ञ": "ਞ",
    "ट": "ਟ",
    "ठ": "ਠ",
    "ड": "ਡ",
    "ढ": "ਢ",
    "ण": "ਣ",
    "त": "ਤ",
    "थ": "ਥ",
    "द": "ਦ",
    "ध": "ਧ",
    "न": "ਨ",
    "प": "ਪ",
    "फ": "ਫ",
    "ब": "ਬ",
    "भ": "ਭ",
    "म": "ਮ",
    "य": "ਯ",
    "र": "ਰ",
    "ल": "ਲ",
    "व": "ਵ",
    "श": "ਸ਼",
    "ष": "",
    "स": "ਸ",
    "ह": "ਹ",
    "ळ": "ਲ਼",
    "क्ष": "ਕ੍ਸ਼",
    "ज्ञ": "ਜ੍ਞ"
  },
  "symbols": {
    "०": "੦",
    "१": "੧",
    "२": "੨",
    "३": "੩",
    "४": "੪",
    "५": "੫",
    "६": "੬",
    "७": "੭",
    "८": "੮",
    "९": "੯",
    "ॐ": "ੴ",
    "ऽ": "ऽ",
    "।": "।",
    "॥": "॥"
  },
  "alternates": {
    "ਂ": ["ੰ"]
  },
  "extra_consonants": {
    "क़": "ਕ਼",
    "ख़": "ਖ਼",
    "ग़": "ਗ਼",
    "ज़": "ਜ਼",
    "ड़": "ੜ",
    "ढ़": "ਢ਼",
    "फ़": "ਫ਼",
    "य़": "ਯ਼",
    "ऱ": "ਰ਼",
    "ऴ": "ਲ਼਼",
    "ऩ": "ਨ਼"
  },
  "accents": {
    "॑": "ੑ",
    "॒": "॒"
  }
};
schemes.javanese = {
  "vowels": {
    "अ": "ꦄ",
    "आ": "ꦄꦴ",
    "इ": "ꦆ",
    "ई": "ꦇ",
    "उ": "ꦈ",
    "ऊ": "ꦈꦴ",
    "ऋ": "ꦉ",
    "ॠ": "ꦉꦴ",
    "ऌ": "ꦊ",
    "ॡ": "ꦋ",
    "ऎ": "",
    "ए": "ꦌ",
    "ऐ": "ꦍ",
    "ऒ": "",
    "ओ": "ꦎ",
    "औ": "ꦎꦴ"
  },
  "vowel_marks": {
    "ा": "ꦴ",
    "ि": "ꦶ",
    "ी": "ꦷ",
    "ु": "ꦸ",
    "ू": "ꦹ",
    "ृ": "ꦽ",
    "ॄ": "꧀ꦉꦴ",
    "ॢ": "꧀ꦊ",
    "ॣ": "꧀ꦋ",
    "ॆ": "",
    "े": "ꦺ",
    "ै": "ꦻ",
    "ॊ": "",
    "ो": "ꦺꦴ",
    "ौ": "ꦻꦴ"
  },
  "yogavaahas": {
    "ं": "ꦁ",
    "ः": "ꦃ",
    "ँ": "ꦀ"
  },
  "virama": {
    "्": "꧀"
  },
  "consonants": {
    "क": "ꦏ",
    "ख": "ꦑ",
    "ग": "ꦒ",
    "घ": "ꦓ",
    "ङ": "ꦔ",
    "च": "ꦕ",
    "छ": "ꦖ",
    "ज": "ꦗ",
    "झ": "ꦙ",
    "ञ": "ꦚ",
    "ट": "ꦛ",
    "ठ": "ꦜ",
    "ड": "ꦝ",
    "ढ": "ꦞ",
    "ण": "ꦟ",
    "त": "ꦠ",
    "थ": "ꦡ",
    "द": "ꦢ",
    "ध": "ꦣ",
    "न": "ꦤ",
    "प": "ꦥ",
    "फ": "ꦦ",
    "ब": "ꦧ",
    "भ": "ꦨ",
    "म": "ꦩ",
    "य": "ꦪ",
    "र": "ꦫ",
    "ल": "ꦭ",
    "व": "ꦮ",
    "श": "ꦯ",
    "ष": "ꦰ",
    "स": "ꦱ",
    "ह": "ꦲ",
    "ळ": "ꦭ꦳",
    "क्ष": "ꦏ꧀ꦰ",
    "ज्ञ": "ꦗ꧀ꦚ"
  },
  "symbols": {
    "०": "꧐",
    "१": "꧑",
    "२": "꧒",
    "३": "꧓",
    "४": "꧔",
    "५": "꧕",
    "६": "꧖",
    "७": "꧗",
    "८": "꧘",
    "९": "꧙",
    "ॐ": "ꦎꦀ",
    "ऽ": "'",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "ꦼ"
  }
};
schemes.kannada = {
  "vowels": {
    "अ": "ಅ",
    "आ": "ಆ",
    "इ": "ಇ",
    "ई": "ಈ",
    "उ": "ಉ",
    "ऊ": "ಊ",
    "ऋ": "ಋ",
    "ॠ": "ೠ",
    "ऌ": "ಌ",
    "ॡ": "ೡ",
    "ऎ": "ಎ",
    "ए": "ಏ",
    "ऐ": "ಐ",
    "ऒ": "ಒ",
    "ओ": "ಓ",
    "औ": "ಔ"
  },
  "vowel_marks": {
    "ा": "ಾ",
    "ि": "ಿ",
    "ी": "ೀ",
    "ु": "ು",
    "ू": "ೂ",
    "ृ": "ೃ",
    "ॄ": "ೄ",
    "ॢ": "ೢ",
    "ॣ": "ೣ",
    "ॆ": "ೆ",
    "े": "ೇ",
    "ै": "ೈ",
    "ॊ": "ೊ",
    "ो": "ೋ",
    "ौ": "ೌ"
  },
  "yogavaahas": {
    "ं": "ಂ",
    "ः": "ಃ",
    "ँ": "ಁ",
    "ᳵ": "ೱ",
    "ᳶ": "ೲ"
  },
  "virama": {
    "्": "್"
  },
  "consonants": {
    "क": "ಕ",
    "ख": "ಖ",
    "ग": "ಗ",
    "घ": "ಘ",
    "ङ": "ಙ",
    "च": "ಚ",
    "छ": "ಛ",
    "ज": "ಜ",
    "झ": "ಝ",
    "ञ": "ಞ",
    "ट": "ಟ",
    "ठ": "ಠ",
    "ड": "ಡ",
    "ढ": "ಢ",
    "ण": "ಣ",
    "त": "ತ",
    "थ": "ಥ",
    "द": "ದ",
    "ध": "ಧ",
    "न": "ನ",
    "प": "ಪ",
    "फ": "ಫ",
    "ब": "ಬ",
    "भ": "ಭ",
    "म": "ಮ",
    "य": "ಯ",
    "र": "ರ",
    "ल": "ಲ",
    "व": "ವ",
    "श": "ಶ",
    "ष": "ಷ",
    "स": "ಸ",
    "ह": "ಹ",
    "ळ": "ಳ",
    "क्ष": "ಕ್ಷ",
    "ज्ञ": "ಜ್ಞ"
  },
  "symbols": {
    "०": "೦",
    "१": "೧",
    "२": "೨",
    "३": "೩",
    "४": "೪",
    "५": "೫",
    "६": "೬",
    "७": "೭",
    "८": "೮",
    "९": "೯",
    "ॐ": "ಓಂ",
    "ऽ": "ಽ",
    "।": "।",
    "॥": "॥"
  },
  "extra_consonants": {
    "क़": "ಕ಼",
    "ख़": "ಖ಼",
    "ग़": "ಗ಼",
    "ज़": "ಜ಼",
    "ड़": "ಡ಼",
    "ढ़": "ಢ಼",
    "फ़": "ಫ಼",
    "य़": "ಯ಼",
    "ऱ": "ಱ",
    "ऴ": "ೞ"
  },
  "alternates": {
    "ಜ಼": ["ಸ಼"]
  }
};
schemes.khamti_shan = {
  "vowels": {
    "अ": "အ",
    "आ": "အႃ",
    "इ": "အိ",
    "ई": "အီ",
    "उ": "အု",
    "ऊ": "အူ",
    "ऋ": "ရိ",
    "ॠ": "ရီ",
    "ऌ": "လိ",
    "ॡ": "လီ",
    "ऎ": "",
    "ए": "အေ",
    "ऐ": "အႆ",
    "ऒ": "",
    "ओ": "အူဝ်",
    "औ": "အဝ်"
  },
  "vowel_marks": {
    "ा": "ႃ",
    "ि": "ိ",
    "ी": "ီ",
    "ु": "ု",
    "ू": "ူ",
    "ृ": "်ရိ",
    "ॄ": "်ရီ",
    "ॢ": "်လိ",
    "ॣ": "်လီ",
    "ॆ": "",
    "े": "ေ",
    "ै": "ႆ",
    "ॊ": "",
    "ो": "ူဝ်",
    "ौ": "ဝ်"
  },
  "yogavaahas": {
    "ं": "ံ",
    "ः": "း",
    "ँ": "ံ"
  },
  "virama": {
    "्": "်"
  },
  "consonants": {
    "क": "က",
    "ख": "ၵ",
    "ग": "ꩠ",
    "घ": "ၷ",
    "ङ": "င",
    "च": "ꩡ",
    "छ": "ꩢ",
    "ज": "ꩣ",
    "झ": "ꩤ",
    "ञ": "ꩥ",
    "ट": "ꩦ",
    "ठ": "ꩧ",
    "ड": "ꩨ",
    "ढ": "ꩩ",
    "ण": "ꧣ",
    "त": "တ",
    "थ": "ထ",
    "द": "ၻ",
    "ध": "ꩪ",
    "न": "ꩫ",
    "प": "ပ",
    "फ": "ၸ",
    "ब": "ၿ",
    "भ": "ၹ",
    "म": "မ",
    "य": "ယ",
    "र": "ꩳ",
    "ल": "လ",
    "व": "ဝ",
    "श": "ꩬ",
    "ष": "ꩬ",
    "स": "ꩬ",
    "ह": "ꩭ",
    "ळ": "ꩮ",
    "क्ष": "က်ꩬ",
    "ज्ञ": "ꩣ်ꩥ"
  },
  "symbols": {
    "०": "႐",
    "१": "႑",
    "२": "႒",
    "३": "႓",
    "४": "႔",
    "५": "႕",
    "६": "႖",
    "७": "႗",
    "८": "႘",
    "९": "႙",
    "ॐ": "အေႃံ",
    "ऽ": "'",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "ႄ"
  }
};
schemes.kharoshthi = {
  "vowels": {
    "अ": "𐨀",
    "आ": "𐨀𐨌",
    "इ": "𐨀𐨁",
    "ई": "𐨀𐨁𐨌",
    "उ": "𐨀𐨂",
    "ऊ": "𐨀𐨂𐨌",
    "ऋ": "𐨀𐨃",
    "ॠ": "𐨀𐨃𐨌",
    "ऌ": "𐨫𐨂",
    "ॡ": "𐨫𐨂𐨌",
    "ऎ": "",
    "ए": "𐨀𐨅",
    "ऐ": "𐨀𐨅𐨌",
    "ऒ": "",
    "ओ": "𐨀𐨆",
    "औ": "𐨀𐨆𐨌"
  },
  "vowel_marks": {
    "ा": "𐨌",
    "ि": "𐨁",
    "ी": "𐨁𐨌",
    "ु": "𐨂",
    "ू": "𐨂𐨌",
    "ृ": "𐨃",
    "ॄ": "𐨃𐨌",
    "ॢ": "𐨿𐨫𐨂",
    "ॣ": "𐨿𐨫𐨂𐨌",
    "ॆ": "",
    "े": "𐨅",
    "ै": "𐨅𐨌",
    "ॊ": "",
    "ो": "𐨆",
    "ौ": "𐨆𐨌"
  },
  "yogavaahas": {
    "ं": "𐨎",
    "ः": "𐨏",
    "ँ": "𐨎"
  },
  "virama": {
    "्": "𐨿"
  },
  "consonants": {
    "क": "𐨐",
    "ख": "𐨑",
    "ग": "𐨒",
    "घ": "𐨓",
    "ङ": "𐨣",
    "च": "𐨕",
    "छ": "𐨖",
    "ज": "𐨗",
    "झ": "𐨗",
    "ञ": "𐨙",
    "ट": "𐨚",
    "ठ": "𐨛",
    "ड": "𐨜",
    "ढ": "𐨝",
    "ण": "𐨞",
    "त": "𐨟",
    "थ": "𐨠",
    "द": "𐨡",
    "ध": "𐨢",
    "न": "𐨣",
    "प": "𐨤",
    "फ": "𐨥",
    "ब": "𐨦",
    "भ": "𐨧",
    "म": "𐨨",
    "य": "𐨩",
    "र": "𐨪",
    "ल": "𐨫",
    "व": "𐨬",
    "श": "𐨭",
    "ष": "𐨮",
    "स": "𐨯",
    "ह": "𐨱",
    "ळ": "𐨫",
    "क्ष": "𐨐𐨿𐨮",
    "ज्ञ": "𐨗𐨿𐨙"
  },
  "symbols": {
    "०": "",
    "१": "𐩀",
    "२": "𐩁",
    "३": "𐩂",
    "४": "𐩃",
    "५": "𐩃𐩀",
    "६": "𐩃𐩁",
    "७": "𐩃𐩂",
    "८": "𐩃𐩃",
    "९": "𐩃𐩃𐩀",
    "ॐ": "𐨀𐨅𐨎",
    "ऽ": "",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "𐨅"
  }
};
schemes.khmer = {
  "vowels": {
    "अ": "អ",
    "आ": "អា",
    "इ": "ឥ",
    "ई": "ឦ",
    "उ": "ឧ",
    "ऊ": "ឩ",
    "ऋ": "ឫ",
    "ॠ": "ឬ",
    "ऌ": "ឭ",
    "ॡ": "ឮ",
    "ऎ": "",
    "ए": "ឯ",
    "ऐ": "ឰ",
    "ऒ": "",
    "ओ": "ឱ",
    "औ": "ឳ"
  },
  "vowel_marks": {
    "ा": "ា",
    "ि": "ិ",
    "ी": "ី",
    "ु": "ុ",
    "ू": "ូ",
    "ृ": "្ឫ",
    "ॄ": "្ឬ",
    "ॢ": "្ឭ",
    "ॣ": "្ឮ",
    "ॆ": "",
    "े": "េ",
    "ै": "ៃ",
    "ॊ": "",
    "ो": "ោ",
    "ौ": "ៅ"
  },
  "yogavaahas": {
    "ं": "ំ",
    "ः": "ះ",
    "ँ": "ំ"
  },
  "virama": {
    "्": "៑"
  },
  "consonants": {
    "क": "ក",
    "ख": "ខ",
    "ग": "គ",
    "घ": "ឃ",
    "ङ": "ង",
    "च": "ច",
    "छ": "ឆ",
    "ज": "ជ",
    "झ": "ឈ",
    "ञ": "ញ",
    "ट": "ដ",
    "ठ": "ឋ",
    "ड": "ឌ",
    "ढ": "ឍ",
    "ण": "ណ",
    "त": "ត",
    "थ": "ថ",
    "द": "ទ",
    "ध": "ធ",
    "न": "ន",
    "प": "ប",
    "फ": "ផ",
    "ब": "ព",
    "भ": "ភ",
    "म": "ម",
    "य": "យ",
    "र": "រ",
    "ल": "ល",
    "व": "វ",
    "श": "ឝ",
    "ष": "ឞ",
    "स": "ស",
    "ह": "ហ",
    "ळ": "ឡ",
    "क्ष": "ក្ឞ",
    "ज्ञ": "ជ្ញ"
  },
  "symbols": {
    "०": "០",
    "१": "១",
    "२": "២",
    "३": "៣",
    "४": "៤",
    "५": "៥",
    "६": "៦",
    "७": "៧",
    "८": "៨",
    "९": "៩",
    "ॐ": "ឱំ",
    "ऽ": "ៜ",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "េ"
  }
};
schemes.khom_thai = {
  "vowels": {
    "अ": "อ",
    "आ": "อา",
    "इ": "อิ",
    "ई": "อี",
    "उ": "อุ",
    "ऊ": "อู",
    "ऋ": "รุ",
    "ॠ": "รู",
    "ऌ": "ลุ",
    "ॡ": "ลู",
    "ऎ": "",
    "ए": "เอ",
    "ऐ": "อยฺ",
    "ऒ": "",
    "ओ": "โอ",
    "औ": "อวฺ"
  },
  "vowel_marks": {
    "ा": "า",
    "ि": "ิ",
    "ी": "ี",
    "ु": "ุ",
    "ू": "ู",
    "ृ": "ฺฤ",
    "ॄ": "ฺฤๅ",
    "ॢ": "ฺลุ",
    "ॣ": "ฺลู",
    "ॆ": "",
    "े": "เ",
    "ै": "ใ",
    "ॊ": "",
    "ो": "โ",
    "ौ": "วฺ"
  },
  "yogavaahas": {
    "ं": "ํ",
    "ः": "หฺ",
    "ँ": "ํ"
  },
  "virama": {
    "्": "ฺ"
  },
  "consonants": {
    "क": "ก",
    "ख": "ข",
    "ग": "ค",
    "घ": "ฆ",
    "ङ": "ง",
    "च": "จ",
    "छ": "ฉ",
    "ज": "ช",
    "झ": "ฌ",
    "ञ": "ญ",
    "ट": "ฏ",
    "ठ": "ฐ",
    "ड": "ฑ",
    "ढ": "ฒ",
    "ण": "ณ",
    "त": "ต",
    "थ": "ถ",
    "द": "ท",
    "ध": "ธ",
    "न": "น",
    "प": "ป",
    "फ": "ผ",
    "ब": "พ",
    "भ": "ภ",
    "म": "ม",
    "य": "ย",
    "र": "ร",
    "ल": "ล",
    "व": "ว",
    "श": "ส",
    "ष": "ส",
    "स": "ส",
    "ह": "ห",
    "ळ": "ฬ",
    "क्ष": "กฺส",
    "ज्ञ": "ชฺญ"
  },
  "symbols": {
    "०": "๐",
    "१": "๑",
    "२": "๒",
    "३": "๓",
    "४": "๔",
    "५": "๕",
    "६": "๖",
    "७": "๗",
    "८": "๘",
    "९": "๙",
    "ॐ": "โอํ",
    "ऽ": "",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "แ"
  }
};
schemes.khudawadi = {
  "vowels": {
    "अ": "𑊰",
    "आ": "𑊱",
    "इ": "𑊲",
    "ई": "𑊳",
    "उ": "𑊴",
    "ऊ": "𑊵",
    "ऋ": "𑋙𑋡",
    "ॠ": "𑋙𑋢",
    "ऌ": "𑋚𑋡",
    "ॡ": "𑋚𑋢",
    "ऎ": "",
    "ए": "𑊶",
    "ऐ": "𑊷",
    "ऒ": "",
    "ओ": "𑊸",
    "औ": "𑊹"
  },
  "vowel_marks": {
    "ा": "𑋠",
    "ि": "𑋡",
    "ी": "𑋢",
    "ु": "𑋣",
    "ू": "𑋤",
    "ृ": "𑋪𑋙𑋡",
    "ॄ": "𑋪𑋙𑋢",
    "ॢ": "𑋪𑋚𑋡",
    "ॣ": "𑋪𑋚𑋢",
    "ॆ": "",
    "े": "𑋥",
    "ै": "𑋦",
    "ॊ": "",
    "ो": "𑋧",
    "ौ": "𑋨"
  },
  "yogavaahas": {
    "ं": "𑋟",
    "ः": "𑋞𑋪",
    "ँ": "𑋟"
  },
  "virama": {
    "्": "𑋪"
  },
  "consonants": {
    "क": "𑊺",
    "ख": "𑊻",
    "ग": "𑊼",
    "घ": "𑊾",
    "ङ": "𑊿",
    "च": "𑋀",
    "छ": "𑋁",
    "ज": "𑋂",
    "झ": "𑋄",
    "ञ": "𑋅",
    "ट": "𑋆",
    "ठ": "𑋇",
    "ड": "𑋈",
    "ढ": "𑋋",
    "ण": "𑋌",
    "त": "𑋍",
    "थ": "𑋎",
    "द": "𑋏",
    "ध": "𑋐",
    "न": "𑋑",
    "प": "𑋒",
    "फ": "𑋓",
    "ब": "𑋔",
    "भ": "𑋖",
    "म": "𑋗",
    "य": "𑋘",
    "र": "𑋙",
    "ल": "𑋚",
    "व": "𑋛",
    "श": "𑋜",
    "ष": "𑋜𑋩",
    "स": "𑋝",
    "ह": "𑋞",
    "ळ": "𑋚𑋩",
    "क्ष": "𑊺𑋪𑋜𑋩",
    "ज्ञ": "𑋂𑋪𑋅"
  },
  "symbols": {
    "०": "𑋰",
    "१": "𑋱",
    "२": "𑋲",
    "३": "𑋳",
    "४": "𑋴",
    "५": "𑋵",
    "६": "𑋶",
    "७": "𑋷",
    "८": "𑋸",
    "९": "𑋹",
    "ॐ": "𑊸𑋟",
    "ऽ": "ऽ",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "𑋥"
  }
};
schemes.lao = {
  "vowels": {
    "अ": "ອະ",
    "आ": "ອາ",
    "इ": "ອິ",
    "ई": "ອີ",
    "उ": "ອຸ",
    "ऊ": "ອູ",
    "ऋ": "ຣຸ",
    "ॠ": "ຣູ",
    "ऌ": "ລຸ",
    "ॡ": "ລູ",
    "ऎ": "",
    "ए": "ເອ",
    "ऐ": "ໄອ",
    "ऒ": "",
    "ओ": "ໂອ",
    "औ": "ເອົາ"
  },
  "vowel_marks": {
    "ा": "າ",
    "ि": "ິ",
    "ी": "ີ",
    "ु": "ຸ",
    "ू": "ູ",
    "ृ": "ຣຸ",
    "ॄ": "ຣູ",
    "ॢ": "ລຸ",
    "ॣ": "ລູ",
    "ॆ": "",
    "े": "ເ",
    "ै": "ໄ",
    "ॊ": "",
    "ो": "ໂ",
    "ौ": "ເົາ"
  },
  "yogavaahas": {
    "ं": "ງ",
    "ः": "ຫ",
    "ँ": "ງ"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "ກະ",
    "ख": "ຂະ",
    "ग": "ຄະ",
    "घ": "ຄະ",
    "ङ": "ງະ",
    "च": "ຈະ",
    "छ": "ຈະ",
    "ज": "ຊະ",
    "झ": "ຊະ",
    "ञ": "ຍະ",
    "ट": "ຕະ",
    "ठ": "ຖະ",
    "ड": "ທະ",
    "ढ": "ທະ",
    "ण": "ນະ",
    "त": "ຕະ",
    "थ": "ຖະ",
    "द": "ທະ",
    "ध": "ທະ",
    "न": "ນະ",
    "प": "ປະ",
    "फ": "ຜະ",
    "ब": "ພະ",
    "भ": "ພະ",
    "म": "ມະ",
    "य": "ຢະ",
    "र": "ຣະ",
    "ल": "ລະ",
    "व": "ວະ",
    "श": "ສະ",
    "ष": "ສະ",
    "स": "ສະ",
    "ह": "ຫະ",
    "ळ": "ລະ",
    "क्ष": "ກສະ",
    "ज्ञ": "ຊຍະ"
  },
  "symbols": {
    "०": "໐",
    "१": "໑",
    "२": "໒",
    "३": "໓",
    "४": "໔",
    "५": "໕",
    "६": "໖",
    "७": "໗",
    "८": "໘",
    "९": "໙",
    "ॐ": "ໂອງ",
    "ऽ": "'",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "ແະ"
  }
};
schemes.lao_pali = {
  "vowels": {
    "अ": "ອ",
    "आ": "ອາ",
    "इ": "ອິ",
    "ई": "ອີ",
    "उ": "ອຸ",
    "ऊ": "ອູ",
    "ऋ": "ຣຸ",
    "ॠ": "ຣູ",
    "ऌ": "ລຸ",
    "ॡ": "ລູ",
    "ऎ": "",
    "ए": "ເອ",
    "ऐ": "ໄອ",
    "ऒ": "",
    "ओ": "ໂອ",
    "औ": "ເອົາ"
  },
  "vowel_marks": {
    "ा": "າ",
    "ि": "ິ",
    "ी": "ີ",
    "ु": "ຸ",
    "ू": "ູ",
    "ृ": "຺ຣຸ",
    "ॄ": "຺ຣູ",
    "ॢ": "຺ລຸ",
    "ॣ": "຺ລູ",
    "ॆ": "",
    "े": "ເ",
    "ै": "ໄ",
    "ॊ": "",
    "ो": "ໂ",
    "ौ": "ເົາ"
  },
  "yogavaahas": {
    "ं": "ໍ",
    "ः": "ຫ໌",
    "ँ": "ໍ"
  },
  "virama": {
    "्": "຺"
  },
  "consonants": {
    "क": "ກ",
    "ख": "ຂ",
    "ग": "ຄ",
    "घ": "ຆ",
    "ङ": "ງ",
    "च": "ຈ",
    "छ": "ຉ",
    "ज": "ຊ",
    "झ": "ຌ",
    "ञ": "ຎ",
    "ट": "ຏ",
    "ठ": "ຐ",
    "ड": "ຑ",
    "ढ": "ຒ",
    "ण": "ຓ",
    "त": "ຕ",
    "थ": "ຖ",
    "द": "ທ",
    "ध": "ຘ",
    "न": "ນ",
    "प": "ປ",
    "फ": "ຜ",
    "ब": "ພ",
    "भ": "ຠ",
    "म": "ມ",
    "य": "ຍ",
    "र": "ຣ",
    "ल": "ລ",
    "व": "ວ",
    "श": "ຨ",
    "ष": "ຩ",
    "स": "ສ",
    "ह": "ຫ",
    "ळ": "ຬ",
    "क्ष": "ກ຺ຩ",
    "ज्ञ": "ຊ຺ຎ"
  },
  "symbols": {
    "०": "໐",
    "१": "໑",
    "२": "໒",
    "३": "໓",
    "४": "໔",
    "५": "໕",
    "६": "໖",
    "७": "໗",
    "८": "໘",
    "९": "໙",
    "ॐ": "ໂອໍ",
    "ऽ": "'",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "ແະ"
  }
};
schemes.lepcha = {
  "vowels": {
    "अ": "ᰣ",
    "आ": "ᰣᰦ",
    "इ": "ᰣᰧ",
    "ई": "ᰣᰧᰶ",
    "उ": "ᰣᰪ",
    "ऊ": "ᰣᰫ",
    "ऋ": "ᰛᰪ",
    "ॠ": "ᰛᰫ",
    "ऌ": "ᰜᰪ",
    "ॡ": "ᰜᰫ",
    "ऎ": "",
    "ए": "ᰣᰬ",
    "ऐ": "ᰣᰬ",
    "ऒ": "",
    "ओ": "ᰣᰨ",
    "औ": "ᰣᰩ"
  },
  "vowel_marks": {
    "ा": "ᰦ",
    "ि": "ᰧ",
    "ी": "ᰧᰶ",
    "ु": "ᰪ",
    "ू": "ᰫ",
    "ृ": "ᰥᰪ",
    "ॄ": "ᰥᰫ",
    "ॢ": "ᰜᰪ",
    "ॣ": "ᰜᰫ",
    "ॆ": "",
    "े": "ᰬ",
    "ै": "ᰬ",
    "ॊ": "",
    "ो": "ᰨ",
    "ौ": "ᰩ"
  },
  "yogavaahas": {
    "ं": "ᰴ",
    "ः": "ᰝ",
    "ँ": "ᰴ"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "ᰀ",
    "ख": "ᰂ",
    "ग": "ᰃ",
    "घ": "ᰃ",
    "ङ": "ᰅ",
    "च": "ᰆ",
    "छ": "ᰇ",
    "ज": "ᰈ",
    "झ": "ᰈ",
    "ञ": "ᰉ",
    "ट": "ᱍ",
    "ठ": "ᱎ",
    "ड": "ᱏ",
    "ढ": "ᱏ",
    "ण": "ᰍ",
    "त": "ᰊ",
    "थ": "ᰋ",
    "द": "ᰌ",
    "ध": "ᰌ",
    "न": "ᰍ",
    "प": "ᰎ",
    "फ": "ᰐ",
    "ब": "ᰓ",
    "भ": "ᰓ",
    "म": "ᰕ",
    "य": "ᰚ",
    "र": "ᰛ",
    "ल": "ᰜ",
    "व": "ᰟ",
    "श": "ᰡ",
    "ष": "ᰡ᰷",
    "स": "ᰠ",
    "ह": "ᰝ",
    "ळ": "ᰜ᰷",
    "क्ष": "ᰀᰡ᰷",
    "ज्ञ": "ᰈᰉ"
  },
  "symbols": {
    "०": "᱀",
    "१": "᱁",
    "२": "᱂",
    "३": "᱃",
    "४": "᱄",
    "५": "᱅",
    "६": "᱆",
    "७": "᱇",
    "८": "᱈",
    "९": "᱉",
    "ॐ": "ᰣᰨᰵ",
    "ऽ": "'",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "ᰬ"
  }
};
schemes.limbu = {
  "vowels": {
    "अ": "ᤀ",
    "आ": "ᤀᤠ",
    "इ": "ᤀᤡ",
    "ई": "ᤀᤡ᤺",
    "उ": "ᤀᤢ",
    "ऊ": "ᤀ᤺ᤢ",
    "ऋ": "ᤖᤢ",
    "ॠ": "ᤖ᤺ᤢ",
    "ऌ": "ᤗᤢ",
    "ॡ": "ᤗ᤺ᤢ",
    "ऎ": "",
    "ए": "ᤀᤣ",
    "ऐ": "ᤀᤤ",
    "ऒ": "",
    "ओ": "ᤀᤥ",
    "औ": "ᤀᤦ"
  },
  "vowel_marks": {
    "ा": "ᤠ",
    "ि": "ᤡ",
    "ी": "ᤡ᤺",
    "ु": "ᤢ",
    "ू": "᤺ᤢ",
    "ृ": "ᤪᤢ",
    "ॄ": "ᤪ᤺ᤢ",
    "ॢ": "᤻ᤗᤢ",
    "ॣ": "᤻ᤗ᤺ᤢ",
    "ॆ": "",
    "े": "ᤣ",
    "ै": "ᤤ",
    "ॊ": "",
    "ो": "ᤥ",
    "ौ": "ᤦ"
  },
  "yogavaahas": {
    "ं": "ᤱ",
    "ः": "ᤜ᤻",
    "ँ": "ᤱ"
  },
  "virama": {
    "्": "᤻"
  },
  "consonants": {
    "क": "ᤁ",
    "ख": "ᤂ",
    "ग": "ᤃ",
    "घ": "ᤄ",
    "ङ": "ᤅ",
    "च": "ᤆ",
    "छ": "ᤇ",
    "ज": "ᤈ",
    "झ": "ᤈ",
    "ञ": "ᤏ",
    "ट": "ᤋ",
    "ठ": "ᤌ",
    "ड": "ᤍ",
    "ढ": "ᤎ",
    "ण": "ᤏ",
    "त": "ᤋ",
    "थ": "ᤌ",
    "द": "ᤍ",
    "ध": "ᤎ",
    "न": "ᤏ",
    "प": "ᤐ",
    "फ": "ᤑ",
    "ब": "ᤒ",
    "भ": "ᤓ",
    "म": "ᤔ",
    "य": "ᤕ",
    "र": "ᤖ",
    "ल": "ᤗ",
    "व": "ᤘ",
    "श": "ᤙ",
    "ष": "ᤙ",
    "स": "ᤛ",
    "ह": "ᤜ",
    "ळ": "ᤗ",
    "क्ष": "ᤁ᤻ᤙ",
    "ज्ञ": "ᤈ᤻ᤏ"
  },
  "symbols": {
    "०": "᥆",
    "१": "᥇",
    "२": "᥈",
    "३": "᥉",
    "४": "᥊",
    "५": "᥋",
    "६": "᥌",
    "७": "᥍",
    "८": "᥎",
    "९": "᥏",
    "ॐ": "ᤀᤥᤱ",
    "ऽ": "'",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "ᤧ"
  }
};
schemes.malayalam = {
  "vowels": {
    "अ": "അ",
    "आ": "ആ",
    "इ": "ഇ",
    "ई": "ഈ",
    "उ": "ഉ",
    "ऊ": "ഊ",
    "ऋ": "ഋ",
    "ॠ": "ൠ",
    "ऌ": "ഌ",
    "ॡ": "ൡ",
    "ऎ": "എ",
    "ए": "ഏ",
    "ऐ": "ഐ",
    "ऒ": "ഒ",
    "ओ": "ഓ",
    "औ": "ഔ"
  },
  "vowel_marks": {
    "ा": "ാ",
    "ि": "ി",
    "ी": "ീ",
    "ु": "ു",
    "ू": "ൂ",
    "ृ": "ൃ",
    "ॄ": "ൄ",
    "ॢ": "ൢ",
    "ॣ": "ൣ",
    "ॆ": "െ",
    "े": "േ",
    "ै": "ൈ",
    "ॊ": "ൊ",
    "ो": "ോ",
    "ौ": "ൌ"
  },
  "yogavaahas": {
    "ं": "ം",
    "ः": "ഃ",
    "ँ": "ഁ"
  },
  "virama": {
    "्": "്"
  },
  "consonants": {
    "क": "ക",
    "ख": "ഖ",
    "ग": "ഗ",
    "घ": "ഘ",
    "ङ": "ങ",
    "च": "ച",
    "छ": "ഛ",
    "ज": "ജ",
    "झ": "ഝ",
    "ञ": "ഞ",
    "ट": "ട",
    "ठ": "ഠ",
    "ड": "ഡ",
    "ढ": "ഢ",
    "ण": "ണ",
    "त": "ത",
    "थ": "ഥ",
    "द": "ദ",
    "ध": "ധ",
    "न": "ന",
    "प": "പ",
    "फ": "ഫ",
    "ब": "ബ",
    "भ": "ഭ",
    "म": "മ",
    "य": "യ",
    "र": "ര",
    "ल": "ല",
    "व": "വ",
    "श": "ശ",
    "ष": "ഷ",
    "स": "സ",
    "ह": "ഹ",
    "ळ": "ള",
    "क्ष": "ക്ഷ",
    "ज्ञ": "ജ്ഞ"
  },
  "symbols": {
    "०": "൦",
    "१": "൧",
    "२": "൨",
    "३": "൩",
    "४": "൪",
    "५": "൫",
    "६": "൬",
    "७": "൭",
    "८": "൮",
    "९": "൯",
    "ॐ": "ഓം",
    "ऽ": "ഽ",
    "।": "।",
    "॥": "॥"
  },
  "extra_consonants": {
    "क़": "",
    "ख़": "",
    "ग़": "",
    "ज़": "",
    "ड़": "",
    "ढ़": "",
    "फ़": "",
    "य़": "",
    "ऱ": "റ",
    "ऴ": "ഴ"
  }
};
schemes.manipuri = {
  "vowels": {
    "अ": "ꯑ",
    "आ": "ꯑꯥ",
    "इ": "ꯏ",
    "ई": "ꯏ",
    "उ": "ꯎ",
    "ऊ": "ꯎ",
    "ऋ": "ꯔꯨ",
    "ॠ": "ꯔꯨ",
    "ऌ": "ꯂꯨ",
    "ॡ": "ꯂꯨ",
    "ऎ": "",
    "ए": "ꯑꯦ",
    "ऐ": "ꯑꯩ",
    "ऒ": "",
    "ओ": "ꯑꯣ",
    "औ": "ꯑꯧ"
  },
  "vowel_marks": {
    "ा": "ꯥ",
    "ि": "ꯤ",
    "ी": "ꯤ",
    "ु": "ꯨ",
    "ू": "ꯨ",
    "ृ": "꯭ꯔꯨ",
    "ॄ": "꯭ꯔꯨ",
    "ॢ": "꯭ꯂꯨ",
    "ॣ": "꯭ꯂꯨ",
    "ॆ": "",
    "े": "ꯦ",
    "ै": "ꯩ",
    "ॊ": "",
    "ो": "ꯣ",
    "ौ": "ꯧ"
  },
  "yogavaahas": {
    "ं": "ꯪ",
    "ः": "ꯍ꯭",
    "ँ": "ꯪ"
  },
  "virama": {
    "्": "꯭"
  },
  "consonants": {
    "क": "ꯀ",
    "ख": "ꯈ",
    "ग": "ꯒ",
    "घ": "ꯘ",
    "ङ": "ꯉ",
    "च": "ꯆ",
    "छ": "ꯆ",
    "ज": "ꯖ",
    "झ": "ꯓ",
    "ञ": "ꯅ",
    "ट": "ꯇ",
    "ठ": "ꯊ",
    "ड": "ꯗ",
    "ढ": "ꯙ",
    "ण": "ꯅ",
    "त": "ꯇ",
    "थ": "ꯊ",
    "द": "ꯗ",
    "ध": "ꯙ",
    "न": "ꯅ",
    "प": "ꯄ",
    "फ": "ꯐ",
    "ब": "ꯕ",
    "भ": "ꯚ",
    "म": "ꯃ",
    "य": "ꯌ",
    "र": "ꯔ",
    "ल": "ꯂ",
    "व": "ꯋ",
    "श": "ꯁ",
    "ष": "ꯁ",
    "स": "ꯁ",
    "ह": "ꯍ",
    "ळ": "ꯂ",
    "क्ष": "ꯛꯁ",
    "ज्ञ": "ꯖ꯭ꯅ"
  },
  "symbols": {
    "०": "꯰",
    "१": "꯱",
    "२": "꯲",
    "३": "꯳",
    "४": "꯴",
    "५": "꯵",
    "६": "꯶",
    "७": "꯷",
    "८": "꯸",
    "९": "꯹",
    "ॐ": "ꯑꯣꯪ",
    "ऽ": "'",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "ꯦ"
  }
};
schemes.marchen = {
  "vowels": {
    "अ": "𑲏",
    "आ": "𑲏𑲰",
    "इ": "𑲏𑲱",
    "ई": "𑲏𑲱𑲰",
    "उ": "𑲏𑲲",
    "ऊ": "𑲏𑲲𑲰",
    "ऋ": "𑲊𑲱",
    "ॠ": "𑲊𑲱𑲰",
    "ऌ": "𑲋𑲱",
    "ॡ": "𑲋𑲱𑲰",
    "ऎ": "",
    "ए": "𑲏𑲳",
    "ऐ": "𑲏𑲳𑲰",
    "ऒ": "",
    "ओ": "𑲏𑲴",
    "औ": "𑲏𑲰𑲴"
  },
  "vowel_marks": {
    "ा": "𑲰",
    "ि": "𑲱",
    "ी": "𑲱𑲰",
    "ु": "𑲲",
    "ू": "𑲲𑲰",
    "ृ": "𑲪𑲱",
    "ॄ": "𑲪𑲱𑲰",
    "ॢ": "𑲫𑲱",
    "ॣ": "𑲫𑲱𑲰",
    "ॆ": "",
    "े": "𑲳",
    "ै": "𑲳𑲰",
    "ॊ": "",
    "ो": "𑲴",
    "ौ": "𑲰𑲴"
  },
  "yogavaahas": {
    "ं": "𑲵",
    "ः": "𑲎",
    "ँ": "𑲶"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "𑱲",
    "ख": "𑱳",
    "ग": "𑱴",
    "घ": "𑱴𑲮",
    "ङ": "𑱵",
    "च": "𑲂",
    "छ": "𑲃",
    "ज": "𑲄",
    "झ": "𑲄𑲮",
    "ञ": "𑱹",
    "ट": "𑱺",
    "ठ": "𑱻",
    "ड": "𑱼",
    "ढ": "𑱼𑲮",
    "ण": "𑱽",
    "त": "𑱺",
    "थ": "𑱻",
    "द": "𑱼",
    "ध": "𑱼𑲮",
    "न": "𑱽",
    "प": "𑱾",
    "फ": "𑱿",
    "ब": "𑲀",
    "भ": "𑲀𑲮",
    "म": "𑲁",
    "य": "𑲉",
    "र": "𑲊",
    "ल": "𑲋",
    "व": "𑲅",
    "श": "𑲌",
    "ष": "𑲌",
    "स": "𑲍",
    "ह": "𑲎",
    "ळ": "𑲋",
    "क्ष": "𑱲𑲬",
    "ज्ञ": "𑲄𑲙"
  },
  "symbols": {
    "०": "༠",
    "१": "༡",
    "२": "༢",
    "३": "༣",
    "४": "༤",
    "५": "༥",
    "६": "༦",
    "७": "༧",
    "८": "༨",
    "९": "༩",
    "ॐ": "𑲏𑲴𑲶",
    "ऽ": "",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "𑲳"
  }
};
schemes.modi = {
  "vowels": {
    "अ": "𑘀",
    "आ": "𑘁",
    "इ": "𑘃",
    "ई": "𑘃",
    "उ": "𑘄",
    "ऊ": "𑘄",
    "ऋ": "𑘨𑘲",
    "ॠ": "𑘨𑘲",
    "ऌ": "𑘩𑘲",
    "ॡ": "𑘩𑘲",
    "ऎ": "",
    "ए": "𑘊",
    "ऐ": "𑘋",
    "ऒ": "",
    "ओ": "𑘌",
    "औ": "𑘍"
  },
  "vowel_marks": {
    "ा": "𑘰",
    "ि": "𑘲",
    "ी": "𑘲",
    "ु": "𑘳",
    "ू": "𑘳",
    "ृ": "𑘿𑘨𑘲",
    "ॄ": "𑘿𑘨𑘲",
    "ॢ": "𑘿𑘩𑘲",
    "ॣ": "𑘿𑘩𑘲",
    "ॆ": "",
    "े": "𑘹",
    "ै": "𑘺",
    "ॊ": "",
    "ो": "𑘻",
    "ौ": "𑘼"
  },
  "yogavaahas": {
    "ं": "𑘽",
    "ः": "𑘾",
    "ँ": "𑘽"
  },
  "virama": {
    "्": "𑘿"
  },
  "consonants": {
    "क": "𑘎",
    "ख": "𑘏",
    "ग": "𑘐",
    "घ": "𑘑",
    "ङ": "𑘒",
    "च": "𑘓",
    "छ": "𑘔",
    "ज": "𑘕",
    "झ": "𑘖",
    "ञ": "𑘗",
    "ट": "𑘘",
    "ठ": "𑘙",
    "ड": "𑘚",
    "ढ": "𑘛",
    "ण": "𑘜",
    "त": "𑘝",
    "थ": "𑘞",
    "द": "𑘟",
    "ध": "𑘠",
    "न": "𑘡",
    "प": "𑘢",
    "फ": "𑘣",
    "ब": "𑘤",
    "भ": "𑘥",
    "म": "𑘦",
    "य": "𑘧",
    "र": "𑘨",
    "ल": "𑘩",
    "व": "𑘪",
    "श": "𑘫",
    "ष": "𑘬",
    "स": "𑘭",
    "ह": "𑘮",
    "ळ": "𑘯",
    "क्ष": "𑘎𑘿𑘬",
    "ज्ञ": "𑘕𑘿𑘗"
  },
  "symbols": {
    "०": "𑙐",
    "१": "𑙑",
    "२": "𑙒",
    "३": "𑙓",
    "४": "𑙔",
    "५": "𑙕",
    "६": "𑙖",
    "७": "𑙗",
    "८": "𑙘",
    "९": "𑙙",
    "ॐ": "𑘌𑘽",
    "ऽ": "ऽ",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "𑙀"
  }
};
schemes.mon = {
  "vowels": {
    "अ": "အ",
    "आ": "အာ",
    "इ": "ဣ",
    "ई": "ဣဳ",
    "उ": "ဥ",
    "ऊ": "ဥူ",
    "ऋ": "ၒ",
    "ॠ": "ၓ",
    "ऌ": "ၔ",
    "ॡ": "ၕ",
    "ऎ": "",
    "ए": "ဨ",
    "ऐ": "အဲ",
    "ऒ": "",
    "ओ": "ဩ",
    "औ": "ဪ"
  },
  "vowel_marks": {
    "ा": "ာ",
    "ि": "ိ",
    "ी": "ဳ",
    "ु": "ု",
    "ू": "ူ",
    "ृ": "ၖ",
    "ॄ": "ၗ",
    "ॢ": "ၘ",
    "ॣ": "ၙ",
    "ॆ": "",
    "े": "ေ",
    "ै": "ဲ",
    "ॊ": "",
    "ो": "ော",
    "ौ": "ော်"
  },
  "yogavaahas": {
    "ं": "ံ",
    "ः": "း",
    "ँ": "ံ"
  },
  "virama": {
    "्": "်"
  },
  "consonants": {
    "क": "က",
    "ख": "ခ",
    "ग": "ဂ",
    "घ": "ဃ",
    "ङ": "ၚ",
    "च": "စ",
    "छ": "ဆ",
    "ज": "ဇ",
    "झ": "ၛ",
    "ञ": "ည",
    "ट": "ဋ",
    "ठ": "ဌ",
    "ड": "ဍ",
    "ढ": "ဎ",
    "ण": "ဏ",
    "त": "တ",
    "थ": "ထ",
    "द": "ဒ",
    "ध": "ဓ",
    "न": "န",
    "प": "ပ",
    "फ": "ဖ",
    "ब": "ဗ",
    "भ": "ဘ",
    "म": "မ",
    "य": "ယ",
    "र": "ရ",
    "ल": "လ",
    "व": "ဝ",
    "श": "ၐ",
    "ष": "ၑ",
    "स": "သ",
    "ह": "ဟ",
    "ळ": "ဠ",
    "क्ष": "က္ၑ",
    "ज्ञ": "ဇ္ည"
  },
  "symbols": {
    "०": "၀",
    "१": "၁",
    "२": "၂",
    "३": "၃",
    "४": "၄",
    "५": "၅",
    "६": "၆",
    "७": "၇",
    "८": "၈",
    "९": "၉",
    "ॐ": "ဩံ",
    "ऽ": "'",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "ေ"
  }
};
schemes.mro = {
  "vowels": {
    "अ": "𖩒",
    "आ": "𖩆",
    "इ": "𖩊",
    "ई": "𖩊",
    "उ": "𖩑",
    "ऊ": "𖩑",
    "ऋ": "𖩓𖩑",
    "ॠ": "𖩓𖩑",
    "ऌ": "𖩍𖩑",
    "ॡ": "𖩍𖩑",
    "ऎ": "",
    "ए": "𖩘",
    "ऐ": "𖩆𖩂",
    "ऒ": "",
    "ओ": "𖩑",
    "औ": "𖩖"
  },
  "vowel_marks": {
    "ा": "𖩆",
    "ि": "𖩊",
    "ी": "𖩊",
    "ु": "𖩑",
    "ू": "𖩑",
    "ृ": "𖩓𖩑",
    "ॄ": "𖩓𖩑",
    "ॢ": "𖩍𖩑",
    "ॣ": "𖩍𖩑",
    "ॆ": "",
    "े": "𖩘",
    "ै": "𖩆𖩂",
    "ॊ": "",
    "ो": "𖩑",
    "ौ": "𖩖"
  },
  "yogavaahas": {
    "ं": "𖩃",
    "ः": "𖩉",
    "ँ": "𖩃"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "𖩌𖩒",
    "ख": "𖩈𖩒",
    "ग": "𖩌𖩒",
    "घ": "𖩈𖩒",
    "ङ": "𖩁𖩒",
    "च": "𖩋𖩒",
    "छ": "𖩋𖩒",
    "ज": "𖩋𖩒",
    "झ": "𖩋𖩒",
    "ञ": "𖩏𖩒",
    "ट": "𖩀𖩒",
    "ठ": "𖩕𖩒",
    "ड": "𖩅𖩒",
    "ढ": "𖩅𖩒",
    "ण": "𖩏𖩒",
    "त": "𖩀𖩒",
    "थ": "𖩕𖩒",
    "द": "𖩅𖩒",
    "ध": "𖩅𖩒",
    "न": "𖩏𖩒",
    "प": "𖩐𖩒",
    "फ": "𖩇𖩒",
    "ब": "𖩄𖩒",
    "भ": "𖩄𖩒",
    "म": "𖩃𖩒",
    "य": "𖩂𖩒",
    "र": "𖩓𖩒",
    "ल": "𖩍𖩒",
    "व": "𖩗𖩒",
    "श": "𖩔𖩒",
    "ष": "𖩔𖩒",
    "स": "𖩔𖩒",
    "ह": "𖩉𖩒",
    "ळ": "𖩍𖩒",
    "क्ष": "𖩌𖩔𖩒",
    "ज्ञ": "𖩋𖩏𖩒"
  },
  "symbols": {
    "०": "𖩠",
    "१": "𖩡",
    "२": "𖩢",
    "३": "𖩣",
    "४": "𖩤",
    "५": "𖩥",
    "६": "𖩦",
    "७": "𖩧",
    "८": "𖩨",
    "९": "𖩩",
    "ॐ": "𖩑𖩃",
    "ऽ": "",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "𖩘"
  }
};
schemes.newa = {
  "vowels": {
    "अ": "𑐀",
    "आ": "𑐁",
    "इ": "𑐂",
    "ई": "𑐃",
    "उ": "𑐄",
    "ऊ": "𑐅",
    "ऋ": "𑐆",
    "ॠ": "𑐇",
    "ऌ": "𑐈",
    "ॡ": "𑐉",
    "ऎ": "",
    "ए": "𑐊",
    "ऐ": "𑐋",
    "ऒ": "",
    "ओ": "𑐌",
    "औ": "𑐍"
  },
  "vowel_marks": {
    "ा": "𑐵",
    "ि": "𑐶",
    "ी": "𑐷",
    "ु": "𑐸",
    "ू": "𑐹",
    "ृ": "𑐺",
    "ॄ": "𑐻",
    "ॢ": "𑐼",
    "ॣ": "𑐽",
    "ॆ": "",
    "े": "𑐾",
    "ै": "𑐿",
    "ॊ": "",
    "ो": "𑑀",
    "ौ": "𑑁"
  },
  "yogavaahas": {
    "ं": "𑑄",
    "ः": "𑑅",
    "ँ": "𑑃"
  },
  "virama": {
    "्": "𑑂"
  },
  "consonants": {
    "क": "𑐎",
    "ख": "𑐏",
    "ग": "𑐐",
    "घ": "𑐑",
    "ङ": "𑐒",
    "च": "𑐔",
    "छ": "𑐕",
    "ज": "𑐖",
    "झ": "𑐗",
    "ञ": "𑐘",
    "ट": "𑐚",
    "ठ": "𑐛",
    "ड": "𑐜",
    "ढ": "𑐝",
    "ण": "𑐞",
    "त": "𑐟",
    "थ": "𑐠",
    "द": "𑐡",
    "ध": "𑐢",
    "न": "𑐣",
    "प": "𑐥",
    "फ": "𑐦",
    "ब": "𑐧",
    "भ": "𑐨",
    "म": "𑐩",
    "य": "𑐫",
    "र": "𑐬",
    "ल": "𑐮",
    "व": "𑐰",
    "श": "𑐱",
    "ष": "𑐲",
    "स": "𑐳",
    "ह": "𑐴",
    "ळ": "𑐮𑑆",
    "क्ष": "𑐎𑑂𑐲",
    "ज्ञ": "𑐖𑑂𑐘"
  },
  "symbols": {
    "०": "𑑐",
    "१": "𑑑",
    "२": "𑑒",
    "३": "𑑓",
    "४": "𑑔",
    "५": "𑑕",
    "६": "𑑖",
    "७": "𑑗",
    "८": "𑑘",
    "९": "𑑙",
    "ॐ": "𑑉",
    "ऽ": "𑑇",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "𑐾"
  }
};
schemes.ol_chiki = {
  "vowels": {
    "अ": "ᱚ",
    "आ": "ᱟ",
    "इ": "ᱤ",
    "ई": "ᱤᱻ",
    "उ": "ᱩ",
    "ऊ": "ᱩᱻ",
    "ऋ": "ᱨᱩ",
    "ॠ": "ᱨᱩᱻ",
    "ऌ": "ᱞᱩ",
    "ॡ": "ᱞᱩᱻ",
    "ऎ": "ᱮᱹ",
    "ए": "ᱮ",
    "ऐ": "ᱚᱤ",
    "ऒ": "ᱳ",
    "ओ": "ᱳ",
    "औ": "ᱚᱩ"
  },
  "vowel_marks": {
    "ा": "ᱟ",
    "ि": "ᱤ",
    "ी": "ᱤᱻ",
    "ु": "ᱩ",
    "ू": "ᱩᱻ",
    "ृ": "ᱨᱩ",
    "ॄ": "ᱨᱩᱻ",
    "ॢ": "ᱞᱩ",
    "ॣ": "ᱞᱩᱻ",
    "ॆ": "ᱮᱹ",
    "े": "ᱮ",
    "ै": "ᱚᱤ",
    "ॊ": "ᱳ",
    "ो": "ᱳ",
    "ौ": "ᱚᱩ"
  },
  "yogavaahas": {
    "ं": "ᱝ",
    "ः": "ᱷ",
    "ँ": "ᱸ"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "ᱠᱚ",
    "ख": "ᱠᱷᱚ",
    "ग": "ᱜᱚ",
    "घ": "ᱜᱷᱚ",
    "ङ": "ᱶᱚ",
    "च": "ᱪᱚ",
    "छ": "ᱪᱷᱚ",
    "ज": "ᱡᱚ",
    "झ": "ᱡᱷᱚ",
    "ञ": "ᱧᱚ",
    "ट": "ᱴᱚ",
    "ठ": "ᱴᱷᱚ",
    "ड": "ᱰᱚ",
    "ढ": "ᱰᱷᱚ",
    "ण": "ᱬᱚ",
    "त": "ᱛᱚ",
    "थ": "ᱛᱷᱚ",
    "द": "ᱫᱚ",
    "ध": "ᱫᱷᱚ",
    "न": "ᱱᱚ",
    "प": "ᱯᱚ",
    "फ": "ᱯᱷᱚ",
    "ब": "ᱵᱚ",
    "भ": "ᱵᱷᱚ",
    "म": "ᱢᱚ",
    "य": "ᱭᱚ",
    "र": "ᱨᱚ",
    "ल": "ᱞᱚ",
    "व": "ᱣᱚ",
    "श": "ᱥᱚ",
    "ष": "ᱥᱚ",
    "स": "ᱥᱚ",
    "ह": "ᱦᱚ",
    "ळ": "ᱞᱚ",
    "क्ष": "ᱠᱥᱚ",
    "ज्ञ": "ᱡᱧᱚ"
  },
  "symbols": {
    "०": "᱐",
    "१": "᱑",
    "२": "᱒",
    "३": "᱓",
    "४": "᱔",
    "५": "᱕",
    "६": "᱖",
    "७": "᱗",
    "८": "᱘",
    "९": "᱙",
    "ॐ": "ᱳᱸ",
    "ऽ": "'",
    "।": "᱾",
    "॥": "᱿"
  },
  "candra": {
    "ॅ": "ᱚᱹ"
  },
  "extra_consonants": {
    "क़": "ᱠᱚ",
    "ख़": "ᱠᱷᱚ",
    "ग़": "ᱜᱚ",
    "ज़": "ᱡᱚ",
    "ड़": "ᱲᱚ",
    "ढ़": "ᱲᱷᱚ",
    "फ़": "ᱯᱚ",
    "य़": "ᱭᱚ",
    "ऱ": "ᱨᱚ"
  }
};
schemes.oriya = {
  "vowels": {
    "अ": "ଅ",
    "आ": "ଆ",
    "इ": "ଇ",
    "ई": "ଈ",
    "उ": "ଉ",
    "ऊ": "ଊ",
    "ऋ": "ଋ",
    "ॠ": "ୠ",
    "ऌ": "ଌ",
    "ॡ": "ୡ",
    "ऎ": "ऎ",
    "ए": "ଏ",
    "ऐ": "ଐ",
    "ऒ": "ऒ",
    "ओ": "ଓ",
    "औ": "ଔ"
  },
  "vowel_marks": {
    "ा": "ା",
    "ि": "ି",
    "ी": "ୀ",
    "ु": "ୁ",
    "ू": "ୂ",
    "ृ": "ୃ",
    "ॄ": "ୄ",
    "ॢ": "ୢ",
    "ॣ": "ୣ",
    "ॆ": "ॆ",
    "े": "େ",
    "ै": "ୈ",
    "ॊ": "ॊ",
    "ो": "ୋ",
    "ौ": "ୌ"
  },
  "yogavaahas": {
    "ं": "ଂ",
    "ः": "ଃ",
    "ँ": "ଁ"
  },
  "virama": {
    "्": "୍"
  },
  "consonants": {
    "क": "କ",
    "ख": "ଖ",
    "ग": "ଗ",
    "घ": "ଘ",
    "ङ": "ଙ",
    "च": "ଚ",
    "छ": "ଛ",
    "ज": "ଜ",
    "झ": "ଝ",
    "ञ": "ଞ",
    "ट": "ଟ",
    "ठ": "ଠ",
    "ड": "ଡ",
    "ढ": "ଢ",
    "ण": "ଣ",
    "त": "ତ",
    "थ": "ଥ",
    "द": "ଦ",
    "ध": "ଧ",
    "न": "ନ",
    "प": "ପ",
    "फ": "ଫ",
    "ब": "ବ",
    "भ": "ଭ",
    "म": "ମ",
    "य": "ଯ",
    "र": "ର",
    "ल": "ଲ",
    "व": "ଵ",
    "श": "ଶ",
    "ष": "ଷ",
    "स": "ସ",
    "ह": "ହ",
    "ळ": "ଳ",
    "क्ष": "କ୍ଷ",
    "ज्ञ": "ଜ୍ଞ"
  },
  "symbols": {
    "०": "୦",
    "१": "୧",
    "२": "୨",
    "३": "୩",
    "४": "୪",
    "५": "୫",
    "६": "୬",
    "७": "୭",
    "८": "୮",
    "९": "୯",
    "ॐ": "ଓଁ",
    "ऽ": "ଽ",
    "।": "।",
    "॥": "॥"
  },
  "zwj": {
    "‍": ""
  },
  "skip": {
    "undefined": ""
  },
  "accents": {
    "॑": "॑",
    "॒": "॒"
  },
  "candra": {
    "ॅ": "େ"
  },
  "extra_consonants": {
    "क़": "କ଼",
    "ख़": "ଖ଼",
    "ग़": "ଗ଼",
    "ज़": "ଜ଼",
    "ड़": "ଡ଼",
    "ढ़": "ଢ଼",
    "फ़": "ଫ଼",
    "य़": "ୟ",
    "ऱ": "ର଼",
    "ऴ": "ଳ଼"
  }
};
schemes.phags_pa = {
  "vowels": {
    "अ": "ꡝ",
    "आ": "ꡝꡖ",
    "इ": "ꡞ",
    "ई": "ꡞꡖ",
    "उ": "ꡟ",
    "ऊ": "ꡟꡖ",
    "ऋ": "ꡘꡞ",
    "ॠ": "ꡘꡖꡞ",
    "ऌ": "ꡙꡞ",
    "ॡ": "ꡙꡖꡞ",
    "ऎ": "",
    "ए": "ꡠ",
    "ऐ": "ꡠꡖ",
    "ऒ": "",
    "ओ": "ꡡ",
    "औ": "ꡡꡖ"
  },
  "vowel_marks": {
    "ा": "ꡖ",
    "ि": "ꡞ",
    "ी": "ꡖꡞ",
    "ु": "ꡟ",
    "ू": "ꡖꡟ",
    "ृ": "ꡱꡞ",
    "ॄ": "ꡖꡱꡞ",
    "ॢ": "ꡙꡞ",
    "ॣ": "ꡙꡖꡞ",
    "ॆ": "",
    "े": "ꡠ",
    "ै": "ꡖꡠ",
    "ॊ": "",
    "ो": "ꡡ",
    "ौ": "ꡖꡡ"
  },
  "yogavaahas": {
    "ं": "ꡳ",
    "ः": "ꡜ",
    "ँ": "ꡳ"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "ꡀ",
    "ख": "ꡁ",
    "ग": "ꡂ",
    "घ": "ꡂꡜ",
    "ङ": "ꡃ",
    "च": "ꡐ",
    "छ": "ꡑ",
    "ज": "ꡒ",
    "झ": "ꡒꡜ",
    "ञ": "ꡇ",
    "ट": "ꡩ",
    "ठ": "ꡪ",
    "ड": "ꡫ",
    "ढ": "ꡫꡜ",
    "ण": "ꡬ",
    "त": "ꡈ",
    "थ": "ꡉ",
    "द": "ꡊ",
    "ध": "ꡊꡜ",
    "न": "ꡋ",
    "प": "ꡌ",
    "फ": "ꡍ",
    "ब": "ꡎ",
    "भ": "ꡎꡜ",
    "म": "ꡏ",
    "य": "ꡗ",
    "र": "ꡘ",
    "ल": "ꡙ",
    "व": "ꡓ",
    "श": "ꡚ",
    "ष": "ꡚ",
    "स": "ꡛ",
    "ह": "ꡜ",
    "ळ": "ꡙ",
    "क्ष": "ꡀꡚ",
    "ज्ञ": "ꡒꡇ"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "ꡳꡡ",
    "ऽ": "",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "ꡠ"
  }
};
schemes.ranjana = {
  "vowels": {
    "अ": "अ",
    "आ": "आ",
    "इ": "इ",
    "ई": "ई",
    "उ": "उ",
    "ऊ": "ऊ",
    "ऋ": "ऋ",
    "ॠ": "ॠ",
    "ऌ": "ऌ",
    "ॡ": "ॡ",
    "ऎ": "",
    "ए": "ए",
    "ऐ": "ऐ",
    "ऒ": "",
    "ओ": "ओ",
    "औ": "औ"
  },
  "vowel_marks": {
    "ा": "ा",
    "ि": "ि",
    "ी": "ी",
    "ु": "ु",
    "ू": "ू",
    "ृ": "ृ",
    "ॄ": "ॄ",
    "ॢ": "ॢ",
    "ॣ": "ॣ",
    "ॆ": "",
    "े": "े",
    "ै": "ै",
    "ॊ": "",
    "ो": "ो",
    "ौ": "ौ"
  },
  "yogavaahas": {
    "ं": "ं",
    "ः": "ः",
    "ँ": "ँ"
  },
  "virama": {
    "्": "्"
  },
  "consonants": {
    "क": "क",
    "ख": "ख",
    "ग": "ग",
    "घ": "घ",
    "ङ": "ङ",
    "च": "च",
    "छ": "छ",
    "ज": "ज",
    "झ": "झ",
    "ञ": "ञ",
    "ट": "ट",
    "ठ": "ठ",
    "ड": "ड",
    "ढ": "ढ",
    "ण": "ण",
    "त": "त",
    "थ": "थ",
    "द": "द",
    "ध": "ध",
    "न": "न",
    "प": "प",
    "फ": "फ",
    "ब": "ब",
    "भ": "भ",
    "म": "म",
    "य": "य",
    "र": "र",
    "ल": "ल",
    "व": "व",
    "श": "श",
    "ष": "ष",
    "स": "स",
    "ह": "ह",
    "ळ": "ल",
    "क्ष": "क्ष",
    "ज्ञ": "ज्ञ"
  },
  "symbols": {
    "०": "०",
    "१": "१",
    "२": "२",
    "३": "३",
    "४": "४",
    "५": "५",
    "६": "६",
    "७": "७",
    "८": "८",
    "९": "९",
    "ॐ": "ॐ",
    "ऽ": "ऽ",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "े"
  }
};
schemes.rohingya = {
  "vowels": {
    "अ": "𐴀𐴝",
    "आ": "𐴀𐴝𐴤",
    "इ": "𐴀𐴞",
    "ई": "𐴀𐴞𐴤",
    "उ": "𐴀𐴟",
    "ऊ": "𐴀𐴟𐴤",
    "ऋ": "𐴌𐴟",
    "ॠ": "𐴌𐴟𐴤",
    "ऌ": "𐴓𐴟",
    "ॡ": "𐴓𐴟𐴤",
    "ऎ": "",
    "ए": "𐴀𐴠𐴤",
    "ऐ": "𐴀𐴝𐴙",
    "ऒ": "",
    "ओ": "𐴀𐴡𐴤",
    "औ": "𐴀𐴝𐴗"
  },
  "vowel_marks": {
    "ा": "𐴀𐴝𐴤",
    "ि": "𐴀𐴞",
    "ी": "𐴀𐴞𐴤",
    "ु": "𐴀𐴟",
    "ू": "𐴀𐴟𐴤",
    "ृ": "𐴌𐴟",
    "ॄ": "𐴌𐴟𐴤",
    "ॢ": "𐴓𐴟",
    "ॣ": "𐴓𐴟𐴤",
    "ॆ": "",
    "े": "𐴀𐴠𐴤",
    "ै": "𐴀𐴝𐴙",
    "ॊ": "",
    "ो": "𐴀𐴡𐴤",
    "ौ": "𐴀𐴝𐴗"
  },
  "yogavaahas": {
    "ं": "𐴣",
    "ः": "𐴇",
    "ँ": "𐴣"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "𐴑𐴝",
    "ख": "𐴑𐴝",
    "ग": "𐴒𐴝",
    "घ": "𐴒𐴝",
    "ङ": "𐴚𐴝",
    "च": "𐴆𐴝",
    "छ": "𐴆𐴝",
    "ज": "𐴅𐴝",
    "झ": "𐴅𐴝",
    "ञ": "𐴛𐴝",
    "ट": "𐴄𐴝",
    "ठ": "𐴄𐴝",
    "ड": "𐴋𐴝",
    "ढ": "𐴋𐴝",
    "ण": "𐴕𐴝",
    "त": "𐴃𐴝",
    "थ": "𐴃𐴝",
    "द": "𐴊𐴝",
    "ध": "𐴊𐴝",
    "न": "𐴕𐴝",
    "प": "𐴂𐴝",
    "फ": "𐴂𐴝",
    "ब": "𐴁𐴝",
    "भ": "𐴁𐴝",
    "म": "𐴔𐴝",
    "य": "𐴘𐴝",
    "र": "𐴌𐴝",
    "ल": "𐴓𐴝",
    "व": "𐴖𐴝",
    "श": "𐴐𐴝",
    "ष": "𐴐𐴝",
    "स": "𐴏𐴝",
    "ह": "𐴇𐴝",
    "ळ": "𐴓𐴝",
    "क्ष": "𐴑𐴐𐴝",
    "ज्ञ": "𐴅𐴛𐴝"
  },
  "symbols": {
    "०": "𐴰",
    "१": "𐴱",
    "२": "𐴲",
    "३": "𐴳",
    "४": "𐴴",
    "५": "𐴵",
    "६": "𐴶",
    "७": "𐴷",
    "८": "𐴸",
    "९": "𐴹",
    "ॐ": "𐴀𐴀𐴡𐴴𐴔",
    "ऽ": "",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "𐴀𐴠"
  }
};
schemes.shan = {
  "vowels": {
    "अ": "အ",
    "आ": "အႃ",
    "इ": "အိ",
    "ई": "အီ",
    "उ": "အု",
    "ऊ": "အူ",
    "ऋ": "အၖ",
    "ॠ": "အၗ",
    "ऌ": "အၘ",
    "ॡ": "အၙ",
    "ऎ": "အေ",
    "ए": "အေ",
    "ऐ": "အႆ",
    "ऒ": "အေႃ",
    "ओ": "အေႃ",
    "औ": "အဝ်"
  },
  "vowel_marks": {
    "ा": "ႃ",
    "ि": "ိ",
    "ी": "ီ",
    "ु": "ု",
    "ू": "ူ",
    "ृ": "ၖ",
    "ॄ": "ၗ",
    "ॢ": "ၘ",
    "ॣ": "ၙ",
    "ॆ": "ေ",
    "े": "ေ",
    "ै": "ႆ",
    "ॊ": "ေႃ",
    "ो": "ေႃ",
    "ौ": "ဝ်"
  },
  "yogavaahas": {
    "ं": "ံ",
    "ः": "း",
    "ँ": "ံ"
  },
  "virama": {
    "्": "်"
  },
  "consonants": {
    "क": "ၵ",
    "ख": "ၶ",
    "ग": "ၷ",
    "घ": "ꧠ",
    "ङ": "င",
    "च": "ၸ",
    "छ": "ꧡ",
    "ज": "ꩡ",
    "झ": "ꧢ",
    "ञ": "ၺ",
    "ट": "ꩦ",
    "ठ": "ꩧ",
    "ड": "ꩨ",
    "ढ": "ꩩ",
    "ण": "ꧣ",
    "त": "တ",
    "थ": "ထ",
    "द": "ၻ",
    "ध": "ꩪ",
    "न": "ၼ",
    "प": "ပ",
    "फ": "ၽ",
    "ब": "ၿ",
    "भ": "ꧤ",
    "म": "မ",
    "य": "ယ",
    "र": "ရ",
    "ल": "လ",
    "व": "ဝ",
    "श": "ၐ",
    "ष": "ၑ",
    "स": "သ",
    "ह": "ႁ",
    "ळ": "ꩮ",
    "क्ष": "ၵ်ၑ",
    "ज्ञ": "ꩡ်ၺ"
  },
  "symbols": {
    "०": "႐",
    "१": "႑",
    "२": "႒",
    "३": "႓",
    "४": "႔",
    "५": "႕",
    "६": "႖",
    "७": "႗",
    "८": "႘",
    "९": "႙",
    "ॐ": "အေႃံ",
    "ऽ": "'",
    "।": "၊",
    "॥": "။"
  },
  "candra": {
    "ॅ": "ႄ"
  },
  "extra_consonants": {
    "क़": "ၵ",
    "ख़": "ၶ",
    "ग़": "ၷ",
    "ज़": "ၹ",
    "ड़": "ꩨ",
    "ढ़": "ꩩ",
    "फ़": "ၾ",
    "य़": "ယ",
    "ऱ": "ရ"
  }
};
schemes.sharada = {
  "vowels": {
    "अ": "𑆃",
    "आ": "𑆄",
    "इ": "𑆅",
    "ई": "𑆆",
    "उ": "𑆇",
    "ऊ": "𑆈",
    "ऋ": "𑆉",
    "ॠ": "𑆊",
    "ऌ": "𑆋",
    "ॡ": "𑆌",
    "ऎ": "𑆍𑇌",
    "ए": "𑆍",
    "ऐ": "𑆎",
    "ऒ": "𑆏𑇌",
    "ओ": "𑆏",
    "औ": "𑆐"
  },
  "vowel_marks": {
    "ा": "𑆳",
    "ि": "𑆴",
    "ी": "𑆵",
    "ु": "𑆶",
    "ू": "𑆷",
    "ृ": "𑆸",
    "ॄ": "𑆹",
    "ॢ": "𑆺",
    "ॣ": "𑆻",
    "ॆ": "𑆼𑇌",
    "े": "𑆼",
    "ै": "𑆽",
    "ॊ": "𑆾𑇌",
    "ो": "𑆾",
    "ौ": "𑆿"
  },
  "yogavaahas": {
    "ं": "𑆁",
    "ः": "𑆂",
    "ँ": "𑆀"
  },
  "virama": {
    "्": "𑇀"
  },
  "consonants": {
    "क": "𑆑",
    "ख": "𑆒",
    "ग": "𑆓",
    "घ": "𑆔",
    "ङ": "𑆕",
    "च": "𑆖",
    "छ": "𑆗",
    "ज": "𑆘",
    "झ": "𑆙",
    "ञ": "𑆚",
    "ट": "𑆛",
    "ठ": "𑆜",
    "ड": "𑆝",
    "ढ": "𑆞",
    "ण": "𑆟",
    "त": "𑆠",
    "थ": "𑆡",
    "द": "𑆢",
    "ध": "𑆣",
    "न": "𑆤",
    "प": "𑆥",
    "फ": "𑆦",
    "ब": "𑆧",
    "भ": "𑆨",
    "म": "𑆩",
    "य": "𑆪",
    "र": "𑆫",
    "ल": "𑆬",
    "व": "𑆮",
    "श": "𑆯",
    "ष": "𑆰",
    "स": "𑆱",
    "ह": "𑆲",
    "ळ": "𑆭",
    "क्ष": "𑆑𑇀𑆰",
    "ज्ञ": "𑆘𑇀𑆚"
  },
  "symbols": {
    "०": "𑇐",
    "१": "𑇑",
    "२": "𑇒",
    "३": "𑇓",
    "४": "𑇔",
    "५": "𑇕",
    "६": "𑇖",
    "७": "𑇗",
    "८": "𑇘",
    "९": "𑇙",
    "ॐ": "𑆏𑆀",
    "ऽ": "𑇁",
    "।": "𑇅",
    "॥": "𑇆"
  },
  "zwj": {
    "‍": "‍"
  },
  "skip": {
    "undefined": ""
  },
  "accents": {
    "॑": "॑",
    "॒": "॒"
  },
  "candra": {
    "ॅ": "𑆼"
  },
  "extra_consonants": {
    "क़": "𑆑𑇊",
    "ख़": "𑆒𑇊",
    "ग़": "𑆓𑇊",
    "ज़": "𑆘𑇊",
    "ड़": "𑆝𑇊",
    "ढ़": "𑆞𑇊",
    "फ़": "𑆦𑇊",
    "य़": "𑆪𑇊",
    "ऱ": "𑆫𑇊",
    "ऴ": "𑆭𑇊"
  }
};
schemes.siddham = {
  "vowels": {
    "अ": "𑖀",
    "आ": "𑖁",
    "इ": "𑖂",
    "ई": "𑖃",
    "उ": "𑖄",
    "ऊ": "𑖅",
    "ऋ": "𑖆",
    "ॠ": "𑖇",
    "ऌ": "𑖈",
    "ॡ": "𑖉",
    "ऎ": "𑖊",
    "ए": "𑖊",
    "ऐ": "𑖋",
    "ऒ": "𑖌",
    "ओ": "𑖌",
    "औ": "𑖍"
  },
  "vowel_marks": {
    "ा": "𑖯",
    "ि": "𑖰",
    "ी": "𑖱",
    "ु": "𑖲",
    "ू": "𑖳",
    "ृ": "𑖴",
    "ॄ": "𑖵",
    "ॢ": "𑖿𑖩𑖰",
    "ॣ": "𑖿𑖩𑖱",
    "ॆ": "𑖸",
    "े": "𑖸",
    "ै": "𑖹",
    "ॊ": "𑖺",
    "ो": "𑖺",
    "ौ": "𑖻"
  },
  "yogavaahas": {
    "ं": "𑖽",
    "ः": "𑖾",
    "ँ": "𑖼"
  },
  "virama": {
    "्": "𑖿"
  },
  "consonants": {
    "क": "𑖎",
    "ख": "𑖏",
    "ग": "𑖐",
    "घ": "𑖑",
    "ङ": "𑖒",
    "च": "𑖓",
    "छ": "𑖔",
    "ज": "𑖕",
    "झ": "𑖖",
    "ञ": "𑖗",
    "ट": "𑖘",
    "ठ": "𑖙",
    "ड": "𑖚",
    "ढ": "𑖛",
    "ण": "𑖜",
    "त": "𑖝",
    "थ": "𑖞",
    "द": "𑖟",
    "ध": "𑖠",
    "न": "𑖡",
    "प": "𑖢",
    "फ": "𑖣",
    "ब": "𑖤",
    "भ": "𑖥",
    "म": "𑖦",
    "य": "𑖧",
    "र": "𑖨",
    "ल": "𑖩",
    "व": "𑖪",
    "श": "𑖫",
    "ष": "𑖬",
    "स": "𑖭",
    "ह": "𑖮",
    "ळ": "𑖩𑗀",
    "क्ष": "𑖎𑖿𑖬",
    "ज्ञ": "𑖕𑖿𑖗"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "𑖌𑖼",
    "ऽ": "",
    "।": "𑗂",
    "॥": "𑗃"
  },
  "candra": {
    "ॅ": "𑖸"
  },
  "extra_consonants": {
    "क़": "𑖎𑗀",
    "ख़": "𑖏𑗀",
    "ग़": "𑖐𑗀",
    "ज़": "𑖕𑗀",
    "ड़": "𑖚𑗀",
    "ढ़": "𑖛𑗀",
    "फ़": "𑖣𑗀",
    "य़": "𑖧𑗀",
    "ऱ": "𑖨𑗀"
  }
};
schemes.sinhala = {
  "vowels": {
    "अ": "අ",
    "आ": "ආ",
    "इ": "ඉ",
    "ई": "ඊ",
    "उ": "උ",
    "ऊ": "ඌ",
    "ऋ": "ඍ",
    "ॠ": "ඎ",
    "ऌ": "ඏ",
    "ॡ": "ඐ",
    "ऎ": "එ",
    "ए": "ඒ",
    "ऐ": "ඓ",
    "ऒ": "ඔ",
    "ओ": "ඕ",
    "औ": "ඖ"
  },
  "vowel_marks": {
    "ा": "ා",
    "ि": "ි",
    "ी": "ී",
    "ु": "ු",
    "ू": "ූ",
    "ृ": "ෘ",
    "ॄ": "ෲ",
    "ॢ": "ෟ",
    "ॣ": "ෳ",
    "ॆ": "ෙ",
    "े": "ේ",
    "ै": "ෛ",
    "ॊ": "ො",
    "ो": "ෝ",
    "ौ": "ෞ"
  },
  "yogavaahas": {
    "ं": "ං",
    "ः": "ඃ"
  },
  "virama": {
    "्": "්"
  },
  "consonants": {
    "क": "ක",
    "ख": "ඛ",
    "ग": "ග",
    "घ": "ඝ",
    "ङ": "ඞ",
    "च": "ච",
    "छ": "ඡ",
    "ज": "ජ",
    "झ": "ඣ",
    "ञ": "ඤ",
    "ट": "ට",
    "ठ": "ඨ",
    "ड": "ඩ",
    "ढ": "ඪ",
    "ण": "ණ",
    "त": "ත",
    "थ": "ථ",
    "द": "ද",
    "ध": "ධ",
    "न": "න",
    "प": "ප",
    "फ": "ඵ",
    "ब": "බ",
    "भ": "භ",
    "म": "ම",
    "य": "ය",
    "र": "ර",
    "ल": "ල",
    "व": "ව",
    "श": "ශ",
    "ष": "ෂ",
    "स": "ස",
    "ह": "හ",
    "ळ": "ළ",
    "क्ष": "ක්‍ෂ",
    "ज्ञ": "ඥ"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "ඕං",
    "ऽ": "(අ)",
    "।": ".",
    "॥": ".."
  },
  "candra": {
    "ॅ": "ැ"
  }
};
schemes.sora_sompeng = {
  "vowels": {
    "अ": "𑃦𑃨",
    "आ": "𑃢",
    "इ": "𑃤",
    "ई": "𑃤",
    "उ": "𑃥",
    "ऊ": "𑃥",
    "ऋ": "𑃝𑃤",
    "ॠ": "𑃝𑃤",
    "ऌ": "𑃘𑃤",
    "ॡ": "𑃘𑃤",
    "ऎ": "𑃣",
    "ए": "𑃣",
    "ऐ": "𑃧",
    "ऒ": "𑃦",
    "ओ": "𑃦",
    "औ": "𑃦𑃥"
  },
  "vowel_marks": {
    "ा": "𑃢",
    "ि": "𑃤",
    "ी": "𑃤",
    "ु": "𑃥",
    "ू": "𑃥",
    "ृ": "𑃝𑃤",
    "ॄ": "𑃝𑃤",
    "ॢ": "𑃘𑃤",
    "ॣ": "𑃘𑃤",
    "ॆ": "𑃣",
    "े": "𑃣",
    "ै": "𑃧",
    "ॊ": "𑃦",
    "ो": "𑃦",
    "ौ": "𑃦𑃥"
  },
  "yogavaahas": {
    "ं": "𑃖",
    "ः": "𑃞",
    "ँ": "𑃖"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "𑃟",
    "ख": "𑃟𑃠",
    "ग": "𑃕",
    "घ": "𑃕𑃠",
    "ङ": "𑃗",
    "च": "𑃓",
    "छ": "𑃓𑃠",
    "ज": "𑃠",
    "झ": "𑃠𑃠",
    "ञ": "𑃡",
    "ट": "𑃑𑃨",
    "ठ": "𑃑𑃨𑃠",
    "ड": "𑃔",
    "ढ": "𑃔𑃠",
    "ण": "𑃙𑃨",
    "त": "𑃑",
    "थ": "𑃑𑃠",
    "द": "𑃔𑃨",
    "ध": "𑃔𑃨𑃠",
    "न": "𑃙",
    "प": "𑃛",
    "फ": "𑃛𑃠",
    "ब": "𑃒",
    "भ": "𑃒𑃠",
    "म": "𑃖",
    "य": "𑃜",
    "र": "𑃝",
    "ल": "𑃘",
    "व": "𑃚",
    "श": "𑃐𑃠",
    "ष": "𑃐𑃨",
    "स": "𑃐",
    "ह": "𑃞",
    "ळ": "𑃘𑃨",
    "क्ष": "𑃟𑃐𑃨",
    "ज्ञ": "𑃠𑃡"
  },
  "symbols": {
    "०": "𑃰",
    "१": "𑃱",
    "२": "𑃲",
    "३": "𑃳",
    "४": "𑃴",
    "५": "𑃵",
    "६": "𑃶",
    "७": "𑃷",
    "८": "𑃸",
    "९": "𑃹",
    "ॐ": "𑃦𑃖",
    "ऽ": "'",
    "।": ".",
    "॥": ".."
  },
  "candra": {
    "ॅ": "𑃣"
  },
  "extra_consonants": {
    "क़": "𑃟",
    "ख़": "𑃟𑃠",
    "ग़": "𑃕",
    "ज़": "𑃠",
    "ड़": "𑃝𑃔",
    "ढ़": "𑃝𑃔𑃠",
    "फ़": "𑃛𑃠",
    "य़": "𑃜",
    "ऱ": "𑃘"
  }
};
schemes.tai_laing = {
  "vowels": {
    "अ": "အ",
    "आ": "အႃ",
    "इ": "အိ",
    "ई": "အီ",
    "उ": "အု",
    "ऊ": "အူ",
    "ऋ": "ꩺိ",
    "ॠ": "ꩺီ",
    "ऌ": "လိ",
    "ॡ": "လီ",
    "ऎ": "အေ",
    "ए": "အေ",
    "ऐ": "အႆ",
    "ऒ": "အေႃ",
    "ओ": "အေႃ",
    "औ": "အဝ်"
  },
  "vowel_marks": {
    "ा": "ႃ",
    "ि": "ိ",
    "ी": "ီ",
    "ु": "ု",
    "ू": "ူ",
    "ृ": "ြိ",
    "ॄ": "ြီ",
    "ॢ": "်လိ",
    "ॣ": "်လီ",
    "ॆ": "ေ",
    "े": "ေ",
    "ै": "ႆ",
    "ॊ": "ေႃ",
    "ो": "ေႃ",
    "ौ": "ဝ်"
  },
  "yogavaahas": {
    "ं": "ံ",
    "ः": "း",
    "ँ": "ံ"
  },
  "virama": {
    "्": "်"
  },
  "consonants": {
    "क": "က",
    "ख": "ၵ",
    "ग": "ꧩ",
    "घ": "ꧪ",
    "ङ": "င",
    "च": "ၸ",
    "छ": "ꩬ",
    "ज": "ꧫ",
    "झ": "ꧬ",
    "ञ": "ꧧ",
    "ट": "ꩦ",
    "ठ": "ꩧ",
    "ड": "ꧭ",
    "ढ": "ꧮ",
    "ण": "ꧯ",
    "त": "တ",
    "थ": "ထ",
    "द": "ꧻ",
    "ध": "ꧼ",
    "न": "ꩫ",
    "प": "ပ",
    "फ": "ꧤ",
    "ब": "ꧽ",
    "भ": "ꧾ",
    "म": "မ",
    "य": "ယ",
    "र": "ꩺ",
    "ल": "လ",
    "व": "ဝ",
    "श": "ꧬ",
    "ष": "ꧬ",
    "स": "ꧬ",
    "ह": "ၯ",
    "ळ": "ꧺ",
    "क्ष": "က်ꧬ",
    "ज्ञ": "ꧫ်ꧧ"
  },
  "symbols": {
    "०": "꧰",
    "१": "꧱",
    "२": "꧲",
    "३": "꧳",
    "४": "꧴",
    "५": "꧵",
    "६": "꧶",
    "७": "꧷",
    "८": "꧸",
    "९": "꧹",
    "ॐ": "အေႃံ",
    "ऽ": "'",
    "।": "၊",
    "॥": "။"
  },
  "candra": {
    "ॅ": "ႄ"
  },
  "extra_consonants": {
    "क़": "က",
    "ख़": "ၵ",
    "ग़": "ꧩ",
    "ज़": "ꧫ",
    "ड़": "ꧭ",
    "ढ़": "ꧮ",
    "फ़": "ꧨ",
    "य़": "ယ",
    "ऱ": "ꩺ"
  }
};
schemes.takri = {
  "vowels": {
    "अ": "𑚀",
    "आ": "𑚁",
    "इ": "𑚂",
    "ई": "𑚃",
    "उ": "𑚄",
    "ऊ": "𑚅",
    "ऋ": "𑚤𑚮",
    "ॠ": "𑚤𑚯",
    "ऌ": "𑚥𑚮",
    "ॡ": "𑚥𑚯",
    "ऎ": "𑚆",
    "ए": "𑚆",
    "ऐ": "𑚇",
    "ऒ": "𑚈",
    "ओ": "𑚈",
    "औ": "𑚉"
  },
  "vowel_marks": {
    "ा": "𑚭",
    "ि": "𑚮",
    "ी": "𑚯",
    "ु": "𑚰",
    "ू": "𑚱",
    "ृ": "𑚶𑚤𑚮",
    "ॄ": "𑚶𑚤𑚯",
    "ॢ": "𑚶𑚥𑚮",
    "ॣ": "𑚶𑚥𑚯",
    "ॆ": "𑚳",
    "े": "𑚲",
    "ै": "𑚳",
    "ॊ": "𑚴",
    "ो": "𑚴",
    "ौ": "𑚵"
  },
  "yogavaahas": {
    "ं": "𑚫",
    "ः": "𑚬",
    "ँ": "𑚫"
  },
  "virama": {
    "्": "𑚶"
  },
  "consonants": {
    "क": "𑚊",
    "ख": "𑚋",
    "ग": "𑚌",
    "घ": "𑚍",
    "ङ": "𑚎",
    "च": "𑚏",
    "छ": "𑚐",
    "ज": "𑚑",
    "झ": "𑚒",
    "ञ": "𑚓",
    "ट": "𑚔",
    "ठ": "𑚕",
    "ड": "𑚖",
    "ढ": "𑚗",
    "ण": "𑚘",
    "त": "𑚙",
    "थ": "𑚚",
    "द": "𑚛",
    "ध": "𑚜",
    "न": "𑚝",
    "प": "𑚞",
    "फ": "𑚟",
    "ब": "𑚠",
    "भ": "𑚡",
    "म": "𑚢",
    "य": "𑚣",
    "र": "𑚤",
    "ल": "𑚥",
    "व": "𑚦",
    "श": "𑚧",
    "ष": "𑚋",
    "स": "𑚨",
    "ह": "𑚩",
    "ळ": "𑚥𑚷",
    "क्ष": "𑚊𑚶𑚋",
    "ज्ञ": "𑚑𑚶𑚓"
  },
  "symbols": {
    "०": "𑛀",
    "१": "𑛁",
    "२": "𑛂",
    "३": "𑛃",
    "४": "𑛄",
    "५": "𑛅",
    "६": "𑛆",
    "७": "𑛇",
    "८": "𑛈",
    "९": "𑛉",
    "ॐ": "𑚈𑚫",
    "ऽ": "ऽ",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "𑚳"
  },
  "extra_consonants": {
    "क़": "𑚊𑚷",
    "ख़": "𑚋𑚷",
    "ग़": "𑚌𑚷",
    "ज़": "𑚑𑚷",
    "ड़": "𑚪",
    "ढ़": "𑚗𑚷",
    "फ़": "𑚟𑚷",
    "य़": "𑚣𑚷",
    "ऱ": "𑚤𑚷"
  }
};
schemes.tamil = {
  "vowels": {
    "अ": "அ",
    "आ": "ஆ",
    "इ": "இ",
    "ई": "ஈ",
    "उ": "உ",
    "ऊ": "ஊ",
    "ऋ": "ரு'",
    "ॠ": "ரூ'",
    "ऌ": "லு'",
    "ॡ": "லூ'",
    "ऎ": "எ",
    "ए": "ஏ",
    "ऐ": "ஐ",
    "ऒ": "ஒ",
    "ओ": "ஓ",
    "औ": "ஔ"
  },
  "vowel_marks": {
    "ा": "ா",
    "ि": "ி",
    "ी": "ீ",
    "ु": "ு",
    "ू": "ூ",
    "ृ": "்ரு'",
    "ॄ": "்ரூ'",
    "ॢ": "்லு'",
    "ॣ": "்லூ'",
    "ॆ": "ெ",
    "े": "ே",
    "ै": "ை",
    "ॊ": "ொ",
    "ो": "ோ",
    "ौ": "ௌ"
  },
  "yogavaahas": {
    "ं": "ம்",
    "ः": "ஃ",
    "ँ": ""
  },
  "virama": {
    "्": "்"
  },
  "consonants": {
    "क": "க",
    "ख": "க",
    "ग": "க",
    "घ": "க",
    "ङ": "ங",
    "च": "ச",
    "छ": "ச",
    "ज": "ஜ",
    "झ": "ச",
    "ञ": "ஞ",
    "ट": "ட",
    "ठ": "ட",
    "ड": "ட",
    "ढ": "ட",
    "ण": "ண",
    "त": "த",
    "थ": "த",
    "द": "த",
    "ध": "த",
    "न": "ந",
    "प": "ப",
    "फ": "ப",
    "ब": "ப",
    "भ": "ப",
    "म": "ம",
    "य": "ய",
    "र": "ர",
    "ल": "ல",
    "व": "வ",
    "श": "ஶ",
    "ष": "ஷ",
    "स": "ஸ",
    "ह": "ஹ",
    "ळ": "ள",
    "क्ष": "க்ஷ",
    "ज्ञ": "ஜ்ஞ"
  },
  "symbols": {
    "०": "௦",
    "१": "௧",
    "२": "௨",
    "३": "௩",
    "४": "௪",
    "५": "௫",
    "६": "௬",
    "७": "௭",
    "८": "௮",
    "९": "௯",
    "ॐ": "ௐ",
    "ऽ": "ऽ",
    "।": "।",
    "॥": "॥"
  },
  "extra_consonants": {
    "क़": "ஃ'க",
    "ख़": "ஃக²",
    "ग़": "ஃக³",
    "ज़": "ஃஜ",
    "ड़": "ஃட²",
    "ढ़": "ஃட³",
    "फ़": "ஃப",
    "य़": "ஃய",
    "ऱ": "ற",
    "ऴ": "ழ",
    "ऩ": "ன"
  }
};
schemes.tamil_extended = {
  "vowels": {
    "अ": "அ",
    "आ": "ஆ",
    "इ": "இ",
    "ई": "ஈ",
    "उ": "உ",
    "ऊ": "ஊ",
    "ऋ": "𑌋",
    "ॠ": "𑍠",
    "ऌ": "𑌌",
    "ॡ": "𑍡",
    "ऎ": "எ",
    "ए": "ஏ",
    "ऐ": "ஐ",
    "ऒ": "ஒ",
    "ओ": "ஓ",
    "औ": "ஔ"
  },
  "vowel_marks": {
    "ा": "ா",
    "ि": "ி",
    "ी": "ீ",
    "ु": "ு",
    "ू": "ூ",
    "ृ": "𑍃",
    "ॄ": "𑍄",
    "ॢ": "𑍢",
    "ॣ": "𑍣",
    "ॆ": "ெ",
    "े": "ே",
    "ै": "ை",
    "ॊ": "ொ",
    "ो": "ோ",
    "ौ": "ௌ"
  },
  "yogavaahas": {
    "ं": "𑌂",
    "ः": "𑌃",
    "ँ": "𑌁"
  },
  "virama": {
    "्": "்"
  },
  "consonants": {
    "क": "க",
    "ख": "𑌖",
    "ग": "𑌗",
    "घ": "𑌘",
    "ङ": "ங",
    "च": "ச",
    "छ": "𑌛",
    "ज": "ஜ",
    "झ": "𑌝",
    "ञ": "ஞ",
    "ट": "ட",
    "ठ": "𑌠",
    "ड": "𑌡",
    "ढ": "𑌢",
    "ण": "ண",
    "त": "த",
    "थ": "𑌥",
    "द": "𑌦",
    "ध": "𑌧",
    "न": "ந",
    "प": "ப",
    "फ": "𑌫",
    "ब": "𑌬",
    "भ": "𑌭",
    "म": "ம",
    "य": "ய",
    "र": "ர",
    "ल": "ல",
    "व": "வ",
    "श": "ஶ",
    "ष": "ஷ",
    "स": "ஸ",
    "ह": "ஹ",
    "ळ": "ள",
    "क्ष": "க்ஷ",
    "ज्ञ": "ஜ்ஞ"
  },
  "symbols": {
    "०": "௦",
    "१": "௧",
    "२": "௨",
    "३": "௩",
    "४": "௪",
    "५": "௫",
    "६": "௬",
    "७": "௭",
    "८": "௮",
    "९": "௯",
    "ॐ": "ௐ",
    "ऽ": "𑌽",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "ெ"
  },
  "extra_consonants": {
    "क़": "க𑌼",
    "ख़": "𑌖𑌼",
    "ग़": "𑌗𑌼",
    "ज़": "ஜ𑌼",
    "ड़": "𑌡𑌼",
    "ढ़": "𑌢𑌼",
    "फ़": "𑌫𑌼",
    "य़": "ய𑌼",
    "ऱ": "ற",
    "ऴ": "ழ",
    "ऩ": "ன"
  }
};
schemes.tamil_superscripted = {
  "vowels": {
    "अ": "அ",
    "आ": "ஆ",
    "इ": "இ",
    "ई": "ஈ",
    "उ": "உ",
    "ऊ": "ஊ",
    "ऋ": "ருʼ",
    "ॠ": "ரூʼ",
    "ऌ": "லுʼ",
    "ॡ": "லூʼ",
    "ऎ": "எ",
    "ए": "ஏ",
    "ऐ": "ஐ",
    "ऒ": "ஒ",
    "ओ": "ஓ",
    "औ": "ஔ"
  },
  "vowel_marks": {
    "ा": "ா",
    "ि": "ி",
    "ी": "ீ",
    "ु": "ு",
    "ू": "ூ",
    "ृ": "்ருʼ",
    "ॄ": "்ரூʼ",
    "ॢ": "்லுʼ",
    "ॣ": "்லூʼ",
    "ॆ": "ெ",
    "े": "ே",
    "ै": "ை",
    "ॊ": "ொ",
    "ो": "ோ",
    "ौ": "ௌ"
  },
  "yogavaahas": {
    "ं": "ம்ʼ",
    "ः": "꞉",
    "ँ": "ம்ˮ"
  },
  "virama": {
    "्": "்"
  },
  "consonants": {
    "क": "க",
    "ख": "க²",
    "ग": "க³",
    "घ": "க⁴",
    "ङ": "ங",
    "च": "ச",
    "छ": "ச²",
    "ज": "ஜ",
    "झ": "ஜ²",
    "ञ": "ஞ",
    "ट": "ட",
    "ठ": "ட²",
    "ड": "ட³",
    "ढ": "ட⁴",
    "ण": "ண",
    "त": "த",
    "थ": "த²",
    "द": "த³",
    "ध": "த⁴",
    "न": "ந",
    "प": "ப",
    "फ": "ப²",
    "ब": "ப³",
    "भ": "ப⁴",
    "म": "ம",
    "य": "ய",
    "र": "ர",
    "ल": "ல",
    "व": "வ",
    "श": "ஶ",
    "ष": "ஷ",
    "स": "ஸ",
    "ह": "ஹ",
    "ळ": "ள",
    "क्ष": "க்ஷ",
    "ज्ञ": "ஜ்ஞ"
  },
  "symbols": {
    "०": "௦",
    "१": "௧",
    "२": "௨",
    "३": "௩",
    "४": "௪",
    "५": "௫",
    "६": "௬",
    "७": "௭",
    "८": "௮",
    "९": "௯",
    "ॐ": "ௐ",
    "ऽ": "(அ)",
    "।": ".",
    "॥": ".."
  },
  "candra": {
    "ॅ": "ே"
  },
  "extra_consonants": {
    "क़": "ஃʼக",
    "ख़": "ஃக²",
    "ग़": "ஃக³",
    "ज़": "ஃஜ",
    "ड़": "ஃட²",
    "ढ़": "ஃட³",
    "फ़": "ஃப",
    "य़": "ஃய",
    "ऱ": "ற",
    "ऴ": "ழ",
    "ऩ": "ன"
  }
};
schemes.telugu = {
  "vowels": {
    "अ": "అ",
    "आ": "ఆ",
    "इ": "ఇ",
    "ई": "ఈ",
    "उ": "ఉ",
    "ऊ": "ఊ",
    "ऋ": "ఋ",
    "ॠ": "ౠ",
    "ऌ": "ఌ",
    "ॡ": "ౡ",
    "ऎ": "ఎ",
    "ए": "ఏ",
    "ऐ": "ఐ",
    "ऒ": "ఒ",
    "ओ": "ఓ",
    "औ": "ఔ"
  },
  "vowel_marks": {
    "ा": "ా",
    "ि": "ి",
    "ी": "ీ",
    "ु": "ు",
    "ू": "ూ",
    "ृ": "ృ",
    "ॄ": "ౄ",
    "ॢ": "ౢ",
    "ॣ": "ౣ",
    "ॆ": "ె",
    "े": "ే",
    "ै": "ై",
    "ॊ": "ొ",
    "ो": "ో",
    "ौ": "ౌ"
  },
  "yogavaahas": {
    "ं": "ం",
    "ः": "ః",
    "ँ": "ఁ"
  },
  "virama": {
    "्": "్"
  },
  "consonants": {
    "क": "క",
    "ख": "ఖ",
    "ग": "గ",
    "घ": "ఘ",
    "ङ": "ఙ",
    "च": "చ",
    "छ": "ఛ",
    "ज": "జ",
    "झ": "ఝ",
    "ञ": "ఞ",
    "ट": "ట",
    "ठ": "ఠ",
    "ड": "డ",
    "ढ": "ఢ",
    "ण": "ణ",
    "त": "త",
    "थ": "థ",
    "द": "ద",
    "ध": "ధ",
    "न": "న",
    "प": "ప",
    "फ": "ఫ",
    "ब": "బ",
    "भ": "భ",
    "म": "మ",
    "य": "య",
    "र": "ర",
    "ल": "ల",
    "व": "వ",
    "श": "శ",
    "ष": "ష",
    "स": "స",
    "ह": "హ",
    "ळ": "ళ",
    "क्ष": "క్ష",
    "ज्ञ": "జ్ఞ"
  },
  "symbols": {
    "०": "౦",
    "१": "౧",
    "२": "౨",
    "३": "౩",
    "४": "౪",
    "५": "౫",
    "६": "౬",
    "७": "౭",
    "८": "౮",
    "९": "౯",
    "ॐ": "ఓం",
    "ऽ": "ఽ",
    "।": "।",
    "॥": "॥"
  },
  "extra_consonants": {
    "क़": "",
    "ख़": "",
    "ग़": "",
    "ज़": "",
    "ड़": "",
    "ढ़": "",
    "फ़": "",
    "य़": "",
    "ऱ": "ఱ",
    "ऴ": "ఴ"
  }
};
schemes.thai = {
  "vowels": {
    "अ": "อ",
    "आ": "อา",
    "इ": "อิ",
    "ई": "อี",
    "उ": "อุ",
    "ऊ": "อู",
    "ऋ": "ฤ",
    "ॠ": "ฤๅ",
    "ऌ": "ฦ",
    "ॡ": "ฦๅ",
    "ऎ": "เอะ",
    "ए": "เอ",
    "ऐ": "ไอ",
    "ऒ": "โอะ",
    "ओ": "โอ",
    "औ": "เอา"
  },
  "vowel_marks": {
    "ा": "า",
    "ि": "ิ",
    "ी": "ี",
    "ु": "ุ",
    "ू": "ู",
    "ृ": "ฺฤ",
    "ॄ": "ฺฤๅ",
    "ॢ": "ฺฦ",
    "ॣ": "ฺฦๅ",
    "ॆ": "เะ",
    "े": "เ",
    "ै": "ไ",
    "ॊ": "โะ",
    "ो": "โ",
    "ौ": "เา"
  },
  "yogavaahas": {
    "ं": "ํ",
    "ः": "ห์",
    "ँ": "ํ"
  },
  "virama": {
    "्": "ฺ"
  },
  "consonants": {
    "क": "ก",
    "ख": "ข",
    "ग": "ค",
    "घ": "ฆ",
    "ङ": "ง",
    "च": "จ",
    "छ": "ฉ",
    "ज": "ช",
    "झ": "ฌ",
    "ञ": "ญ",
    "ट": "ฏ",
    "ठ": "ฐ",
    "ड": "ฑ",
    "ढ": "ฒ",
    "ण": "ณ",
    "त": "ต",
    "थ": "ถ",
    "द": "ท",
    "ध": "ธ",
    "न": "น",
    "प": "ป",
    "फ": "ผ",
    "ब": "พ",
    "भ": "ภ",
    "म": "ม",
    "य": "ย",
    "र": "ร",
    "ल": "ล",
    "व": "ว",
    "श": "ศ",
    "ष": "ษ",
    "स": "ส",
    "ह": "ห",
    "ळ": "ฬ",
    "क्ष": "กฺษ",
    "ज्ञ": "ชฺญ"
  },
  "symbols": {
    "०": "๐",
    "१": "๑",
    "२": "๒",
    "३": "๓",
    "४": "๔",
    "५": "๕",
    "६": "๖",
    "७": "๗",
    "८": "๘",
    "९": "๙",
    "ॐ": "โอํ",
    "ऽ": "'",
    "।": "ฯ",
    "॥": "๚"
  },
  "candra": {
    "ॅ": "แะ"
  },
  "extra_consonants": {
    "क़": "ก",
    "ख़": "ข",
    "ग़": "ค",
    "ज़": "ซ",
    "ड़": "ร",
    "ढ़": "รฺห",
    "फ़": "ฟ",
    "य़": "ย",
    "ऱ": "ร"
  }
};
schemes.tibetan = {
  "vowels": {
    "अ": "ཨ",
    "आ": "ཨཱ",
    "इ": "ཨི",
    "ई": "ཨཱི",
    "उ": "ཨུ",
    "ऊ": "ཨཱུ",
    "ऋ": "རྀ",
    "ॠ": "རཱྀ",
    "ऌ": "ལྀ",
    "ॡ": "ལཱྀ",
    "ऎ": "ཨེ",
    "ए": "ཨེ",
    "ऐ": "ཨཻ",
    "ऒ": "ཨོ",
    "ओ": "ཨོ",
    "औ": "ཨཽ"
  },
  "vowel_marks": {
    "ा": "ཱ",
    "ि": "ི",
    "ी": "ཱི",
    "ु": "ུ",
    "ू": "ཱུ",
    "ृ": "ྲྀ",
    "ॄ": "ྲཱྀ",
    "ॢ": "ླྀ",
    "ॣ": "ླཱྀ",
    "ॆ": "ེ",
    "े": "ེ",
    "ै": "ཻ",
    "ॊ": "ོ",
    "ो": "ོ",
    "ौ": "ཽ"
  },
  "yogavaahas": {
    "ं": "ཾ",
    "ः": "ཿ",
    "ँ": "ྃ"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "ཀ",
    "ख": "ཁ",
    "ग": "ག",
    "घ": "གྷ",
    "ङ": "ང",
    "च": "ཙ",
    "छ": "ཚ",
    "ज": "ཛ",
    "झ": "ཛྷ",
    "ञ": "ཉ",
    "ट": "ཊ",
    "ठ": "ཋ",
    "ड": "ཌ",
    "ढ": "ཌྷ",
    "ण": "ཎ",
    "त": "ཏ",
    "थ": "ཐ",
    "द": "ད",
    "ध": "དྷ",
    "न": "ན",
    "प": "པ",
    "फ": "ཕ",
    "ब": "བ",
    "भ": "བྷ",
    "म": "མ",
    "य": "ཡ",
    "र": "ར",
    "ल": "ལ",
    "व": "བ",
    "श": "ཤ",
    "ष": "ཥ",
    "स": "ས",
    "ह": "ཧ",
    "ळ": "ལ༹",
    "क्ष": "ཀྵ",
    "ज्ञ": "ཛྙ"
  },
  "symbols": {
    "०": "༠",
    "१": "༡",
    "२": "༢",
    "३": "༣",
    "४": "༤",
    "५": "༥",
    "६": "༦",
    "७": "༧",
    "८": "༨",
    "९": "༩",
    "ॐ": "ༀ",
    "ऽ": "྅",
    "।": "།",
    "॥": "༎"
  },
  "candra": {
    "ॅ": "ེ"
  },
  "extra_consonants": {
    "क़": "ཀ༹",
    "ख़": "ཁ༹",
    "ग़": "ག༹",
    "ज़": "ཟ",
    "ड़": "ཌ༹",
    "ढ़": "ཌྷ༹",
    "फ़": "ཕ༹",
    "य़": "ཡ༹",
    "ऱ": "ར༹"
  }
};
schemes.tirhuta_maithili = {
  "vowels": {
    "अ": "𑒁",
    "आ": "𑒂",
    "इ": "𑒃",
    "ई": "𑒄",
    "उ": "𑒅",
    "ऊ": "𑒆",
    "ऋ": "𑒇",
    "ॠ": "𑒈",
    "ऌ": "𑒉",
    "ॡ": "𑒊",
    "ऎ": "𑒁𑒺",
    "ए": "𑒋",
    "ऐ": "𑒌",
    "ऒ": "𑒁𑒽",
    "ओ": "𑒍",
    "औ": "𑒎"
  },
  "vowel_marks": {
    "ा": "𑒰",
    "ि": "𑒱",
    "ी": "𑒲",
    "ु": "𑒳",
    "ू": "𑒴",
    "ृ": "𑒵",
    "ॄ": "𑒶",
    "ॢ": "𑒷",
    "ॣ": "𑒸",
    "ॆ": "𑒺",
    "े": "𑒹",
    "ै": "𑒻",
    "ॊ": "𑒽",
    "ो": "𑒼",
    "ौ": "𑒾"
  },
  "yogavaahas": {
    "ं": "𑓀",
    "ः": "𑓁",
    "ँ": "𑒿"
  },
  "virama": {
    "्": "𑓂"
  },
  "consonants": {
    "क": "𑒏",
    "ख": "𑒐",
    "ग": "𑒑",
    "घ": "𑒒",
    "ङ": "𑒓",
    "च": "𑒔",
    "छ": "𑒕",
    "ज": "𑒖",
    "झ": "𑒗",
    "ञ": "𑒘",
    "ट": "𑒙",
    "ठ": "𑒚",
    "ड": "𑒛",
    "ढ": "𑒜",
    "ण": "𑒝",
    "त": "𑒞",
    "थ": "𑒟",
    "द": "𑒠",
    "ध": "𑒡",
    "न": "𑒢",
    "प": "𑒣",
    "फ": "𑒤",
    "ब": "𑒥",
    "भ": "𑒦",
    "म": "𑒧",
    "य": "𑒨",
    "र": "𑒩",
    "ल": "𑒪",
    "व": "𑒫",
    "श": "𑒬",
    "ष": "𑒭",
    "स": "𑒮",
    "ह": "𑒯",
    "ळ": "𑒝𑓃",
    "क्ष": "𑒏𑓂𑒭",
    "ज्ञ": "𑒖𑓂𑒘"
  },
  "symbols": {
    "०": "𑓐",
    "१": "𑓑",
    "२": "𑓒",
    "३": "𑓓",
    "४": "𑓔",
    "५": "𑓕",
    "६": "𑓖",
    "७": "𑓗",
    "८": "𑓘",
    "९": "𑓙",
    "ॐ": "𑓇",
    "ऽ": "𑓄",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "𑒹"
  },
  "extra_consonants": {
    "क़": "𑒏𑓃",
    "ख़": "𑒐𑓃",
    "ग़": "𑒑𑓃",
    "ज़": "𑒖𑓃",
    "ड़": "𑒛𑓃",
    "ढ़": "𑒜𑓃",
    "फ़": "𑒤𑓃",
    "य़": "𑒨𑓃",
    "ऱ": "𑒩"
  }
};
schemes.urdu = {
  "vowels": {
    "अ": "اَ",
    "आ": "آ",
    "इ": "اِ",
    "ई": "اِی",
    "उ": "اُ",
    "ऊ": "اُو",
    "ऋ": "رِ",
    "ॠ": "رِی",
    "ऌ": "لِ",
    "ॡ": "لِی",
    "ऎ": "اے",
    "ए": "اے",
    "ऐ": "اَے",
    "ऒ": "او",
    "ओ": "او",
    "औ": "اَو"
  },
  "vowel_marks": {
    "ा": "ا",
    "ि": "ِ",
    "ी": "ِی",
    "ु": "ُ",
    "ू": "ُو",
    "ृ": "ْرِ",
    "ॄ": "ْرِی",
    "ॢ": "ْلِ",
    "ॣ": "ْلِی",
    "ॆ": "ے",
    "े": "ے",
    "ै": "ے",
    "ॊ": "و",
    "ो": "و",
    "ौ": "و"
  },
  "yogavaahas": {
    "ं": "ں",
    "ः": "ہ",
    "ँ": "ں"
  },
  "virama": {
    "्": "ْ"
  },
  "consonants": {
    "क": "کَ",
    "ख": "کھَ",
    "ग": "گَ",
    "घ": "گھَ",
    "ङ": "نَ",
    "च": "چَ",
    "छ": "چھَ",
    "ज": "جَ",
    "झ": "جھَ",
    "ञ": "نَ",
    "ट": "ٹَ",
    "ठ": "ٹھَ",
    "ड": "ڈَ",
    "ढ": "ڈھَ",
    "ण": "نَ",
    "त": "تَ",
    "थ": "تھَ",
    "द": "دَ",
    "ध": "دھَ",
    "न": "نَ",
    "प": "پَ",
    "फ": "پھَ",
    "ब": "بَ",
    "भ": "بھَ",
    "म": "مَ",
    "य": "یَ",
    "र": "رَ",
    "ल": "لَ",
    "व": "وَ",
    "श": "شَ",
    "ष": "شَ",
    "स": "سَ",
    "ह": "ہَ",
    "ळ": "لَ",
    "क्ष": "کْشَ",
    "ज्ञ": "جْنَ"
  },
  "symbols": {
    "०": "۰",
    "१": "۱",
    "२": "۲",
    "३": "۳",
    "४": "۴",
    "५": "۵",
    "६": "۶",
    "७": "۷",
    "८": "۸",
    "९": "۹",
    "ॐ": "اومْ",
    "ऽ": "",
    "।": "۔",
    "॥": "۔۔"
  },
  "candra": {
    "ॅ": "ے"
  },
  "extra_consonants": {
    "क़": "قَ",
    "ख़": "خَ",
    "ग़": "غَ",
    "ज़": "زَ",
    "ड़": "ڑَ",
    "ढ़": "ڑھَ",
    "फ़": "فَ",
    "य़": "یَ",
    "ऱ": "رَ"
  }
};
schemes.vattelutu = {
  "vowels": {
    "अ": "அ",
    "आ": "ஆ",
    "इ": "இ",
    "ई": "ஈ",
    "उ": "உ",
    "ऊ": "ஊ",
    "ऋ": "ரு",
    "ॠ": "ரூ",
    "ऌ": "லு",
    "ॡ": "லூ",
    "ऎ": "எ",
    "ए": "ஏ",
    "ऐ": "ஐ",
    "ऒ": "ஒ",
    "ओ": "ஓ",
    "औ": "ஔ"
  },
  "vowel_marks": {
    "ा": "ா",
    "ि": "ி",
    "ी": "ீ",
    "ु": "ு",
    "ू": "ூ",
    "ृ": "்ரு",
    "ॄ": "்ரூ",
    "ॢ": "்லு",
    "ॣ": "்லூ",
    "ॆ": "ெ",
    "े": "ே",
    "ै": "ை",
    "ॊ": "ொ",
    "ो": "ோ",
    "ौ": "ௌ"
  },
  "yogavaahas": {
    "ं": "ம்",
    "ः": "꞉",
    "ँ": "ம்"
  },
  "virama": {
    "्": "்"
  },
  "consonants": {
    "क": "க",
    "ख": "க",
    "ग": "க",
    "घ": "க",
    "ङ": "ங",
    "च": "ச",
    "छ": "ச",
    "ज": "ச",
    "झ": "ச",
    "ञ": "ஞ",
    "ट": "ட",
    "ठ": "ட",
    "ड": "ட",
    "ढ": "ட",
    "ण": "ண",
    "त": "த",
    "थ": "த",
    "द": "த",
    "ध": "த",
    "न": "ந",
    "प": "ப",
    "फ": "ப",
    "ब": "ப",
    "भ": "ப",
    "म": "ம",
    "य": "ய",
    "र": "ர",
    "ल": "ல",
    "व": "வ",
    "श": "ச",
    "ष": "ச",
    "स": "ச",
    "ह": "க",
    "ळ": "ள",
    "क्ष": "க்ச",
    "ज्ञ": "ச்ஞ"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "ஓம்",
    "ऽ": "",
    "।": ".",
    "॥": ".."
  },
  "candra": {
    "ॅ": "ெ"
  },
  "extra_consonants": {
    "क़": "க",
    "ख़": "க",
    "ग़": "க",
    "ज़": "ச",
    "ड़": "ட",
    "ढ़": "ட",
    "फ़": "ப",
    "य़": "ய",
    "ऱ": "ற"
  }
};
schemes.wancho = {
  "vowels": {
    "अ": "𞋁",
    "आ": "𞋀",
    "इ": "𞋜",
    "ई": "𞋜",
    "उ": "𞋞",
    "ऊ": "𞋞",
    "ऋ": "𞋗𞋞",
    "ॠ": "𞋗𞋞",
    "ऌ": "𞋈𞋞",
    "ॡ": "𞋈𞋞",
    "ऎ": "𞋛",
    "ए": "𞋛",
    "ऐ": "𞋀𞋜",
    "ऒ": "𞋕",
    "ओ": "𞋕",
    "औ": "𞋖"
  },
  "vowel_marks": {
    "ा": "𞋀",
    "ि": "𞋜",
    "ी": "𞋜",
    "ु": "𞋞",
    "ू": "𞋞",
    "ृ": "𞋗𞋞",
    "ॄ": "𞋗𞋞",
    "ॢ": "𞋈𞋞",
    "ॣ": "𞋈𞋞",
    "ॆ": "𞋛",
    "े": "𞋛",
    "ै": "𞋀𞋜",
    "ॊ": "𞋕",
    "ो": "𞋕",
    "ौ": "𞋖"
  },
  "yogavaahas": {
    "ं": "𞋝",
    "ः": "𞋫",
    "ँ": "𞋉"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "𞋔𞋁",
    "ख": "𞋙𞋁",
    "ग": "𞋅𞋁",
    "घ": "𞋅𞋁",
    "ङ": "𞋝𞋁",
    "च": "𞋃𞋁",
    "छ": "𞋃𞋁",
    "ज": "𞋐𞋁",
    "झ": "𞋐𞋁",
    "ञ": "𞋩𞋁",
    "ट": "𞋋𞋁",
    "ठ": "𞋌𞋁",
    "ड": "𞋄𞋁",
    "ढ": "𞋄𞋁",
    "ण": "𞋉𞋁",
    "त": "𞋋𞋁",
    "थ": "𞋌𞋁",
    "द": "𞋄𞋁",
    "ध": "𞋄𞋁",
    "न": "𞋉𞋁",
    "प": "𞋊𞋁",
    "फ": "𞋇𞋁",
    "ब": "𞋂𞋁",
    "भ": "𞋂𞋁",
    "म": "𞋘𞋁",
    "य": "𞋆𞋁",
    "र": "𞋗𞋁",
    "ल": "𞋈𞋁",
    "व": "𞋓𞋁",
    "श": "𞋏𞋁",
    "ष": "𞋏𞋁",
    "स": "𞋎𞋁",
    "ह": "𞋚𞋁",
    "ळ": "𞋟𞋁",
    "क्ष": "𞋔𞋏𞋁",
    "ज्ञ": "𞋐𞋩𞋁"
  },
  "symbols": {
    "०": "𞋰",
    "१": "𞋱",
    "२": "𞋲",
    "३": "𞋳",
    "४": "𞋴",
    "५": "𞋵",
    "६": "𞋶",
    "७": "𞋷",
    "८": "𞋸",
    "९": "𞋹",
    "ॐ": "𞋦",
    "ऽ": "",
    "।": ".",
    "॥": ".."
  },
  "candra": {
    "ॅ": "𞋛"
  },
  "extra_consonants": {
    "क़": "𞋔𞋁",
    "ख़": "𞋙𞋁",
    "ग़": "𞋅𞋁",
    "ज़": "𞋑𞋁",
    "ड़": "𞋄𞋁",
    "ढ़": "𞋄𞋁",
    "फ़": "𞋍𞋁",
    "य़": "𞋆𞋁",
    "ऱ": "𞋗𞋁"
  }
};
schemes.warang_citi = {
  "vowels": {
    "अ": "𑣁",
    "आ": "𑣁𑣙",
    "इ": "𑣂",
    "ई": "𑣆",
    "उ": "𑣃",
    "ऊ": "𑣇",
    "ऋ": "𑣜𑣃",
    "ॠ": "𑣜𑣇",
    "ऌ": "𑣚𑣃",
    "ॡ": "𑣚𑣇",
    "ऎ": "𑣈",
    "ए": "𑣈",
    "ऐ": "𑣆",
    "ऒ": "𑣉",
    "ओ": "𑣉",
    "औ": "𑣇"
  },
  "vowel_marks": {
    "ा": "𑣁𑣙",
    "ि": "𑣂",
    "ी": "𑣆",
    "ु": "𑣃",
    "ू": "𑣇",
    "ृ": "𑣜𑣃",
    "ॄ": "𑣜𑣇",
    "ॢ": "𑣚𑣃",
    "ॣ": "𑣚𑣇",
    "ॆ": "𑣈",
    "े": "𑣈",
    "ै": "𑣆",
    "ॊ": "𑣉",
    "ो": "𑣉",
    "ौ": "𑣇"
  },
  "yogavaahas": {
    "ं": "𑣀",
    "ः": "𑣄",
    "ँ": "𑣀"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "𑣌",
    "ख": "𑣌",
    "ग": "𑣋",
    "घ": "𑣋",
    "ङ": "𑣊",
    "च": "𑣏",
    "छ": "𑣏",
    "ज": "𑣎",
    "झ": "𑣎",
    "ञ": "𑣍",
    "ट": "𑣒",
    "ठ": "𑣒",
    "ड": "𑣑",
    "ढ": "𑣑",
    "ण": "𑣐",
    "त": "𑣕",
    "थ": "𑣕",
    "द": "𑣔",
    "ध": "𑣔",
    "न": "𑣓",
    "प": "𑣘",
    "फ": "𑣘",
    "ब": "𑣗",
    "भ": "𑣗",
    "म": "𑣖",
    "य": "𑣅",
    "र": "𑣜",
    "ल": "𑣚",
    "व": "𑣟",
    "श": "𑣞",
    "ष": "𑣞",
    "स": "𑣞",
    "ह": "𑣙",
    "ळ": "𑣚",
    "क्ष": "𑣌𑣞",
    "ज्ञ": "𑣎𑣍"
  },
  "symbols": {
    "०": "𑣠",
    "१": "𑣡",
    "२": "𑣢",
    "३": "𑣣",
    "४": "𑣤",
    "५": "𑣥",
    "६": "𑣦",
    "७": "𑣧",
    "८": "𑣨",
    "९": "𑣩",
    "ॐ": "𑣿",
    "ऽ": "'",
    "।": ".",
    "॥": ".."
  },
  "candra": {
    "ॅ": "𑣈"
  },
  "extra_consonants": {
    "क़": "𑣌",
    "ख़": "𑣌",
    "ग़": "𑣋",
    "ज़": "𑣎",
    "ड़": "𑣛",
    "ढ़": "𑣛",
    "फ़": "𑣘",
    "य़": "𑣅",
    "ऱ": "𑣜"
  }
};
schemes.zanbazar_square = {
  "vowels": {
    "अ": "𑨀",
    "आ": "𑨀𑨊",
    "इ": "𑨀𑨁",
    "ई": "𑨀𑨁𑨊",
    "उ": "𑨀𑨂",
    "ऊ": "𑨀𑨂𑨊",
    "ऋ": "𑨫𑨉",
    "ॠ": "𑨫𑨉𑨊",
    "ऌ": "𑨬𑨉",
    "ॡ": "𑨬𑨉𑨊",
    "ऎ": "𑨀𑨄",
    "ए": "𑨀𑨄",
    "ऐ": "𑨀𑨄𑨊",
    "ऒ": "𑨀𑨆",
    "ओ": "𑨀𑨆",
    "औ": "𑨀𑨆𑨊"
  },
  "vowel_marks": {
    "ा": "𑨊",
    "ि": "𑨁",
    "ी": "𑨁𑨊",
    "ु": "𑨂",
    "ू": "𑨂𑨊",
    "ृ": "𑩇𑨫𑨉",
    "ॄ": "𑩇𑨫𑨉𑨊",
    "ॢ": "𑩇𑨬𑨉",
    "ॣ": "𑩇𑨬𑨉𑨊",
    "ॆ": "𑨄",
    "े": "𑨄",
    "ै": "𑨄𑨊",
    "ॊ": "𑨆",
    "ो": "𑨆",
    "ौ": "𑨆𑨊"
  },
  "yogavaahas": {
    "ं": "𑨸",
    "ः": "𑨹",
    "ँ": "𑨵"
  },
  "virama": {
    "्": "𑨴"
  },
  "consonants": {
    "क": "𑨋",
    "ख": "𑨌",
    "ग": "𑨍",
    "घ": "𑨎",
    "ङ": "𑨏",
    "च": "𑨣",
    "छ": "𑨤",
    "ज": "𑨥",
    "झ": "𑨦",
    "ञ": "𑨓",
    "ट": "𑨔",
    "ठ": "𑨕",
    "ड": "𑨖",
    "ढ": "𑨗",
    "ण": "𑨘",
    "त": "𑨙",
    "थ": "𑨚",
    "द": "𑨛",
    "ध": "𑨜",
    "न": "𑨝",
    "प": "𑨞",
    "फ": "𑨟",
    "ब": "𑨠",
    "भ": "𑨡",
    "म": "𑨢",
    "य": "𑨪",
    "र": "𑨫",
    "ल": "𑨬",
    "व": "𑨭",
    "श": "𑨮",
    "ष": "𑨯",
    "स": "𑨰",
    "ह": "𑨱",
    "ळ": "𑨬",
    "क्ष": "𑨲",
    "ज्ञ": "𑨥𑩇𑨓"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "𑨀𑨆𑨵",
    "ऽ": "",
    "।": "𑩂",
    "॥": "𑩃"
  },
  "candra": {
    "ॅ": "𑨄"
  },
  "extra_consonants": {
    "क़": "𑨋",
    "ख़": "𑨌",
    "ग़": "𑨍",
    "ज़": "𑨨",
    "ड़": "𑨖",
    "ढ़": "𑨗",
    "फ़": "𑨟",
    "य़": "𑨪",
    "ऱ": "𑨫"
  }
};
schemes.avestan = {
  "vowels": {
    "अ": "𐬀",
    "आ": "𐬁",
    "इ": "𐬌",
    "ई": "𐬍",
    "उ": "𐬎",
    "ऊ": "𐬏",
    "ऋ": "𐬆𐬭𐬆",
    "ॠ": "𐬇𐬭𐬇",
    "ऌ": "𐬆𐬮𐬆",
    "ॡ": "𐬇𐬮𐬇",
    "ऎ": "𐬈",
    "ए": "𐬉",
    "ऐ": "𐬀𐬌",
    "ऒ": "𐬊",
    "ओ": "𐬋",
    "औ": "𐬀𐬎"
  },
  "vowel_marks": {
    "ा": "𐬁",
    "ि": "𐬌",
    "ी": "𐬍",
    "ु": "𐬎",
    "ू": "𐬏",
    "ृ": "𐬆𐬭𐬆",
    "ॄ": "𐬇𐬭𐬇",
    "ॢ": "𐬆𐬮𐬆",
    "ॣ": "𐬇𐬮𐬇",
    "ॆ": "𐬈",
    "े": "𐬉",
    "ै": "𐬀𐬌",
    "ॊ": "𐬊",
    "ो": "𐬋",
    "ौ": "𐬀𐬎"
  },
  "yogavaahas": {
    "ं": "𐬩",
    "ः": "𐬵",
    "ँ": "𐬩"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "𐬐𐬀",
    "ख": "𐬑𐬀",
    "ग": "𐬔𐬀",
    "घ": "𐬖𐬀",
    "ङ": "𐬢𐬀",
    "च": "𐬗𐬀",
    "छ": "𐬗𐬀",
    "ज": "𐬘𐬀",
    "झ": "𐬘𐬀",
    "ञ": "𐬦𐬀",
    "ट": "𐬙𐬀",
    "ठ": "𐬚𐬀",
    "ड": "𐬛𐬀",
    "ढ": "𐬛𐬀",
    "ण": "𐬧𐬀",
    "त": "𐬙𐬀",
    "थ": "𐬚𐬀",
    "द": "𐬛𐬀",
    "ध": "𐬜𐬀",
    "न": "𐬥𐬀",
    "प": "𐬞𐬀",
    "फ": "𐬞𐬀",
    "ब": "𐬠𐬀",
    "भ": "𐬠𐬀",
    "म": "𐬨𐬀",
    "य": "𐬫𐬀",
    "र": "𐬭𐬀",
    "ल": "𐬮𐬀",
    "व": "𐬬𐬀",
    "श": "𐬱𐬀",
    "ष": "𐬴𐬀",
    "स": "𐬯𐬀",
    "ह": "𐬵𐬀",
    "ळ": "𐬮𐬀",
    "क्ष": "𐬐𐬴𐬀",
    "ज्ञ": "𐬘𐬦𐬀"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "𐬋𐬨",
    "ऽ": "",
    "।": "𐬼‏",
    "॥": "𐬽‏"
  },
  "zwj": {
    "‍": "‍"
  },
  "skip": {
    "undefined": ""
  },
  "accents": {
    "॑": "॑",
    "॒": "॒"
  },
  "candra": {
    "ॅ": "𐬉"
  },
  "extra_consonants": {
    "क़": "𐬐𐬀",
    "ख़": "𐬓𐬀",
    "ग़": "𐬕𐬀",
    "ज़": "𐬰𐬀",
    "ड़": "𐬛𐬀",
    "ढ़": "𐬜𐬀",
    "फ़": "𐬟𐬀",
    "य़": "𐬪𐬀",
    "ऱ": "𐬭𐬀"
  },
  "isRomanScheme": true
};
schemes.baraha = {
  "_vowel_comment": ["omissions  from optitrans_dravidian", "ea", "oa"],
  "_yogavaahas_comment": ["omissions  from optitrans_dravidian", "kH", "pH"],
  "vowels": {
    "अ": "a",
    "आ": "A",
    "इ": "i",
    "ई": "I",
    "उ": "u",
    "ऊ": "U",
    "ऋ": "Ru",
    "ॠ": "RU",
    "ऌ": "~lu",
    "ॡ": "~lU",
    "ऎ": "e",
    "ए": "E",
    "ऐ": "ai",
    "ऒ": "o",
    "ओ": "O",
    "औ": "au",
    "ऍ": "",
    "ऑ": ""
  },
  "yogavaahas": {
    "ं": "M",
    "ः": "H",
    "ँ": "~M",
    "ᳵ": "",
    "ᳶ": "",
    "ꣳ": "(gg)"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "k",
    "ख": "kh",
    "ग": "g",
    "घ": "gh",
    "ङ": "~g",
    "च": "ch",
    "छ": "Ch",
    "ज": "j",
    "झ": "jh",
    "ञ": "~j",
    "ट": "T",
    "ठ": "Th",
    "ड": "D",
    "ढ": "Dh",
    "ण": "N",
    "त": "t",
    "थ": "th",
    "द": "d",
    "ध": "dh",
    "न": "n",
    "प": "p",
    "फ": "ph",
    "ब": "b",
    "भ": "bh",
    "म": "m",
    "य": "y",
    "र": "r",
    "ल": "l",
    "व": "v",
    "श": "sh",
    "ष": "Sh",
    "स": "s",
    "ह": "h",
    "ळ": "L",
    "क्ष": "kSh",
    "ज्ञ": "j~ja"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "OM",
    "ऽ": "&",
    "।": "|",
    "॥": "||"
  },
  "candra": {
    "ॅ": "~M"
  },
  "zwj": {
    "‍": "^"
  },
  "zwnj": {
    "‌": "^^"
  },
  "skip": {
    "undefined": "_"
  },
  "accents": {
    "॑": "#",
    "॒": "q",
    "᳡": "Q",
    "꣡": "V",
    "꣢": "W",
    "꣣": "$"
  },
  "alternates": {
    "A": ["aa"],
    "I": ["ee"],
    "U": ["oo"],
    "kh": ["K"],
    "gh": ["G"],
    "ch": ["c"],
    "Ch": ["C"],
    "jh": ["J"],
    "ph": ["P"],
    "bh": ["B"],
    "~n": ["JN"],
    "v": ["w"],
    "sh": ["S"],
    "h": ["~h"],
    "V": ["x"],
    "|": ["."],
    "||": [".."]
  },
  "isRomanScheme": true
};
schemes.cyrillic = {
  "vowels": {
    "अ": "а",
    "आ": "а̄",
    "इ": "и",
    "ई": "ӣ",
    "उ": "у",
    "ऊ": "ӯ",
    "ऋ": "р̣",
    "ॠ": "р̣̄",
    "ऌ": "л̣",
    "ॡ": "л̣̄",
    "ऎ": "э̆",
    "ए": "э",
    "ऐ": "аи",
    "ऒ": "о̆",
    "ओ": "о",
    "औ": "ау"
  },
  "yogavaahas": {
    "ं": "м̣",
    "ः": "х̣",
    "ँ": "м̐"
  },
  "virama": {
    "्": ""
  },
  "accents": {
    "॑": "̭",
    "॒": "॒",
    "᳡": "̀",
    "꣡": "́",
    "꣢": "²",
    "꣣": "³",
    "꣤": "⁴",
    "꣥": "⁵",
    "꣦": "⁶",
    "꣧": "⁷",
    "꣨": "⁸",
    "꣩": "⁹",
    "꣪": "꣪",
    "꣫": "꣫",
    "꣬": "꣬",
    "꣭": "꣭",
    "꣮": "꣮",
    "꣯": "꣯",
    "꣰": "꣰",
    "꣱": "꣱"
  },
  "consonants": {
    "क": "к",
    "ख": "кх",
    "ग": "г",
    "घ": "гх",
    "ङ": "н̇",
    "च": "ч",
    "छ": "чх",
    "ज": "дж",
    "झ": "джх",
    "ञ": "н̃",
    "ट": "т̣",
    "ठ": "т̣х",
    "ड": "д̣",
    "ढ": "д̣х",
    "ण": "н̣",
    "त": "т",
    "थ": "тх",
    "द": "д",
    "ध": "дх",
    "न": "н",
    "प": "п",
    "फ": "пх",
    "ब": "б",
    "भ": "бх",
    "म": "м",
    "य": "й",
    "र": "р",
    "ल": "л",
    "व": "в",
    "श": "ш́",
    "ष": "ш",
    "स": "с",
    "ह": "х",
    "ळ": "л̤",
    "क्ष": "кш",
    "ज्ञ": "джн̃"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "ом̣",
    "ऽ": "'",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "ӕ"
  },
  "extra_consonants": {
    "क़": "ԛ",
    "ख़": "к͟х",
    "ग़": "г̇",
    "ज़": "з",
    "ड़": "р̤",
    "ढ़": "р̤х",
    "फ़": "ф",
    "य़": "й̣",
    "ऱ": "р̱"
  },
  "isRomanScheme": true
};
schemes.hk = {
  "vowels": {
    "अ": "a",
    "आ": "A",
    "इ": "i",
    "ई": "I",
    "उ": "u",
    "ऊ": "U",
    "ऋ": "R",
    "ॠ": "RR",
    "ऌ": "lR",
    "ॡ": "lRR",
    "ऎ": "è",
    "ए": "e",
    "ऐ": "ai",
    "ऒ": "ò",
    "ओ": "o",
    "औ": "au"
  },
  "yogavaahas": {
    "ं": "M",
    "ः": "H",
    "ँ": "~"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "k",
    "ख": "kh",
    "ग": "g",
    "घ": "gh",
    "ङ": "G",
    "च": "c",
    "छ": "ch",
    "ज": "j",
    "झ": "jh",
    "ञ": "J",
    "ट": "T",
    "ठ": "Th",
    "ड": "D",
    "ढ": "Dh",
    "ण": "N",
    "त": "t",
    "थ": "th",
    "द": "d",
    "ध": "dh",
    "न": "n",
    "प": "p",
    "फ": "ph",
    "ब": "b",
    "भ": "bh",
    "म": "m",
    "य": "y",
    "र": "r",
    "ल": "l",
    "व": "v",
    "श": "z",
    "ष": "S",
    "स": "s",
    "ह": "h",
    "ळ": "L",
    "क्ष": "kS",
    "ज्ञ": "jJ"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "OM",
    "ऽ": "'",
    "।": "|",
    "॥": "||"
  },
  "extra_consonants": {
    "क़": "q",
    "ख़": "qh",
    "ग़": "g2",
    "ज़": "z2",
    "ड़": "r3",
    "ढ़": "r3h",
    "फ़": "f",
    "य़": "Y",
    "ऱ": "r2",
    "ऴ": "zh",
    "ऩ": "n2"
  },
  "isRomanScheme": true
};
schemes.hk_dravidian = {
  "vowels": {
    "अ": "a",
    "आ": "A",
    "इ": "i",
    "ई": "I",
    "उ": "u",
    "ऊ": "U",
    "ऋ": "R",
    "ॠ": "RR",
    "ऌ": "lR",
    "ॡ": "lRR",
    "ऎ": "e",
    "ए": "E",
    "ऐ": "ai",
    "ऒ": "o",
    "ओ": "O",
    "औ": "au"
  },
  "yogavaahas": {
    "ं": "M",
    "ः": "H",
    "ँ": "~"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "k",
    "ख": "kh",
    "ग": "g",
    "घ": "gh",
    "ङ": "G",
    "च": "c",
    "छ": "ch",
    "ज": "j",
    "झ": "jh",
    "ञ": "J",
    "ट": "T",
    "ठ": "Th",
    "ड": "D",
    "ढ": "Dh",
    "ण": "N",
    "त": "t",
    "थ": "th",
    "द": "d",
    "ध": "dh",
    "न": "n",
    "प": "p",
    "फ": "ph",
    "ब": "b",
    "भ": "bh",
    "म": "m",
    "य": "y",
    "र": "r",
    "ल": "l",
    "व": "v",
    "श": "z",
    "ष": "S",
    "स": "s",
    "ह": "h",
    "ळ": "L",
    "क्ष": "kS",
    "ज्ञ": "jJ"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "OM",
    "ऽ": "'",
    "।": "|",
    "॥": "||"
  },
  "extra_consonants": {
    "क़": "q",
    "ख़": "qh",
    "ग़": "g2",
    "ज़": "z2",
    "ड़": "r3",
    "ढ़": "r3h",
    "फ़": "f",
    "य़": "Y",
    "ऱ": "r2",
    "ऴ": "zh",
    "ऩ": "n2"
  },
  "isRomanScheme": true
};
schemes.iast = {
  "vowels": {
    "अ": "a",
    "आ": "ā",
    "इ": "i",
    "ई": "ī",
    "उ": "u",
    "ऊ": "ū",
    "ऋ": "ṛ",
    "ॠ": "ṝ",
    "ऌ": "ḷ",
    "ॡ": "ḹ",
    "ऎ": "è",
    "ए": "e",
    "ऐ": "ai",
    "ऒ": "ò",
    "ओ": "o",
    "औ": "au"
  },
  "yogavaahas": {
    "ं": "ṃ",
    "ः": "ḥ",
    "ँ": "~"
  },
  "virama": {
    "्": ""
  },
  "accents": {
    "॑": "̭",
    "॒": "॒",
    "᳡": "̀",
    "꣡": "́",
    "꣢": "²",
    "꣣": "³",
    "꣤": "⁴",
    "꣥": "⁵",
    "꣦": "⁶",
    "꣧": "⁷",
    "꣨": "⁸",
    "꣩": "⁹",
    "꣪": "꣪",
    "꣫": "꣫",
    "꣬": "꣬",
    "꣭": "꣭",
    "꣮": "꣮",
    "꣯": "꣯",
    "꣰": "꣰",
    "꣱": "꣱"
  },
  "consonants": {
    "क": "k",
    "ख": "kh",
    "ग": "g",
    "घ": "gh",
    "ङ": "ṅ",
    "च": "c",
    "छ": "ch",
    "ज": "j",
    "झ": "jh",
    "ञ": "ñ",
    "ट": "ṭ",
    "ठ": "ṭh",
    "ड": "ḍ",
    "ढ": "ḍh",
    "ण": "ṇ",
    "त": "t",
    "थ": "th",
    "द": "d",
    "ध": "dh",
    "न": "n",
    "प": "p",
    "फ": "ph",
    "ब": "b",
    "भ": "bh",
    "म": "m",
    "य": "y",
    "र": "r",
    "ल": "l",
    "व": "v",
    "श": "ś",
    "ष": "ṣ",
    "स": "s",
    "ह": "h",
    "ळ": "l̤",
    "क्ष": "kṣ",
    "ज्ञ": "jñ"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "oṃ",
    "ऽ": "'",
    "।": "|",
    "॥": "||"
  },
  "extra_consonants": {
    "क़": "q",
    "ख़": "k͟h",
    "ग़": "ġ",
    "ज़": "z",
    "ड़": "r̤",
    "ढ़": "r̤h",
    "फ़": "f",
    "य़": "ẏ",
    "ऱ": "ṟ",
    "ऴ": "ḻ",
    "ऩ": "ṉ"
  },
  "alternates": {
    "|": [".", "/"],
    "||": ["..", "//"],
    "'": ["`"],
    "m̐": [],
    "ṃ": ["ṃ", "ṁ", "ṁ"],
    "ḥ": ["ḥ"],
    "ṭ": ["ṭ"],
    "ṭh": ["ṭh"],
    "ḍ": ["ḍ"],
    "ḍh": ["ḍh"],
    "ṇ": ["ṇ"],
    "ṣ": ["ṣ"],
    "ṅ": ["ṅ"],
    "ñ": ["ñ"],
    "ṛ": ["r̥", "ṛ"],
    "ṝ": ["ṝ", "r̥̄", "r̥̄", "ṝ", "ṝ"],
    "́": ["¹"]
  },
  "accented_vowel_alternates": {
    "á": ["á"],
    "é": ["é"],
    "í": ["í"],
    "ó": ["ó"],
    "ú": ["ú"],
    "ṛ́": ["ŕ̥"],
    "à": ["à"],
    "è": ["è"],
    "ì": ["ì"],
    "ò": ["ò"],
    "ù": ["ù"],
    "ṛ̀": ["r̥̀", "ṛ̀"]
  },
  "isRomanScheme": true
};
schemes.iso = {
  "vowels": {
    "अ": "a",
    "आ": "ā",
    "इ": "i",
    "ई": "ī",
    "उ": "u",
    "ऊ": "ū",
    "ऋ": "r̥",
    "ॠ": "r̥̄",
    "ऌ": "l̥",
    "ॡ": "l̥̄",
    "ऎ": "e",
    "ए": "ē",
    "ऐ": "ai",
    "ऒ": "o",
    "ओ": "ō",
    "औ": "au",
    "ऍ": "ê",
    "ऑ": "ô"
  },
  "yogavaahas": {
    "ं": "ṁ",
    "ः": "ḥ",
    "ᳵ": "ẖ",
    "ḫ": "ᳶ",
    "ँ": "m̐"
  },
  "virama": {
    "्": ""
  },
  "zwj": {
    "‍": "{}"
  },
  "accents": {
    "॑": "̭",
    "॒": "॒",
    "᳡": "̀",
    "꣡": "́",
    "꣢": "²",
    "꣣": "³",
    "꣤": "⁴",
    "꣥": "⁵",
    "꣦": "⁶",
    "꣧": "⁷",
    "꣨": "⁸",
    "꣩": "⁹",
    "꣪": "꣪",
    "꣫": "꣫",
    "꣬": "꣬",
    "꣭": "꣭",
    "꣮": "꣮",
    "꣯": "꣯",
    "꣰": "꣰",
    "꣱": "꣱"
  },
  "consonants": {
    "क": "k",
    "ख": "kh",
    "ग": "g",
    "घ": "gh",
    "ङ": "ṅ",
    "च": "c",
    "छ": "ch",
    "ज": "j",
    "झ": "jh",
    "ञ": "ñ",
    "ट": "ṭ",
    "ठ": "ṭh",
    "ड": "ḍ",
    "ढ": "ḍh",
    "ण": "ṇ",
    "त": "t",
    "थ": "th",
    "द": "d",
    "ध": "dh",
    "न": "n",
    "प": "p",
    "फ": "ph",
    "ब": "b",
    "भ": "bh",
    "म": "m",
    "य": "y",
    "र": "r",
    "ल": "l",
    "व": "v",
    "श": "ś",
    "ष": "ṣ",
    "स": "s",
    "ह": "h",
    "ळ": "ḷ",
    "क्ष": "kṣ",
    "ज्ञ": "jñ"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "ōṁ",
    "ऽ": "'",
    "।": "।",
    "॥": "॥"
  },
  "extra_consonants": {
    "क़": "q",
    "ख़": "k͟h",
    "ग़": "ġ",
    "ज़": "z",
    "ड़": "ṛ",
    "ढ़": "ṛh",
    "फ़": "f",
    "य़": "ẏ",
    "ऱ": "ṟ",
    "ऴ": "ḻ",
    "ऩ": "ṉ",
    "ऱ्": "r̆",
    "थ़": "s̱",
    "स़": "s̤",
    "ह़": "h̤",
    "त़": "t̤",
    "झ़": "ž",
    "च़": "ʦ",
    "छ़": "ʦh",
    "ذ": "ẕ",
    "ض": "ż",
    "ظ": "ẓ",
    "व़": "w"
  },
  "accented_vowel_alternates": {
    "á": ["á"],
    "é": ["é"],
    "í": ["í"],
    "ó": ["ó"],
    "ú": ["ú"],
    "ŕ̥": ["ŕ̥"],
    "à": ["à"],
    "è": ["è"],
    "ì": ["ì"],
    "ò": ["ò"],
    "ù": ["ù"],
    "r̥̀": ["r̥̀"]
  },
  "alternates": {
    "|": [".", "/"],
    "||": ["..", "//"],
    "'": ["`"],
    "m̐": ["ṁ"],
    "ṝ": ["ṝ", "r̥̄", "r̥̄", "ṝ", "ṝ"],
    "ṃ": ["ṃ"],
    "ḥ": ["ḥ"],
    "ṭ": ["ṭ"],
    "ṭh": ["ṭh"],
    "ḍ": ["ḍ"],
    "ḍh": ["ḍh"],
    "ṇ": ["ṇ"],
    "ṅ": ["ṅ"],
    "ñ": ["ñ"],
    "ṣ": ["ṣ"],
    "ṛ": ["ṛ"],
    "ṛh": ["ṛh"],
    "k͟h": ["ḵẖ", "ḵh"],
    "q": ["ḳ"],
    "w": ["ẉ"],
    "ġ": ["g̠ẖ"],
    "́": ["¹"],
    "r̥": ["r̤i"]
  },
  "isRomanScheme": true
};
schemes.itrans = {
  "vowels": {
    "अ": "a",
    "आ": "A",
    "इ": "i",
    "ई": "I",
    "उ": "u",
    "ऊ": "U",
    "ऋ": "RRi",
    "ॠ": "RRI",
    "ऌ": "LLi",
    "ॡ": "LLI",
    "ऎ": "è",
    "ए": "e",
    "ऐ": "ai",
    "ऒ": "ò",
    "ओ": "o",
    "औ": "au"
  },
  "yogavaahas": {
    "ं": "M",
    "ः": "H",
    "ँ": ".N"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "k",
    "ख": "kh",
    "ग": "g",
    "घ": "gh",
    "ङ": "~N",
    "च": "ch",
    "छ": "Ch",
    "ज": "j",
    "झ": "jh",
    "ञ": "~n",
    "ट": "T",
    "ठ": "Th",
    "ड": "D",
    "ढ": "Dh",
    "ण": "N",
    "त": "t",
    "थ": "th",
    "द": "d",
    "ध": "dh",
    "न": "n",
    "प": "p",
    "फ": "ph",
    "ब": "b",
    "भ": "bh",
    "म": "m",
    "य": "y",
    "र": "r",
    "ल": "l",
    "व": "v",
    "श": "sh",
    "ष": "Sh",
    "स": "s",
    "ह": "h",
    "ळ": "L",
    "क्ष": "kSh",
    "ज्ञ": "j~n"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "OM",
    "ऽ": ".a",
    "।": "|",
    "॥": "||"
  },
  "candra": {
    "ॅ": ".c"
  },
  "zwj": {
    "‍": "{}"
  },
  "skip": {
    "undefined": "_"
  },
  "accents": {
    "॑": "\\'",
    "॒": "\\_"
  },
  "extra_consonants": {
    "क़": "q",
    "ख़": "K",
    "ग़": "G",
    "ज़": "z",
    "ड़": ".D",
    "ढ़": ".Dh",
    "फ़": "f",
    "य़": "Y",
    "ऱ": "R",
    "ऴ": "zh"
  },
  "alternates": {
    "A": ["aa"],
    "I": ["ii", "ee"],
    "U": ["uu", "oo"],
    "RRi": ["R^i"],
    "RRI": ["R^I"],
    "LLi": ["L^i"],
    "LLI": ["L^I"],
    "M": [".m", ".n"],
    "~N": ["N^"],
    "ch": ["c"],
    "Ch": ["C", "chh"],
    "~n": ["JN"],
    "v": ["w"],
    "Sh": ["S", "shh"],
    "kSh": ["kS", "x"],
    "j~n": ["GY", "dny"],
    "OM": ["AUM"],
    "\\_": ["\\`"],
    "\\_H": ["\\`H"],
    "\\'M": ["\\'.m", "\\'.n"],
    "\\_M": ["\\_.m", "\\_.n", "\\`M", "\\`.m", "\\`.n"],
    ".a": ["~"],
    "|": ["."],
    "||": [".."],
    "z": ["J"]
  },
  "isRomanScheme": true
};
schemes.itrans_dravidian = {
  "vowels": {
    "अ": "a",
    "आ": "A",
    "इ": "i",
    "ई": "I",
    "उ": "u",
    "ऊ": "U",
    "ऋ": "RRi",
    "ॠ": "RRI",
    "ऌ": "LLi",
    "ॡ": "LLI",
    "ऎ": "e",
    "ए": "E",
    "ऐ": "ai",
    "ऒ": "o",
    "ओ": "O",
    "औ": "au"
  },
  "yogavaahas": {
    "ं": "M",
    "ः": "H",
    "ँ": ".N"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "k",
    "ख": "kh",
    "ग": "g",
    "घ": "gh",
    "ङ": "~N",
    "च": "ch",
    "छ": "Ch",
    "ज": "j",
    "झ": "jh",
    "ञ": "~n",
    "ट": "T",
    "ठ": "Th",
    "ड": "D",
    "ढ": "Dh",
    "ण": "N",
    "त": "t",
    "थ": "th",
    "द": "d",
    "ध": "dh",
    "न": "n",
    "प": "p",
    "फ": "ph",
    "ब": "b",
    "भ": "bh",
    "म": "m",
    "य": "y",
    "र": "r",
    "ल": "l",
    "व": "v",
    "श": "sh",
    "ष": "Sh",
    "स": "s",
    "ह": "h",
    "ळ": "L",
    "क्ष": "kSh",
    "ज्ञ": "j~n"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "OM",
    "ऽ": ".a",
    "।": "|",
    "॥": "||"
  },
  "candra": {
    "ॅ": ".c"
  },
  "zwj": {
    "‍": "{}"
  },
  "skip": {
    "undefined": "_"
  },
  "accents": {
    "॑": "\\'",
    "॒": "\\_"
  },
  "extra_consonants": {
    "क़": "q",
    "ख़": "K",
    "ग़": "G",
    "ज़": "z",
    "ड़": ".D",
    "ढ़": ".Dh",
    "फ़": "f",
    "य़": "Y",
    "ऱ": "R",
    "ऴ": "zh",
    "ऩ": "n2"
  },
  "alternates": {
    "A": ["aa"],
    "I": ["ii", "ee"],
    "U": ["uu", "oo"],
    "RRi": ["R^i"],
    "RRI": ["R^I"],
    "LLi": ["L^i"],
    "LLI": ["L^I"],
    "M": [".m", ".n"],
    "~N": ["N^"],
    "ch": ["c"],
    "Ch": ["C", "chh"],
    "~n": ["JN"],
    "v": ["w"],
    "Sh": ["S", "shh"],
    "kSh": ["kS", "x"],
    "j~n": ["GY", "dny"],
    "OM": ["AUM"],
    "\\_": ["\\`"],
    "\\_H": ["\\`H"],
    "\\'M": ["\\'.m", "\\'.n"],
    "\\_M": ["\\_.m", "\\_.n", "\\`M", "\\`.m", "\\`.n"],
    ".a": ["~"],
    "|": ["."],
    "||": [".."],
    "z": ["J"]
  },
  "isRomanScheme": true
};
schemes.itrans_lowercase = {
  "vowels": {
    "अ": "a",
    "आ": "aa",
    "इ": "i",
    "ई": "ii",
    "उ": "u",
    "ऊ": "uu",
    "ऋ": "RRi",
    "ॠ": "RRI",
    "ऌ": "LLi",
    "ॡ": "LLI",
    "ऎ": "è",
    "ए": "e",
    "ऐ": "ai",
    "ऒ": "ò",
    "ओ": "o",
    "औ": "au"
  },
  "yogavaahas": {
    "ं": "M",
    "ः": "H",
    "ँ": ".N"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "k",
    "ख": "kh",
    "ग": "g",
    "घ": "gh",
    "ङ": "~N",
    "च": "ch",
    "छ": "Ch",
    "ज": "j",
    "झ": "jh",
    "ञ": "~n",
    "ट": "T",
    "ठ": "Th",
    "ड": "D",
    "ढ": "Dh",
    "ण": "N",
    "त": "t",
    "थ": "th",
    "द": "d",
    "ध": "dh",
    "न": "n",
    "प": "p",
    "फ": "ph",
    "ब": "b",
    "भ": "bh",
    "म": "m",
    "य": "y",
    "र": "r",
    "ल": "l",
    "व": "v",
    "श": "sh",
    "ष": "Sh",
    "स": "s",
    "ह": "h",
    "ळ": "L",
    "क्ष": "kSh",
    "ज्ञ": "GY"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "OM",
    "ऽ": ".a",
    "।": "|",
    "॥": "||"
  },
  "candra": {
    "ॅ": ".c"
  },
  "zwj": {
    "‍": "{}"
  },
  "skip": {
    "undefined": "_"
  },
  "accents": {
    "॑": "\\'",
    "॒": "\\_"
  },
  "extra_consonants": {
    "क़": "q",
    "ख़": "K",
    "ग़": "G",
    "ज़": "z",
    "ड़": ".D",
    "ढ़": ".Dh",
    "फ़": "f",
    "य़": "Y",
    "ऱ": "R",
    "ऴ": "zh"
  },
  "alternates": {
    "A": ["A"],
    "I": ["I", "ee"],
    "U": ["U", "oo"],
    "RRi": ["R^i"],
    "RRI": ["R^I"],
    "LLi": ["L^i"],
    "LLI": ["L^I"],
    "M": [".m", ".n"],
    "~N": ["N^"],
    "ch": ["c"],
    "Ch": ["C", "chh"],
    "~n": ["JN"],
    "v": ["w"],
    "Sh": ["S", "shh"],
    "kSh": ["kS", "x"],
    "GY": ["j~n", "dny"],
    "OM": ["AUM"],
    "\\_": ["\\`"],
    "\\_H": ["\\`H"],
    "\\'M": ["\\'.m", "\\'.n"],
    "\\_M": ["\\_.m", "\\_.n", "\\`M", "\\`.m", "\\`.n"],
    ".a": ["~"],
    "|": ["."],
    "||": [".."],
    "z": ["J"]
  },
  "isRomanScheme": true
};
schemes.kolkata_v2 = {
  "vowels": {
    "अ": "a",
    "आ": "ā",
    "इ": "i",
    "ई": "ī",
    "उ": "u",
    "ऊ": "ū",
    "ऋ": "ṛ",
    "ॠ": "ṝ",
    "ऌ": "ḷ",
    "ॡ": "ḹ",
    "ऎ": "e",
    "ए": "ē",
    "ऐ": "ai",
    "ऒ": "o",
    "ओ": "ō",
    "औ": "au"
  },
  "yogavaahas": {
    "ं": "ṃ",
    "ः": "ḥ",
    "ँ": "~"
  },
  "virama": {
    "्": ""
  },
  "accents": {
    "॑": "̭",
    "॒": "॒",
    "᳡": "̀",
    "꣡": "́",
    "꣢": "²",
    "꣣": "³",
    "꣤": "⁴",
    "꣥": "⁵",
    "꣦": "⁶",
    "꣧": "⁷",
    "꣨": "⁸",
    "꣩": "⁹",
    "꣪": "꣪",
    "꣫": "꣫",
    "꣬": "꣬",
    "꣭": "꣭",
    "꣮": "꣮",
    "꣯": "꣯",
    "꣰": "꣰",
    "꣱": "꣱"
  },
  "consonants": {
    "क": "k",
    "ख": "kh",
    "ग": "g",
    "घ": "gh",
    "ङ": "ṅ",
    "च": "c",
    "छ": "ch",
    "ज": "j",
    "झ": "jh",
    "ञ": "ñ",
    "ट": "ṭ",
    "ठ": "ṭh",
    "ड": "ḍ",
    "ढ": "ḍh",
    "ण": "ṇ",
    "त": "t",
    "थ": "th",
    "द": "d",
    "ध": "dh",
    "न": "n",
    "प": "p",
    "फ": "ph",
    "ब": "b",
    "भ": "bh",
    "म": "m",
    "य": "y",
    "र": "r",
    "ल": "l",
    "व": "v",
    "श": "ś",
    "ष": "ṣ",
    "स": "s",
    "ह": "h",
    "ळ": "l̤",
    "क्ष": "kṣ",
    "ज्ञ": "jñ"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "ōṃ",
    "ऽ": "'",
    "।": "|",
    "॥": "||"
  },
  "extra_consonants": {
    "क़": "q",
    "ख़": "k͟h",
    "ग़": "ġ",
    "ज़": "z",
    "ड़": "r̤",
    "ढ़": "r̤h",
    "फ़": "f",
    "य़": "ẏ",
    "ऱ": "ṟ",
    "ऴ": "ḻ",
    "ऩ": "ṉ"
  },
  "alternates": {
    "|": [".", "/"],
    "||": ["..", "//"],
    "'": ["`"],
    "m̐": ["ṁ"],
    "ṃ": ["ṃ"],
    "ḥ": ["ḥ"],
    "ṭ": ["ṭ"],
    "ṭh": ["ṭh"],
    "ḍ": ["ḍ"],
    "ḍh": ["ḍh"],
    "ṇ": ["ṇ"],
    "ṅ": ["ṅ"],
    "ñ": ["ñ"],
    "ṣ": ["ṣ"],
    "ṛ": ["r̥", "ṛ"],
    "ṝ": ["ṝ", "r̥̄", "r̥̄", "ṝ", "ṝ"],
    "́": ["¹"]
  },
  "accented_vowel_alternates": {
    "á": ["á"],
    "é": ["é"],
    "í": ["í"],
    "ó": ["ó"],
    "ú": ["ú"],
    "ṛ́": ["ŕ̥"],
    "à": ["à"],
    "è": ["è"],
    "ì": ["ì"],
    "ò": ["ò"],
    "ù": ["ù"],
    "ṛ̀": ["r̥̀", "ṛ̀"]
  },
  "isRomanScheme": true
};
schemes.mahajani = {
  "vowels": {
    "अ": "𑅐",
    "आ": "𑅐",
    "इ": "𑅑",
    "ई": "𑅑",
    "उ": "𑅒",
    "ऊ": "𑅒",
    "ऋ": "𑅭𑅒",
    "ॠ": "𑅭𑅒",
    "ऌ": "𑅮𑅒",
    "ॡ": "𑅮𑅒",
    "ऎ": "𑅓",
    "ए": "𑅓",
    "ऐ": "𑅑",
    "ऒ": "𑅔",
    "ओ": "𑅔",
    "औ": "𑅒"
  },
  "yogavaahas": {
    "ं": "𑅧",
    "ः": "𑅱",
    "ँ": "𑅧"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "𑅕",
    "ख": "𑅖",
    "ग": "𑅗",
    "घ": "𑅘",
    "ङ": "𑅧",
    "च": "𑅙",
    "छ": "𑅚",
    "ज": "𑅛",
    "झ": "𑅜",
    "ञ": "𑅝",
    "ट": "𑅞",
    "ठ": "𑅟",
    "ड": "𑅠",
    "ढ": "𑅡",
    "ण": "𑅢",
    "त": "𑅣",
    "थ": "𑅤",
    "द": "𑅥",
    "ध": "𑅦",
    "न": "𑅧",
    "प": "𑅨",
    "फ": "𑅩",
    "ब": "𑅪",
    "भ": "𑅫",
    "म": "𑅬",
    "य": "𑅛",
    "र": "𑅭",
    "ल": "𑅮",
    "व": "𑅯",
    "श": "𑅰𑅳",
    "ष": "𑅖𑅳",
    "स": "𑅰",
    "ह": "𑅱",
    "ळ": "𑅮𑅳",
    "क्ष": "𑅕𑅖𑅳",
    "ज्ञ": "𑅛𑅝"
  },
  "symbols": {
    "०": "०",
    "१": "१",
    "२": "२",
    "३": "३",
    "४": "४",
    "५": "५",
    "६": "६",
    "७": "७",
    "८": "८",
    "९": "९",
    "ॐ": "𑅔𑅬",
    "ऽ": "",
    "।": "।",
    "॥": "॥"
  },
  "zwj": {
    "‍": "‍"
  },
  "skip": {
    "undefined": ""
  },
  "accents": {
    "॑": "॑",
    "॒": "॒"
  },
  "candra": {
    "ॅ": "𑅓"
  },
  "extra_consonants": {
    "क़": "𑅕𑅳",
    "ख़": "𑅖𑅳",
    "ग़": "𑅗𑅳",
    "ज़": "𑅛𑅳",
    "ड़": "𑅲",
    "ढ़": "𑅡𑅳",
    "फ़": "𑅩𑅳",
    "य़": "𑅛",
    "ऱ": "𑅭𑅳"
  },
  "isRomanScheme": true
};
schemes.multani = {
  "vowels": {
    "अ": "𑊀",
    "आ": "𑊀",
    "इ": "𑊁",
    "ई": "𑊁",
    "उ": "𑊂",
    "ऊ": "𑊂",
    "ऋ": "𑊢𑊂",
    "ॠ": "𑊢𑊂",
    "ऌ": "𑊣𑊂",
    "ॡ": "𑊣𑊂",
    "ऎ": "",
    "ए": "𑊃",
    "ऐ": "𑊃",
    "ऒ": "",
    "ओ": "𑊂",
    "औ": "𑊂"
  },
  "yogavaahas": {
    "ं": "𑊚",
    "ः": "𑊦",
    "ँ": "𑊚"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "𑊄",
    "ख": "𑊅",
    "ग": "𑊆",
    "घ": "𑊈",
    "ङ": "𑊚",
    "च": "𑊊",
    "छ": "𑊋",
    "ज": "𑊌",
    "झ": "𑊌",
    "ञ": "𑊏",
    "ट": "𑊐",
    "ठ": "𑊑",
    "ड": "𑊒",
    "ढ": "𑊔",
    "ण": "𑊕",
    "त": "𑊖",
    "थ": "𑊗",
    "द": "𑊘",
    "ध": "𑊙",
    "न": "𑊚",
    "प": "𑊛",
    "फ": "𑊜",
    "ब": "𑊝",
    "भ": "𑊟",
    "म": "𑊠",
    "य": "𑊡",
    "र": "𑊢",
    "ल": "𑊣",
    "व": "𑊤",
    "श": "𑊥",
    "ष": "𑊥",
    "स": "𑊥",
    "ह": "𑊦",
    "ळ": "𑊣",
    "क्ष": "𑊄𑊥",
    "ज्ञ": "𑊌𑊏"
  },
  "symbols": {
    "०": "੦",
    "१": "੧",
    "२": "੨",
    "३": "੩",
    "४": "੪",
    "५": "੫",
    "६": "੬",
    "७": "੭",
    "८": "੮",
    "९": "੯",
    "ॐ": "𑊂𑊠",
    "ऽ": "",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "𑊃"
  },
  "isRomanScheme": true
};
schemes.optitrans = {
  "vowels": {
    "अ": "a",
    "आ": "A",
    "इ": "i",
    "ई": "I",
    "उ": "u",
    "ऊ": "U",
    "ऋ": "R",
    "ॠ": "RR",
    "ऌ": "LLi",
    "ॡ": "LLI",
    "ऎ": "E",
    "ए": "e",
    "ऐ": "ai",
    "ऒ": "O",
    "ओ": "o",
    "औ": "au",
    "ऍ": "ea",
    "ऑ": "oa"
  },
  "yogavaahas": {
    "ं": "M",
    "ः": "H",
    "ँ": ".N",
    "ᳵ": "kH",
    "ᳶ": "pH"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "k",
    "ख": "kh",
    "ग": "g",
    "घ": "gh",
    "ङ": "~N",
    "च": "ch",
    "छ": "Ch",
    "ज": "j",
    "झ": "jh",
    "ञ": "~n",
    "ट": "T",
    "ठ": "Th",
    "ड": "D",
    "ढ": "Dh",
    "ण": "N",
    "त": "t",
    "थ": "th",
    "द": "d",
    "ध": "dh",
    "न": "n",
    "प": "p",
    "फ": "ph",
    "ब": "b",
    "भ": "bh",
    "म": "m",
    "य": "y",
    "र": "r",
    "ल": "l",
    "व": "v",
    "श": "sh",
    "ष": "Sh",
    "स": "s",
    "ह": "h",
    "ळ": "L",
    "क्ष": "x",
    "ज्ञ": "jn"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "OM",
    "ऽ": ".a",
    "।": "|",
    "॥": "||"
  },
  "candra": {
    "ॅ": ".c"
  },
  "zwj": {
    "‍": "{}"
  },
  "skip": {
    "undefined": "_"
  },
  "accents": {
    "॑": "\\'",
    "॒": "\\_"
  },
  "extra_consonants": {
    "क़": "q",
    "ख़": ".kh",
    "ग़": ".g",
    "ज़": "z",
    "ड़": ".D",
    "ढ़": ".Dh",
    "फ़": "f",
    "य़": "Y",
    "ऱ": ".Rh",
    "ऴ": ".L"
  },
  "alternates": {
    "A": ["aa"],
    "I": ["ii", "ee"],
    "U": ["uu"],
    "R": ["RRi", "R^i"],
    "RR": ["RRI", "R^I"],
    "LLi": ["L^i"],
    "LLI": ["L^I"],
    "M": [".m", ".n"],
    "ch": ["c"],
    "Ch": ["C"],
    "jh": ["J"],
    "ph": ["P"],
    "bh": ["B"],
    "~n": ["JN"],
    "v": ["w"],
    "Sh": ["S"],
    "x": ["kS", "ksh"],
    "jn": ["GY", "dny"],
    "|": ["."],
    "||": [".."],
    "z": ["J"]
  },
  "shortcuts": {
    "~nc": "nc",
    "~nC": "nC",
    "~nj": "nj",
    "~nJ": "nJ",
    "~Nk": "nk",
    "~NK": "nK",
    "~Ng": "ng",
    "~NG": "nG",
    "~Nx": "nx"
  },
  "isRomanScheme": true
};
schemes.optitrans_dravidian = {
  "vowels": {
    "अ": "a",
    "आ": "A",
    "इ": "i",
    "ई": "I",
    "उ": "u",
    "ऊ": "U",
    "ऋ": "R",
    "ॠ": "RR",
    "ऌ": "LLi",
    "ॡ": "LLI",
    "ऎ": "e",
    "ए": "E",
    "ऐ": "ai",
    "ऒ": "o",
    "ओ": "O",
    "औ": "au",
    "ऍ": "ea",
    "ऑ": "oa"
  },
  "yogavaahas": {
    "ं": "M",
    "ः": "H",
    "ँ": ".N",
    "ᳵ": "kH",
    "ᳶ": "pH"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "k",
    "ख": "kh",
    "ग": "g",
    "घ": "gh",
    "ङ": "~N",
    "च": "ch",
    "छ": "Ch",
    "ज": "j",
    "झ": "jh",
    "ञ": "~n",
    "ट": "T",
    "ठ": "Th",
    "ड": "D",
    "ढ": "Dh",
    "ण": "N",
    "त": "t",
    "थ": "th",
    "द": "d",
    "ध": "dh",
    "न": "n",
    "प": "p",
    "फ": "ph",
    "ब": "b",
    "भ": "bh",
    "म": "m",
    "य": "y",
    "र": "r",
    "ल": "l",
    "व": "v",
    "श": "sh",
    "ष": "Sh",
    "स": "s",
    "ह": "h",
    "ळ": "L",
    "क्ष": "x",
    "ज्ञ": "jn"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "OM",
    "ऽ": ".a",
    "।": "|",
    "॥": "||"
  },
  "candra": {
    "ॅ": ".c"
  },
  "zwj": {
    "‍": "{}"
  },
  "skip": {
    "undefined": "_"
  },
  "accents": {
    "॑": "\\'",
    "॒": "\\_"
  },
  "extra_consonants": {
    "क़": ".k",
    "ख़": "q",
    "ग़": ".g",
    "ज़": "z",
    "ड़": ".D",
    "ढ़": ".Dh",
    "फ़": "f",
    "य़": "Y",
    "ऱ": ".Rh",
    "ऴ": ".L",
    "ऩ": "n2"
  },
  "alternates": {
    "A": ["aa"],
    "I": ["ii", "ee"],
    "U": ["uu"],
    "R": ["RRi", "R^i"],
    "RR": ["RRI", "R^I"],
    "LLi": ["L^i"],
    "LLI": ["L^I"],
    "M": [".m", ".n"],
    "ch": ["c"],
    "Ch": ["C"],
    "jh": ["J"],
    "ph": ["P"],
    "bh": ["B"],
    "~n": ["JN"],
    "v": ["w"],
    "Sh": ["S"],
    "x": ["kS", "ksh"],
    "jn": ["GY", "dny"],
    "|": ["."],
    "||": [".."],
    "z": ["J"]
  },
  "shortcuts": {
    "~nc": "nc",
    "~nC": "nC",
    "~nj": "nj",
    "~nJ": "nJ",
    "~Nk": "nk",
    "~NK": "nK",
    "~Ng": "ng",
    "~NG": "nG",
    "~Nx": "nx"
  },
  "isRomanScheme": true
};
schemes.persian_old = {
  "vowels": {
    "अ": "𐎠",
    "आ": "𐎠",
    "इ": "𐎡",
    "ई": "𐎡",
    "उ": "𐎢",
    "ऊ": "𐎢",
    "ऋ": "𐎽𐎢",
    "ॠ": "𐎽𐎢",
    "ऌ": "𐎾𐎢",
    "ॡ": "𐎾𐎢",
    "ऎ": "",
    "ए": "𐎡",
    "ऐ": "𐎠𐎡",
    "ऒ": "",
    "ओ": "𐎢",
    "औ": "𐎠𐎢"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "𐎣",
    "ख": "𐎧",
    "ग": "𐎥",
    "घ": "𐎥",
    "ङ": "𐎴",
    "च": "𐎨",
    "छ": "𐎨",
    "ज": "𐎩",
    "झ": "𐎩",
    "ञ": "𐎴",
    "ट": "𐎫",
    "ठ": "𐎫",
    "ड": "𐎭",
    "ढ": "𐎭",
    "ण": "𐎴",
    "त": "𐎫",
    "थ": "𐎰",
    "द": "𐎭",
    "ध": "𐎭",
    "न": "𐎴",
    "प": "𐎱",
    "फ": "𐎱",
    "ब": "𐎲",
    "भ": "𐎲",
    "म": "𐎶",
    "य": "𐎹",
    "र": "𐎼",
    "ल": "𐎾",
    "व": "𐎺",
    "श": "𐏁",
    "ष": "𐏂",
    "स": "𐎿",
    "ह": "𐏃",
    "ळ": "𐎾",
    "क्ष": "𐎣𐏂",
    "ज्ञ": "𐎩𐎴"
  },
  "symbols": {
    "०": "",
    "१": "𐏑",
    "२": "𐏒",
    "३": "𐏒𐏑",
    "४": "𐏒𐏒",
    "५": "𐏒𐏒𐏑",
    "६": "𐏒𐏒𐏒",
    "७": "𐏒𐏒𐏒𐏑",
    "८": "𐏒𐏒𐏒𐏒",
    "९": "𐏒𐏒𐏒𐏒𐏑",
    "ॐ": "𐎢𐎶",
    "ऽ": "",
    "।": "।",
    "॥": "॥"
  },
  "candra": {
    "ॅ": "𐎡"
  },
  "isRomanScheme": true
};
schemes.slp1 = {
  "vowels": {
    "अ": "a",
    "आ": "A",
    "इ": "i",
    "ई": "I",
    "उ": "u",
    "ऊ": "U",
    "ऋ": "f",
    "ॠ": "F",
    "ऌ": "x",
    "ॡ": "X",
    "ऎ": "è",
    "ए": "e",
    "ऐ": "E",
    "ऒ": "ò",
    "ओ": "o",
    "औ": "O"
  },
  "yogavaahas": {
    "ं": "M",
    "ः": "H",
    "ँ": "~"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "k",
    "ख": "K",
    "ग": "g",
    "घ": "G",
    "ङ": "N",
    "च": "c",
    "छ": "C",
    "ज": "j",
    "झ": "J",
    "ञ": "Y",
    "ट": "w",
    "ठ": "W",
    "ड": "q",
    "ढ": "Q",
    "ण": "R",
    "त": "t",
    "थ": "T",
    "द": "d",
    "ध": "D",
    "न": "n",
    "प": "p",
    "फ": "P",
    "ब": "b",
    "भ": "B",
    "म": "m",
    "य": "y",
    "र": "r",
    "ल": "l",
    "व": "v",
    "श": "S",
    "ष": "z",
    "स": "s",
    "ह": "h",
    "ळ": "L",
    "क्ष": "kz",
    "ज्ञ": "jY"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "AUM",
    "ऽ": "'",
    "।": ".",
    "॥": ".."
  },
  "extra_consonants": {
    "क़": "k0",
    "ख़": "K0",
    "ग़": "g0",
    "ज़": "j0",
    "ड़": "q0",
    "ढ़": "Q0",
    "फ़": "P0",
    "य़": "Y0",
    "ऱ": "r2",
    "ऴ": "L0",
    "ऩ": "n0"
  },
  "shortcuts": {
    "Lh": "|"
  },
  "isRomanScheme": true
};
schemes.slp1_accented = {
  "vowels": {
    "अ": "a",
    "आ": "A",
    "इ": "i",
    "ई": "I",
    "उ": "u",
    "ऊ": "U",
    "ऋ": "f",
    "ॠ": "F",
    "ऌ": "x",
    "ॡ": "X",
    "ऎ": "è",
    "ए": "e",
    "ऐ": "E",
    "ऒ": "ò",
    "ओ": "o",
    "औ": "O"
  },
  "yogavaahas": {
    "ं": "M",
    "ः": "H",
    "ँ": "~",
    "ꣳ": "M£"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "k",
    "ख": "K",
    "ग": "g",
    "घ": "G",
    "ङ": "N",
    "च": "c",
    "छ": "C",
    "ज": "j",
    "झ": "J",
    "ञ": "Y",
    "ट": "w",
    "ठ": "W",
    "ड": "q",
    "ढ": "Q",
    "ण": "R",
    "त": "t",
    "थ": "T",
    "द": "d",
    "ध": "D",
    "न": "n",
    "प": "p",
    "फ": "P",
    "ब": "b",
    "भ": "B",
    "म": "m",
    "य": "y",
    "र": "r",
    "ल": "l",
    "व": "v",
    "श": "S",
    "ष": "z",
    "स": "s",
    "ह": "h",
    "ळ": "L",
    "क्ष": "kz",
    "ज्ञ": "jY"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "AUM",
    "ऽ": "'",
    "।": ".",
    "॥": ".."
  },
  "accents": {
    "॑": "̭",
    "॒": "\\",
    "᳡": "^",
    "꣡": "/",
    "꣢": "²",
    "꣣": "³",
    "꣤": "⁴",
    "꣥": "⁵",
    "꣦": "⁶",
    "꣧": "⁷",
    "꣨": "⁸",
    "꣩": "⁹",
    "꣪": "꣪",
    "꣫": "꣫",
    "꣬": "꣬",
    "꣭": "꣭",
    "꣮": "꣮",
    "꣯": "꣯",
    "꣰": "꣰",
    "꣱": "꣱"
  },
  "extra_consonants": {
    "क़": "k0",
    "ख़": "K0",
    "ग़": "g0",
    "ज़": "j0",
    "ड़": "q0",
    "ढ़": "Q0",
    "फ़": "P0",
    "य़": "Y0",
    "ऱ": "r2",
    "ऴ": "L0"
  },
  "shortcuts": {
    "Lh": "|"
  },
  "isRomanScheme": true
};
schemes.titus = {
  "vowels": {
    "अ": "a",
    "आ": "ā",
    "इ": "i",
    "ई": "ī",
    "उ": "u",
    "ऊ": "ū",
    "ऋ": "r̥",
    "ॠ": "r̥̄",
    "ऌ": "l̥",
    "ॡ": "l̥̄",
    "ऎ": "ĕ",
    "ए": "e",
    "ऐ": "ai",
    "ऒ": "ŏ",
    "ओ": "o",
    "औ": "au",
    "ऍ": "æ",
    "ऑ": "ô"
  },
  "yogavaahas": {
    "ं": "ṃ",
    "ः": "ḥ",
    "ँ": "m̐"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "k",
    "ख": "kʰ",
    "ग": "g",
    "घ": "gʰ",
    "ङ": "ṅ",
    "च": "c",
    "छ": "cʰ",
    "ज": "j",
    "झ": "jʰ",
    "ञ": "ñ",
    "ट": "ṭ",
    "ठ": "ṭʰ",
    "ड": "ḍ",
    "ढ": "ḍʰ",
    "ण": "ṇ",
    "त": "t",
    "थ": "tʰ",
    "द": "d",
    "ध": "dʰ",
    "न": "n",
    "प": "p",
    "फ": "pʰ",
    "ब": "b",
    "भ": "bʰ",
    "म": "m",
    "य": "y",
    "र": "r",
    "ल": "l",
    "व": "v",
    "श": "ś",
    "ष": "ṣ",
    "स": "s",
    "ह": "h",
    "ळ": "ḷ",
    "क्ष": "kṣ",
    "ज्ञ": "jñ"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "oṃ",
    "ऽ": "'",
    "।": ".",
    "॥": ".."
  },
  "zwj": {
    "‍": "‍"
  },
  "skip": {
    "undefined": ""
  },
  "candra": {
    "ॅ": "æ"
  },
  "extra_consonants": {
    "क़": "q",
    "ख़": "k͟ʰ",
    "ग़": "ġ",
    "ज़": "z",
    "ड़": "ṛ",
    "ढ़": "ṛʰ",
    "फ़": "f",
    "य़": "ẏ",
    "ऱ": "ṟ",
    "ऴ": "ḻ"
  },
  "alternates": {
    "m̐": ["ṁ"],
    "oṃ": ["ŏṃ"],
    "r̥̄": ["r̥̄"],
    ".": ["|", "/"],
    "..": ["||", "//"]
  },
  "isRomanScheme": true
};
schemes.velthuis = {
  "vowels": {
    "अ": "a",
    "आ": "aa",
    "इ": "i",
    "ई": "ii",
    "उ": "u",
    "ऊ": "uu",
    "ऋ": ".r",
    "ॠ": ".rr",
    "ऌ": ".l",
    "ॡ": ".ll",
    "ऎ": "è",
    "ए": "e",
    "ऐ": "ai",
    "ऒ": "ò",
    "ओ": "o",
    "औ": "au"
  },
  "yogavaahas": {
    "ं": ".m",
    "ः": ".h",
    "ँ": "~m"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "k",
    "ख": "kh",
    "ग": "g",
    "घ": "gh",
    "ङ": "\"n",
    "च": "c",
    "छ": "ch",
    "ज": "j",
    "झ": "jh",
    "ञ": "~n",
    "ट": ".t",
    "ठ": ".th",
    "ड": ".d",
    "ढ": ".dh",
    "ण": ".n",
    "त": "t",
    "थ": "th",
    "द": "d",
    "ध": "dh",
    "न": "n",
    "प": "p",
    "फ": "ph",
    "ब": "b",
    "भ": "bh",
    "म": "m",
    "य": "y",
    "र": "r",
    "ल": "l",
    "व": "v",
    "श": "\"s",
    "ष": ".s",
    "स": "s",
    "ह": "h",
    "ळ": "L",
    "क्ष": "k.s",
    "ज्ञ": "j~n"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "O",
    "ऽ": ".a",
    "।": "|",
    "॥": "||"
  },
  "isRomanScheme": true
};
schemes.wx = {
  "vowels": {
    "अ": "a",
    "आ": "A",
    "इ": "i",
    "ई": "I",
    "उ": "u",
    "ऊ": "U",
    "ऋ": "q",
    "ॠ": "Q",
    "ऌ": "L",
    "ॡ": "ḹ",
    "ऎ": "è",
    "ए": "e",
    "ऐ": "E",
    "ऒ": "",
    "ओ": "o",
    "औ": "O"
  },
  "yogavaahas": {
    "ं": "M",
    "ः": "H",
    "ँ": "z"
  },
  "virama": {
    "्": ""
  },
  "consonants": {
    "क": "k",
    "ख": "K",
    "ग": "g",
    "घ": "G",
    "ङ": "f",
    "च": "c",
    "छ": "C",
    "ज": "j",
    "झ": "J",
    "ञ": "F",
    "ट": "t",
    "ठ": "T",
    "ड": "d",
    "ढ": "D",
    "ण": "N",
    "त": "w",
    "थ": "W",
    "द": "x",
    "ध": "X",
    "न": "n",
    "प": "p",
    "फ": "P",
    "ब": "b",
    "भ": "B",
    "म": "m",
    "य": "y",
    "र": "r",
    "ल": "l",
    "व": "v",
    "श": "S",
    "ष": "R",
    "स": "s",
    "ह": "h",
    "ळ": "l̤",
    "क्ष": "kR",
    "ज्ञ": "jF"
  },
  "symbols": {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "ॐ": "oM",
    "ऽ": "'",
    "।": ".",
    "॥": ".."
  },
  "isRomanScheme": true
};
var devanagariVowelToMarks = {
  "आ": "ा",
  "इ": "ि",
  "ई": "ी",
  "उ": "ु",
  "ऊ": "ू",
  "ऋ": "ृ",
  "ॠ": "ॄ",
  "ऌ": "ॢ",
  "ॡ": "ॣ",
  "ऎ": "ॆ",
  "ए": "े",
  "ऐ": "ै",
  "ऒ": "ॊ",
  "ओ": "ो",
  "औ": "ौ",
  "ऍ": "ॅ",
  "ऑ": "ॉ"
};

function exportSanscriptSingleton(global, schemes, devanagariVowelToMarks) {
  "use strict";

  var Sanscript = {};
  Sanscript.defaults = {
    "skip_sgml": false,
    "syncope": false,
    "preferred_alternates": {}
  };
  Sanscript.schemes = schemes;
  var romanSchemes = {};
  var cache = {};

  Sanscript.addBrahmicScheme = function (name, scheme) {
    Sanscript.schemes[name] = scheme;
  };

  Sanscript.addRomanScheme = function (name, scheme) {
    if (!("vowel_marks" in scheme)) {
      scheme.vowel_marks = {};

      for (var _i = 0, _Object$entries = Object.entries(scheme.vowels); _i < _Object$entries.length; _i++) {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
            key = _Object$entries$_i[0],
            value = _Object$entries$_i[1];

        if (key != "अ") {
          scheme.vowel_marks[devanagariVowelToMarks[key]] = value;
        }
      }
    }

    Sanscript.schemes[name] = scheme;
    romanSchemes[name] = true;
  };

  (function () {
    var capitalize = function capitalize(text) {
      return text.charAt(0).toUpperCase() + text.substring(1, text.length);
    };

    var addCapitalAlternates = function addCapitalAlternates(codeList, alternatesMap) {
      var _iterator = _createForOfIteratorHelper(codeList),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var v = _step.value;
          var initAlternatesList = alternatesMap[v] || [];
          var alternatesList = initAlternatesList;
          alternatesList = alternatesList.concat(capitalize(v));

          var _iterator2 = _createForOfIteratorHelper(initAlternatesList),
              _step2;

          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var alternate = _step2.value;
              alternatesList = alternatesList.concat(capitalize(alternate));
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }

          alternatesMap[v] = alternatesList;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    };

    addCapitalAlternates(Object.values(schemes.iast.vowels), schemes.iast.alternates);
    addCapitalAlternates(Object.values(schemes.iast.consonants), schemes.iast.alternates);
    addCapitalAlternates(Object.values(schemes.iast.extra_consonants), schemes.iast.alternates);
    addCapitalAlternates(["oṃ"], schemes.iast.alternates);
    addCapitalAlternates(Object.values(schemes.kolkata_v2.vowels), schemes.kolkata_v2.alternates);
    addCapitalAlternates(Object.values(schemes.kolkata_v2.consonants), schemes.kolkata_v2.alternates);
    addCapitalAlternates(Object.values(schemes.kolkata_v2.extra_consonants), schemes.kolkata_v2.alternates);
    addCapitalAlternates(Object.values(schemes.iso.vowels), schemes.iso.alternates);
    addCapitalAlternates(Object.values(schemes.iso.consonants), schemes.iso.alternates);
    addCapitalAlternates(Object.values(schemes.iso.extra_consonants), schemes.iso.alternates);
    addCapitalAlternates(["ōṁ"], schemes.iso.alternates);

    for (var _i2 = 0, _Object$entries2 = Object.entries(schemes); _i2 < _Object$entries2.length; _i2++) {
      var _Object$entries2$_i = _slicedToArray(_Object$entries2[_i2], 2),
          schemeName = _Object$entries2$_i[0],
          scheme = _Object$entries2$_i[1];

      if (scheme.isRomanScheme) {
        Sanscript.addRomanScheme(schemeName, scheme);
      }
    }
  })();

  var makeMap = function makeMap(from, to, _options) {
    var consonants = {};
    var fromScheme = Sanscript.schemes[from];
    var letters = {};
    var tokenLengths = [];
    var marks = {};
    var accents = {};
    var toScheme = Sanscript.schemes[to];
    var alternates = fromScheme["alternates"] || {};

    for (var group in fromScheme) {
      if (!{}.hasOwnProperty.call(fromScheme, group)) {
        continue;
      }

      if (["alternates", "accented_vowel_alternates", "isRomanScheme"].includes(group)) {
        continue;
      }

      var fromGroup = fromScheme[group];
      var toGroup = toScheme[group];

      if (toGroup === undefined) {
        continue;
      }

      for (var _i3 = 0, _Object$entries3 = Object.entries(fromGroup); _i3 < _Object$entries3.length; _i3++) {
        var _Object$entries3$_i = _slicedToArray(_Object$entries3[_i3], 2),
            key = _Object$entries3$_i[0],
            F = _Object$entries3$_i[1];

        var T = toGroup[key];

        if (T === undefined) {
          continue;
        }

        if (T == "" && !["virama", "zwj", "skip"].includes(group)) {
          T = F;
        }

        var alts = alternates[F] || [];
        var numAlts = alts.length;
        var j = 0;
        tokenLengths.push(F.length);

        for (j = 0; j < numAlts; j++) {
          tokenLengths.push(alts[j].length);
        }

        if (group === "vowel_marks" || group === "virama") {
          marks[F] = T;

          for (j = 0; j < numAlts; j++) {
            marks[alts[j]] = T;
          }
        } else {
          letters[F] = T;

          for (j = 0; j < numAlts; j++) {
            letters[alts[j]] = T;
          }

          if (group === "consonants" || group === "extra_consonants") {
            consonants[F] = T;

            for (j = 0; j < numAlts; j++) {
              consonants[alts[j]] = T;
            }
          }

          if (group === "accents") {
            accents[F] = T;

            for (j = 0; j < numAlts; j++) {
              accents[alts[j]] = T;
            }
          }
        }
      }
    }

    if (fromScheme["accented_vowel_alternates"]) {
      for (var _i4 = 0, _Object$keys = Object.keys(fromScheme["accented_vowel_alternates"]); _i4 < _Object$keys.length; _i4++) {
        var baseAccentedVowel = _Object$keys[_i4];
        var synonyms = fromScheme.accented_vowel_alternates[baseAccentedVowel];

        var _iterator3 = _createForOfIteratorHelper(synonyms),
            _step3;

        try {
          for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
            var accentedVowel = _step3.value;
            var baseVowel = baseAccentedVowel.substring(0, baseAccentedVowel.length - 1);
            var sourceAccent = baseAccentedVowel[baseAccentedVowel.length - 1];
            var targetAccent = accents[sourceAccent] || sourceAccent;
            marks[accentedVowel] = (marks[baseVowel] || "") + targetAccent;

            if (!letters[baseVowel]) {
              console.error(baseVowel, targetAccent, letters);
            }

            letters[accentedVowel] = letters[baseVowel].concat(targetAccent);
          }
        } catch (err) {
          _iterator3.e(err);
        } finally {
          _iterator3.f();
        }
      }
    }

    return {
      consonants: consonants,
      accents: accents,
      fromRoman: fromScheme.isRomanScheme,
      letters: letters,
      marks: marks,
      maxTokenLength: Math.max.apply(Math, tokenLengths),
      toRoman: toScheme.isRomanScheme,
      virama: toScheme.virama["्"],
      toSchemeA: toScheme.vowels["अ"],
      fromSchemeA: fromScheme.vowels["अ"],
      from: from,
      to: to
    };
  };

  var transliterateRoman = function transliterateRoman(data, map, options) {
    var buf = [];
    var consonants = map.consonants;
    var dataLength = data.length;
    var letters = map.letters;
    var marks = map.marks;
    var maxTokenLength = map.maxTokenLength;
    var optSkipSGML = options.skip_sgml;
    var optSyncope = options.syncope;
    var toRoman = map.toRoman;
    var virama = map.virama;
    var hadConsonant = false;
    var tempLetter;
    var tempMark;
    var tokenBuffer = "";
    var skippingSGML = false;
    var skippingTrans = false;
    var toggledTrans = false;

    for (var i = 0, L; (L = data.charAt(i)) || tokenBuffer; i++) {
      var difference = maxTokenLength - tokenBuffer.length;

      if (difference > 0 && i < dataLength) {
        tokenBuffer += L;

        if (difference > 1) {
          continue;
        }
      }

      for (var j = 0; j < maxTokenLength; j++) {
        var token = tokenBuffer.substr(0, maxTokenLength - j);

        if (skippingSGML === true) {
          skippingSGML = token !== ">";
        } else if (token === "<") {
          skippingSGML = optSkipSGML;
        } else if (token === "##") {
          toggledTrans = !toggledTrans;
          tokenBuffer = tokenBuffer.substr(2);
          break;
        }

        skippingTrans = skippingSGML || toggledTrans;

        if ((tempLetter = letters[token]) !== undefined && !skippingTrans) {
          if (toRoman) {
            buf.push(tempLetter);
          } else {
            if (hadConsonant) {
              if (tempMark = marks[token]) {
                buf.push(tempMark);
              } else if (token !== map.fromSchemeA) {
                buf.push(virama);
                buf.push(tempLetter);
              }
            } else {
              buf.push(tempLetter);
            }

            hadConsonant = token in consonants;
          }

          tokenBuffer = tokenBuffer.substr(maxTokenLength - j);
          break;
        } else if (j === maxTokenLength - 1) {
          if (hadConsonant) {
            hadConsonant = false;

            if (!optSyncope) {
              buf.push(virama);
            }
          }

          buf.push(token);
          tokenBuffer = tokenBuffer.substr(1);
        }
      }
    }

    if (hadConsonant && !optSyncope) {
      buf.push(virama);
    }

    var result = buf.join("");
    var toScheme = schemes[map.to];

    if (!toRoman && Object.keys(map.accents).length > 0) {
      var pattern = new RegExp("([".concat(Object.values(map.accents).join(""), "])([").concat(Object.values(toScheme['yogavaahas']).join(""), "])"), "g");
      result = result.replace(pattern, "$2$1");
    }

    return result;
  };

  var transliterateBrahmic = function transliterateBrahmic(data, map, _options) {
    var buf = [];
    var consonants = map.consonants;
    var letters = map.letters;
    var marks = map.marks;
    var toRoman = map.toRoman;
    var danglingHash = false;
    var hadRomanConsonant = false;
    var temp;
    var skippingTrans = false;
    var toScheme = schemes[map.to];

    if (toRoman && Object.keys(map.accents).length > 0) {
      var pattern = new RegExp("([".concat(Object.values(toScheme['yogavaahas']).join(""), "])([").concat(Object.values(map.accents).join(""), "])"), "g");
      data = data.replace(pattern, "$2$1");
    }

    for (var i = 0, L; L = data.charAt(i); i++) {
      if (L === "#") {
        if (danglingHash) {
          skippingTrans = !skippingTrans;
          danglingHash = false;
        } else {
          danglingHash = true;
        }

        if (hadRomanConsonant) {
          buf.push(map.toSchemeA);
          hadRomanConsonant = false;
        }

        continue;
      } else if (skippingTrans) {
        buf.push(L);
        continue;
      }

      if ((temp = marks[L]) !== undefined) {
        buf.push(temp);
        hadRomanConsonant = false;
      } else {
        if (danglingHash) {
          buf.push("#");
          danglingHash = false;
        }

        if (hadRomanConsonant) {
          buf.push(map.toSchemeA);
          hadRomanConsonant = false;
        }

        if (temp = letters[L]) {
          buf.push(temp);
          hadRomanConsonant = toRoman && L in consonants;
        } else {
          buf.push(L);
        }
      }
    }

    if (hadRomanConsonant) {
      buf.push(map.toSchemeA);
    }

    return buf.join("");
  };

  Sanscript.t = function (data, from, to, options) {
    options = options || {};
    var cachedOptions = cache.options || {};
    var defaults = Sanscript.defaults;
    var hasPriorState = cache.from === from && cache.to === to;
    var map;

    for (var key in defaults) {
      if ({}.hasOwnProperty.call(defaults, key)) {
        var value = defaults[key];

        if (key in options) {
          value = options[key];
        }

        options[key] = value;

        if (value !== cachedOptions[key]) {
          hasPriorState = false;
        }
      }
    }

    if (hasPriorState) {
      map = cache.map;
    } else {
      map = makeMap(from, to, options);
      cache = {
        from: from,
        map: map,
        options: options,
        to: to
      };
    }

    if (from === "itrans") {
      data = data.replace(/\{\\m\+\}/g, ".h.N");
      data = data.replace(/\.h/g, "");
      data = data.replace(/\\([^'`_]|$)/g, "##$1##");
    }

    if (from === "tamil_superscripted") {
      var pattern = "([" + Object.values(schemes["tamil_superscripted"]["vowel_marks"]).join("") + schemes["tamil_superscripted"]["virama"]["्"] + "॒॑" + "]+)([²³⁴])";
      data = data.replace(new RegExp(pattern, "g"), "$2$1");
      console.error("transliteration from tamil_superscripted not fully implemented!");
    }

    var fromShortcuts = schemes[from]["shortcuts"];

    if (fromShortcuts) {
      for (var _key in fromShortcuts) {
        var shortcut = fromShortcuts[_key];

        if (_key.includes(shortcut)) {
          data = data.replace(_key, shortcut);
        }

        data = data.replace(shortcut, _key);
      }
    }

    var result = "";

    if (map.fromRoman) {
      result = transliterateRoman(data, map, options);
    } else {
      result = transliterateBrahmic(data, map, options);
    }

    var toShortcuts = schemes[to]["shortcuts"];

    if (toShortcuts) {
      for (var _key2 in toShortcuts) {
        var _shortcut = toShortcuts[_key2];

        if (_shortcut.includes(_key2)) {
          result = result.replace(_shortcut, _key2);
        }

        result = result.replace(_key2, _shortcut);
      }
    }

    if (to === "tamil_superscripted") {
      var _pattern = "([²³⁴])([" + Object.values(schemes["tamil_superscripted"]["vowel_marks"]).join("") + schemes["tamil_superscripted"]["virama"]["्"] + "॒॑" + "]+)";

      result = result.replace(new RegExp(_pattern, "g"), "$2$1");
    }

    if (_typeof(options.preferred_alternates[to]) == "object") {
      var keys = Object.keys(options.preferred_alternates[to]);

      for (var i = 0; i < keys.length; i++) {
        result = result.split(keys[i]).join(options.preferred_alternates[to][keys[i]]);
      }
    }

    return result;
  };

  if (typeof define === "function" && define.amd) {
    define(function () {
      return Sanscript;
    });
  } else if (typeof exports !== "undefined") {
    if (typeof module !== "undefined" && module.exports) {
      exports = Sanscript;
      module.exports = Sanscript;
    }

    exports.Sanscript = Sanscript;
  } else {
    global.Sanscript = Sanscript;
  }
}

exportSanscriptSingleton(this, schemes, devanagariVowelToMarks);
